import { Stack } from '@mui/material';
import { BusinessesAdmin } from './BusinessesAdmin';
import { Header } from '../../../customer/components/TopBar/Header';

export const BusinessAdminHome = () => (
  <Stack>
    <Header />
    <BusinessesAdmin />
  </Stack>
);
