import {
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useParams } from "react-router-dom";
import { useGetAdminBusiness } from "../../../apiClient/apiClient";
import { CarouselSlider } from "../../../shared/components/UI/CarouselSlider";
import EditIcon from "@mui/icons-material/Edit";
import { Header } from "../../../customer/components/TopBar/Header";
import { DrawerListBusinessAdmin } from "../DrawerBusinessAdmin/DrawerListBusinessAdmin";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { NARROW_SCREEN } from "../../../shared/helpers/values";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";
import { TermsAndGDPRLinks } from "../../../shared/components/UI/TermsAndGDPRLinks";

export const containerStyles = {
  width: "500px",
  height: "280px",
  margin: "0 auto",
  marginTop: 38,
};
export const BusinessDetailAdmin = () => {
  const { t } = useTranslation("common");
  const { id } = useParams<{ id: string }>();

  const { data } = useGetAdminBusiness({
    businessId: String(id),
  });
  const business = data?.data;

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  return (
    <>
      <Header children={<DrawerListBusinessAdmin />} />
      <Stack spacing={3}>
        <Stack
          alignItems={isNarrow ? "center" : "start"}
          sx={{ m: isNarrow ? 1 : 5, ml: isNarrow ? 1 : 35, mt: 10 }}
          direction={isNarrow ? "column" : "row"}
        >
          {isNarrow && (
            <Tooltip title='Upravit profil' arrow>
              <StyledLink to='edit'>
                <IconButton>
                  <EditIcon color='primary' />
                </IconButton>
              </StyledLink>
            </Tooltip>
          )}
          <Card
            variant='outlined'
            sx={{
              backgroundColor: (theme) => theme.palette.secondary.light,
              borderRadius: "50px",
              borderWidth: "2px",
              borderColor: (theme) => theme.palette.primary.main,
              width: "100%",
              color: (theme) => theme.palette.primary.light,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack alignItems='center' justifyContent='center'>
              {isNarrow ? (
                <CardMedia sx={{ width: "100%" }}>
                  <CarouselSlider slides={business?.images} />
                </CardMedia>
              ) : (
                <CardMedia>
                  <div style={containerStyles}>
                    <CarouselSlider slides={business?.images} />
                  </div>
                </CardMedia>
              )}
              <CardContent sx={{ pt: 6, mx: 5 }}>
                <Stack spacing={2}>
                  <Stack alignItems='center'>
                    <Typography variant='h3' color='primary.dark'>
                      {business?.name}
                    </Typography>
                  </Stack>
                  <Stack alignItems='start'>
                    <Typography>{business?.description}</Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Stack>
            <Stack sx={{ pl: 2, pb: 2 }}>
              {business?.terms && business?.gdpr && (
                <TermsAndGDPRLinks
                  terms={business?.terms}
                  gdpr={business?.gdpr}
                />
              )}
            </Stack>
          </Card>
          {!isNarrow && (
            <Tooltip title='Upravit profil' arrow>
              <StyledLink to='edit'>
                <IconButton>
                  <EditIcon color='primary' />
                </IconButton>
              </StyledLink>
            </Tooltip>
          )}
        </Stack>
        <Stack
          sx={{ pl: isNarrow ? 1 : 35, mt: 10, pr: isNarrow ? 1 : 10, pb: 4 }}
        >
          <StyledLink to={`/admin/business/${id}/products/add`}>
            <Button
              variant='contained'
              sx={{ width: "100%", borderRadius: 5, color: "secondary.light" }}
            >
              <Stack
                direction='row'
                spacing={1}
                sx={{ alignItems: "center", justifyContent: "center" }}
              >
                <Add fontSize='small' />
                <Typography variant='button'>
                  {t("admin.product.addProduct")}
                </Typography>
              </Stack>
            </Button>
          </StyledLink>
        </Stack>
      </Stack>
    </>
  );
};
