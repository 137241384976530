import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";

export const NoAdminAccess = () => {
  const { t } = useTranslation("common");
  return (
    <Stack
      spacing={5}
      justifyContent='center'
      alignItems='center'
      sx={{ pt: 15 }}
    >
      <Typography variant='h3' color='primary.dark'>
        {t("admin.noAccess.noAccessMessage")}
      </Typography>
      <StyledLink to='/app'>
        <Button variant='contained'>{t("admin.noAccess.returnButton")}</Button>
      </StyledLink>
    </Stack>
  );
};
