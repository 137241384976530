import React, { ReactNode } from 'react';
import { Stack, Drawer } from '@mui/material';

const drawerWidth = 240;

type ResponsiveDrawerProps = {
  drawerOpen: boolean;
  children: ReactNode;
  isNarrow: boolean;
  onClose: (_e: React.MouseEvent, reason: string) => false | void;
};

export const ResponsiveDrawer = ({
  drawerOpen,
  children,
  isNarrow,
  onClose,
}: ResponsiveDrawerProps) => (
  <Drawer
    variant={isNarrow ? 'temporary' : 'persistent'}
    sx={{
      '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        width: drawerWidth,
      },
    }}
    elevation={5}
    PaperProps={{
      sx: {
        backgroundColor: (theme) => theme.palette.primary.dark,
      },
    }}
    open={drawerOpen}
    onClose={onClose}
  >
    <Stack spacing={1} sx={{ mt: 9, mx: 2 }}>
      {children}
    </Stack>
  </Drawer>
);
