import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowBack, Delete, DeleteForever } from "@mui/icons-material";
import { UploadProductImage } from "./UploadProductImage";
import {
  useDeleteAdminProduct,
  useGetAdminProduct,
  useUpdateAdminProduct,
} from "../../../apiClient/apiClient";
import { StyledTextField } from "../../../shared/components/UI/StyledTextField";
import { useEffect, useState } from "react";
import { DeleteDialog } from "../../../shared/components/UI/DeleteDialog";
import { Header } from "../../../customer/components/TopBar/Header";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { NARROW_SCREEN } from "../../../shared/helpers/values";
import { categories as options } from "../../../customer/components/Drawer/DrawerHome/DrawerListCategories";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";

const UpdateProductSchema = () =>
  yup.object({
    name: yup.string().optional(),
    description: yup.string().optional(),
    price: yup.number().optional(),
    inStock: yup.number().optional(),
    categories: yup.array().of(yup.string()).optional(),
  });

export const EditProduct = () => {
  const { t } = useTranslation("common");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [categories, setCategories] = useState<string[]>([]);

  const { businessId, productId } = useParams<{
    businessId: string;
    productId: string;
  }>();

  const { data: getProduct, refetch } = useGetAdminProduct({
    businessId: String(businessId),
    productId: String(productId),
  });

  const product = getProduct?.data;

  const { control, handleSubmit, watch, setValue } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(UpdateProductSchema()),
    defaultValues: {
      name: product?.name,
      description: product?.description,
      price: product?.price,
      inStock: product?.inStock,
      categories: product?.categories,
    },
  });

  const watchValues = watch();

  const navigate = useNavigate();

  const { mutate: updateProduct, isLoading: isUpdating } =
    useUpdateAdminProduct();

  const { mutate: deleteProduct, isLoading: isDeleting } =
    useDeleteAdminProduct();

  const selectedCategories = options.filter((category) =>
    categories.includes(category.locale)
  );

  const onSubmit = () => {
    updateProduct(
      {
        businessId: String(businessId),
        productId: String(productId),
        name: watchValues.name ?? product?.name,
        description: watchValues.description ?? product?.description,
        price:
          watchValues.price !== undefined ? +watchValues.price : product?.price,
        inStock: +watchValues.inStock ?? product?.inStock,
        categories:
          categories.length > 0
            ? selectedCategories?.map(({ name }) => name)
            : product?.categories,
      },
      {
        onSuccess: () => {
          navigate(`/admin/business/${businessId}/products/`);
        },
      }
    );
  };

  const handleImgDelete = ({ img }: { img: string }) => {
    const filteredImages = product?.images.filter((image) => image !== img);
    updateProduct(
      {
        businessId: String(businessId),
        productId: String(productId),
        images: filteredImages,
      },
      { onSuccess: () => refetch() }
    );
  };

  useEffect(() => {
    setValue("name", product?.name);
    setValue("description", product?.description);
    setValue("price", product?.price);
    setValue("inStock", product?.inStock);
  }, [
    product?.description,
    product?.inStock,
    product?.name,
    product?.price,
    setValue,
  ]);

  const handleProductDelete = () => {
    deleteProduct(
      { businessId: String(businessId), productId: String(productId) },
      { onSuccess: () => navigate(`/admin/business/${businessId}/products/`) }
    );
  };

  const defaultCategoryValues = options.filter((option) =>
    product?.categories.includes(option.name)
  );

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  return (
    <Stack>
      <Header />
      <Stack alignItems='center' justifyContent='center' sx={{ mt: 10, mb: 1 }}>
        <Stack
          spacing={3}
          alignItems='center'
          justifyContent='center'
          sx={{ width: isNarrow ? "95%" : "60%", textAlign: "center" }}
        >
          <Typography
            variant='h4'
            color='text.primary'
            sx={{ fontWeight: 500 }}
          >
            Upravit produkt
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction={isNarrow ? "column" : "row"}
        spacing={isNarrow ? 1 : 5}
        justifyContent='center'
        alignItems={isNarrow ? "center" : "start"}
        sx={{
          my: 2,
          mr: isNarrow ? 0 : 12,
          mb: 5,
        }}
      >
        <StyledLink to={`/admin/business/${businessId}/products`}>
          <IconButton>
            <ArrowBack fontSize='large' color='primary' />
          </IconButton>
        </StyledLink>
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{ m: 3, mr: 5, width: isNarrow ? "98%" : "80%" }}
        >
          <Card
            variant='outlined'
            sx={{
              backgroundColor: "transparent",
              borderRadius: "20px",
              borderWidth: "2px",
              borderColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.light,
              alignItems: "center",
              justifyContent: "center",
              p: isNarrow ? 0 : 5,
              width: "100%",
            }}
          >
            <Stack
              spacing={3}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4 }}>
                {/* todo - dnd images */}
                {product?.images.map((img, index) => (
                  <Grid
                    item
                    key={`${img}${index}`}
                    sx={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Card
                      sx={{
                        width: 300,
                        height: 200,
                        borderRadius: "50px",
                        borderWidth: "2px",
                        borderColor: (theme) => theme.palette.primary.main,
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        alt='product-img'
                        src={img}
                        style={{ width: 300, height: 200 }}
                      />
                      <IconButton
                        size='medium'
                        sx={{
                          position: "absolute",
                          top: "6%",
                          right: "5%",
                          backgroundColor: (theme) =>
                            theme.palette.secondary.dark,
                          ":hover": {
                            backgroundColor: (theme) =>
                              theme.palette.secondary.light,
                          },
                        }}
                        onClick={() => handleImgDelete({ img })}
                      >
                        {isUpdating ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Delete fontSize='medium' />
                        )}
                      </IconButton>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <UploadProductImage />
            </Stack>
            <Divider
              sx={{
                pt: 2,
                borderColor: (theme) => theme.palette.primary.main,
              }}
            />
            <CardContent
              sx={{ pt: 6, mx: isNarrow ? 0 : 5, alignItems: "center" }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack
                  spacing={2}
                  justifyContent='center'
                  alignItems='center'
                  sx={{ pt: 5 }}
                >
                  <Stack spacing={3}>
                    <Stack
                      direction='row'
                      spacing={3}
                      alignItems='center'
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Typography
                        variant='h6'
                        color='text.secondary'
                        sx={{ flex: 1, textAlign: "end" }}
                      >
                        {t("shared.naming")}:
                      </Typography>
                      <Controller
                        control={control}
                        name='name'
                        render={({ field, fieldState, formState }) => (
                          <StyledTextField
                            {...field}
                            value={
                              field.value === undefined
                                ? product?.name
                                : field.value
                            }
                            variant='outlined'
                            size='small'
                            error={!!fieldState.error}
                            helperText={
                              !!fieldState.error
                                ? fieldState.error.message
                                : undefined
                            }
                            inputProps={{
                              sx: {
                                color: (theme) => theme.palette.text.primary,
                                backgroundColor: "transparent !important",
                                width: isNarrow ? "180px" : "300px",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: (theme) => theme.palette.primary.main,
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={3}
                      alignItems='center'
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Typography
                        variant='h6'
                        color='text.secondary'
                        sx={{ flex: 1, textAlign: "end" }}
                      >
                        {t("shared.description")}:
                      </Typography>
                      <Controller
                        control={control}
                        name='description'
                        render={({ field, fieldState, formState }) => (
                          <StyledTextField
                            {...field}
                            multiline
                            value={
                              field.value === undefined
                                ? product?.description
                                : field.value
                            }
                            variant='outlined'
                            size='small'
                            error={!!fieldState.error}
                            helperText={
                              !!fieldState.error
                                ? fieldState.error.message
                                : undefined
                            }
                            inputProps={{
                              sx: {
                                color: (theme) => theme.palette.text.primary,
                                backgroundColor: "transparent !important",
                                width: isNarrow ? "180px" : "300px",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: (theme) => theme.palette.primary.main,
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={3}
                      alignItems='center'
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Typography
                        variant='h6'
                        color='text.secondary'
                        sx={{ flex: 1, textAlign: "end" }}
                      >
                        {t("shared.price")}:
                      </Typography>
                      <Controller
                        control={control}
                        name='price'
                        render={({ field, fieldState, formState }) => (
                          <StyledTextField
                            {...field}
                            value={
                              field.value === undefined
                                ? product?.price
                                : field.value
                            }
                            variant='outlined'
                            size='small'
                            type='number'
                            error={!!fieldState.error}
                            helperText={
                              !!fieldState.error
                                ? fieldState.error.message
                                : undefined
                            }
                            inputProps={{
                              sx: {
                                color: (theme) => theme.palette.text.primary,
                                backgroundColor: "transparent !important",
                                width: isNarrow ? "180px" : "300px",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: (theme) => theme.palette.primary.main,
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={3}
                      alignItems='center'
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Typography
                        variant='h6'
                        color='text.secondary'
                        sx={{ flex: 1, textAlign: "end" }}
                      >
                        {t("shared.inStock")}:
                      </Typography>
                      <Controller
                        control={control}
                        name='inStock'
                        render={({ field, fieldState, formState }) => (
                          <StyledTextField
                            {...field}
                            value={
                              field.value === undefined
                                ? product?.inStock
                                : field.value
                            }
                            variant='outlined'
                            size='small'
                            type='number'
                            error={!!fieldState.error}
                            helperText={
                              !!fieldState.error
                                ? fieldState.error.message
                                : undefined
                            }
                            inputProps={{
                              sx: {
                                color: (theme) => theme.palette.text.primary,
                                backgroundColor: "transparent !important",
                                width: isNarrow ? "180px" : "300px",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: (theme) => theme.palette.primary.main,
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      alignItems='center'
                      spacing={4}
                      sx={{ pb: 2, justifyContent: "space-between" }}
                    >
                      <Typography
                        variant='h6'
                        color='text.secondary'
                        sx={{ flex: 1, textAlign: "end" }}
                      >
                        {t("shared.categories")}
                      </Typography>
                      <Controller
                        control={control}
                        name='categories'
                        render={({ fieldState, field }) => (
                          <Autocomplete
                            multiple
                            defaultValue={
                              defaultCategoryValues?.map((category) =>
                                t(category.locale as any)
                              ) ?? []
                            }
                            options={options.map(({ locale }) =>
                              t(locale as any)
                            )}
                            filterSelectedOptions
                            sx={{
                              backgroundColor: "transparent !important",
                              width: isNarrow ? "208px" : "328px",
                            }}
                            onChange={(event, data) => setCategories(data)}
                            renderInput={(params) => (
                              <StyledTextField
                                {...params}
                                variant='outlined'
                                size='small'
                              />
                            )}
                          />
                        )}
                      />
                    </Stack>
                    <Button
                      type='submit'
                      variant='contained'
                      sx={{ alignSelf: "center" }}
                    >
                      {t("shared.save")}
                    </Button>
                  </Stack>
                </Stack>
              </form>
              <Stack
                alignItems={isNarrow ? "center" : "end"}
                justifyContent='end'
              >
                <Button
                  variant='outlined'
                  color='error'
                  onClick={() => setOpenDialog(true)}
                  startIcon={<DeleteForever fontSize='small' />}
                  sx={{ mt: isNarrow ? 2 : 0 }}
                >
                  {t("admin.product.deleteProduct")}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Stack>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: {
            width: "430px",
            position: "fixed",
            backgroundColor: (theme) => theme.palette.secondary.light,
          },
          elevation: 2,
        }}
      >
        <DeleteDialog
          onClose={() => setOpenDialog(false)}
          onDelete={handleProductDelete}
          isLoading={isDeleting}
          textTitle={t("admin.product.removeProduct")}
          textBody={t("admin.product.removeProductConfirmation")}
        />
      </Dialog>
    </Stack>
  );
};
