import { Close, Delete, DeleteOutline } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type DeleteCartItemDialogProps = {
  onClose: () => void;
  onDelete: () => void;
  isLoading: boolean;
  textTitle: string;
  textBody: string;
};

export const DeleteDialog = ({
  onClose,
  onDelete,
  isLoading,
  textTitle,
  textBody,
}: DeleteCartItemDialogProps) => {
  const { t } = useTranslation("common");
  return (
    <>
      <DialogTitle
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          sx={{
            alignItems: "center",
            color: (theme) => theme.palette.secondary.light,
          }}
        >
          <Stack direction='row' alignItems='center' spacing={1}>
            <DeleteOutline />
            <Typography variant='h5'>{textTitle}</Typography>
          </Stack>
          <IconButton onClick={onClose}>
            <Close sx={{ color: (theme) => theme.palette.secondary.light }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          px: 3,
          my: 3,
          pb: 0,
          overflowY: "visible",
          backgroundColor: (theme) => theme.palette.secondary.light,
        }}
      >
        <Stack spacing={3}>
          <Typography color='primary.dark' variant='h6'>
            {textBody}
          </Typography>
          <Stack direction='row' spacing={2} justifyContent='end'>
            <Button
              type='submit'
              onClick={onDelete}
              variant='contained'
              disabled={isLoading}
              startIcon={
                isLoading && <CircularProgress color='inherit' size={20} />
              }
            >
              {t("shared.yes")}
            </Button>

            <Button variant='outlined' onClick={onClose} color='primary'>
              {t("shared.no")}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </>
  );
};
