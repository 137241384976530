import { AppBar, IconButton, Stack } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { AvatarMenu } from "./AvatarMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { SearchBar } from "../SearchBar/SearchBar";
import { ShoppingCartMenu } from "../ShoppingCart/ShoppingCartMenu";
import { ResponsiveDrawer } from "../Drawer/Drawer";
import { useAuth } from "../../context/AuthContext";
import { NARROW_SCREEN, WIDE_SCREEN } from "../../../shared/helpers/values";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";
import logo from "./delora-logo.svg";
import { Home } from "@mui/icons-material";

type HeaderProps = {
  children?: ReactNode;
};

export const Header = ({ children }: HeaderProps) => {
  const [drawerOpen, setDrawerOpen] = useState(true);

  const auth = useAuth();
  const isAdmin = auth?.user?.roles?.includes("ADMIN");

  const location = useLocation();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  useEffect(() => {
    if (isNarrow) {
      setDrawerOpen(false);
    }
  }, [isNarrow, location]);

  useEffect(() => {
    if (isNarrow) {
      setDrawerOpen(false);
    }
    if (windowSize.width && windowSize.width > WIDE_SCREEN) {
      setDrawerOpen(true);
    }
  }, [isNarrow, windowSize.width]);

  return (
    <>
      <AppBar
        elevation={3}
        sx={{
          height: 56,
          justifyContent: "center",
          zIndex: 1201,
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ px: 2 }}
        >
          <Stack direction='row' alignItems='center' justifyContent='center'>
            <StyledLink to='/app'>
              <Stack alignItems='center' justifyContent='center'>
                <img src={logo} alt='delora-logo' style={{ height: "50px" }} />
              </Stack>
            </StyledLink>
            {isNarrow && children && (
              <IconButton onClick={handleDrawerToggle}>
                <MenuIcon fontSize='large' />
              </IconButton>
            )}
          </Stack>
          <SearchBar />
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            justifyContent='center'
          >
            {isAdmin && (
              <StyledLink to='/admin/business'>
                <IconButton
                  size='large'
                  aria-label='account of current user'
                  aria-controls='avatar-menu'
                  aria-haspopup='true'
                  color='inherit'
                >
                  <Home fontSize='large' color='secondary' />
                </IconButton>
              </StyledLink>
            )}
            <ShoppingCartMenu />
            <AvatarMenu />
          </Stack>
        </Stack>
      </AppBar>
      {!!children && (
        <ResponsiveDrawer
          drawerOpen={drawerOpen}
          children={children}
          isNarrow={!!isNarrow}
          onClose={(_e: React.MouseEvent, reason: string) =>
            reason === "backdropClick" &&
            isNarrow === true &&
            setDrawerOpen(false)
          }
        />
      )}
    </>
  );
};
