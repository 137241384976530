import { DeleteForever } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Dialog,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import {
  useDeleteCartItem,
  useGetCart,
  useUpdateCartItem,
} from "../../../apiClient/apiClient";
import { useAuth } from "../../context/AuthContext";
import { useCartContext } from "../../context/CartContext";
import { QuantityInput } from "../UI/QuantityInput";
import { roundToTwoDecimals } from "../../../shared/helpers/helpers";
import { DeleteDialog } from "../../../shared/components/UI/DeleteDialog";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import {
  NARROW_SCREEN,
  removedFromCart,
  somethingWentWrong,
} from "../../../shared/helpers/values";
import { SnackbarMsg } from "../../../shared/components/UI/SnackbarMsg";
import { useTranslation } from "react-i18next";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";

export const ShoppingCartDrawer = () => {
  const { t } = useTranslation("common");
  const { setShowCart, showCart } = useCartContext();

  const auth = useAuth();

  const {
    data: cartData,
    isSuccess,
    isLoading: isLoadingCart,
  } = useGetCart({ enabled: !!auth?.user });
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [cartItemId, setCartItemId] = useState<string | null>(null);
  const [showMsg, setShowMsg] = useState<{
    errorMsg: boolean;
    removeFromCartMsg: boolean;
  }>({ errorMsg: false, removeFromCartMsg: false });

  const { mutate: deleteCartItem, isLoading: isDeleting } = useDeleteCartItem();
  const cart = cartData?.data?.items;

  const handleDelete = ({ cartItemId }) => {
    deleteCartItem(
      { cartItemId: cartItemId },
      {
        onSuccess: () => {
          setOpenDialog(false);
          setShowMsg({
            errorMsg: false,
            removeFromCartMsg: true,
          });
        },
        onError: () =>
          setShowMsg({
            errorMsg: true,
            removeFromCartMsg: false,
          }),
      }
    );
  };

  const pricesWithQuantities = cart?.map(
    (item) => item.product.price * item.quantity
  );

  const pricesTotal = pricesWithQuantities?.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue;
    },
    0
  );

  const { mutate: updateCartItem, isLoading: isLoadingCartItem } =
    useUpdateCartItem();

  const onLessClick = ({ cartItemId, quantity }) => {
    updateCartItem({
      cartItemId,
      quantity: quantity - 1,
    });
  };

  const onMoreClick = ({ cartItemId, quantity }) => {
    updateCartItem({
      cartItemId,
      quantity: quantity + 1,
    });
  };

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  return (
    <>
      <Drawer
        open={showCart}
        onClose={() => setShowCart(false)}
        anchor='right'
        disableScrollLock
        PaperProps={{
          sx: {
            width: isNarrow ? 350 : 450,
            backgroundColor: (theme) => theme.palette.secondary.light,
            px: 1,
          },
        }}
      >
        <Stack
          spacing={1}
          justifyContent='center'
          alignItems='center'
          sx={{ pt: 10 }}
        >
          <Typography variant='h4' color='primary.dark' fontWeight={600}>
            {t("customer.cart.yourCart")}
          </Typography>
        </Stack>
        <Divider sx={{ pt: 1 }} />
        {cart && cart?.length < 1 && (
          <Stack
            spacing={1}
            justifyContent='center'
            alignItems='center'
            sx={{ pt: 2 }}
          >
            <Typography variant='body1' color='text.disabled'>
              {t("customer.cart.emptyCart")}
            </Typography>
          </Stack>
        )}
        {isSuccess &&
          cart?.map((item) => (
            <Box key={item.id}>
              <Stack
                direction='row'
                sx={{ py: 2, px: 2 }}
                alignItems='center'
                justifyContent='space-between'
              >
                <Stack
                  direction='row'
                  spacing={1}
                  alignItems='center'
                  sx={{
                    overflow: "hidden",
                    mr: 3,
                  }}
                >
                  <Avatar
                    src={item.product?.images?.[0]}
                    sx={{ width: 56, height: 56 }}
                  />
                  <Stack>
                    <Typography
                      variant='h6'
                      color='primary.dark'
                      sx={{
                        maxWidth: "186px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item?.product?.name}
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      color='text.secondary'
                      sx={{
                        maxWidth: "186px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {roundToTwoDecimals(
                        item?.quantity * item?.product?.price
                      )}{" "}
                      {t("shared.currencyCZ")}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Stack alignItems='center' spacing={1}>
                    <QuantityInput
                      cartItemQuantity={item?.quantity}
                      isLoading={isLoadingCart || isLoadingCartItem}
                      onLessClick={() => {
                        if (item?.quantity === 1) {
                          setCartItemId(item?.id);
                          setOpenDialog(true);
                        } else
                          onLessClick({
                            cartItemId: item?.id,
                            quantity: item?.quantity,
                          });
                      }}
                      onMoreClick={() =>
                        onMoreClick({
                          cartItemId: item?.id,
                          quantity: item?.quantity,
                        })
                      }
                      moreBtnDisabled={item?.product.inStock <= item?.quantity}
                    />
                  </Stack>
                  <IconButton
                    onClick={() => {
                      setCartItemId(item?.id);
                      setOpenDialog(true);
                    }}
                  >
                    <DeleteForever />
                  </IconButton>
                </Stack>
              </Stack>
              <Divider />
            </Box>
          ))}
        {cart && cart?.length > 0 && (
          <>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={{ pl: 2, pr: 3.5, my: 2 }}
            >
              <Typography variant='h5' color='text.secondary'>
                {t("shared.inTotal")}:
              </Typography>
              <Typography
                variant='h5'
                color='text.secondary'
                sx={{ fontWeight: 500 }}
              >
                {roundToTwoDecimals(pricesTotal ?? 0)} Kč
              </Typography>
            </Stack>
            <Divider />
          </>
        )}
        <StyledLink to='/cart'>
          <Button
            variant='contained'
            sx={{
              mt: 2,
              mb: 2,
              width: "100%",
              borderRadius: "50px",
              color: "secondary.light",
            }}
            disabled={!cart?.length}
            onClick={() => setShowCart(false)}
          >
            {t("customer.cart.orderContinue")}
          </Button>
        </StyledLink>
      </Drawer>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: {
            width: "430px",
            position: "fixed",
            backgroundColor: (theme) => theme.palette.secondary.light,
          },
          elevation: 2,
        }}
      >
        <DeleteDialog
          onClose={() => setOpenDialog(false)}
          onDelete={() => {
            handleDelete({ cartItemId: cartItemId });
          }}
          isLoading={isDeleting}
          textTitle={t("customer.cart.deleteFromCart")}
          textBody={t("customer.cart.deleteFromCartConfirmation")}
        />
      </Dialog>
      <SnackbarMsg
        open={showMsg.removeFromCartMsg}
        handleClose={() =>
          setShowMsg({
            errorMsg: false,
            removeFromCartMsg: false,
          })
        }
        message={t(removedFromCart)}
        severity='success'
      />
      <SnackbarMsg
        open={showMsg.errorMsg}
        handleClose={() =>
          setShowMsg({
            errorMsg: false,
            removeFromCartMsg: false,
          })
        }
        message={t(somethingWentWrong)}
        severity='error'
      />
    </>
  );
};
