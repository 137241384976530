import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Typography,
  Link as LinkMui,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { StyledTextField } from "../../../shared/components/UI/StyledTextField";
import {
  useRegistrateAdmin,
  useVerifyUser,
} from "../../../apiClient/apiClient";
import { useAuth } from "../../../customer/context/AuthContext";
import jwt_decode from "jwt-decode";
import { DecodeType } from "../../../types/types";
import {
  NARROW_SCREEN,
  invalidEmail,
  requiredField,
  somethingWentWrong,
  welcomeMsg,
  userExists,
  pleaseConfirm,
} from "../../../shared/helpers/values";
import { Header } from "../../../customer/components/TopBar/Header";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { SnackbarMsg } from "../../../shared/components/UI/SnackbarMsg";
import { useDebounce } from "../../../shared/hooks/useDebounce";
import { emailRegExp } from "../../../shared/helpers/validators";
import { useTranslation } from "react-i18next";
import conditions from "../../../shared/components/Settings/docs/conditions-seller.pdf";
import gdpr from "../../../shared/components/Settings/docs/gdpr.pdf";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";

const RegistrationSchema = () => {
  const { t } = useTranslation("common");
  return yup.object({
    password: yup.string().required(t(requiredField).toString()),
    email: yup
      .string()
      .matches(emailRegExp, t(invalidEmail).toString())
      .required(t(requiredField).toString())
      .test(
        "$userExists",
        t(userExists).toString(),
        (_, verifyUserContext) =>
          !verifyUserContext?.options?.context?.userExists
      ),
    conditions: yup.bool().oneOf([true], t(pleaseConfirm).toString()),
    gdpr: yup.bool().oneOf([true], t(pleaseConfirm).toString()),
  });
};

export const RegistrationAdmin = () => {
  const { t } = useTranslation("common");
  const [showPassword, setShowPassword] = useState(false);
  const [showMsg, setShowMsg] = useState<{
    successMsg: boolean;
    errorMsg: boolean;
  }>({ successMsg: false, errorMsg: false });
  const [userExists, setUserExists] = useState<boolean>(false);

  const navigate = useNavigate();

  const { mutate: registration, isLoading } = useRegistrateAdmin();
  const { control, handleSubmit, watch } = useForm({
    mode: "onBlur",
    resolver: yupResolver(RegistrationSchema()),
    defaultValues: {
      password: "",
      email: "",
      conditions: false,
      gdpr: false,
    },
    context: {
      userExists,
    },
  });

  const emailValue = watch("email");
  const debounceEmail = useDebounce(emailValue, 200);
  const { data: verifyUserRes, refetch } = useVerifyUser({
    email: debounceEmail,
  });

  useEffect(() => {
    if (verifyUserRes !== undefined) {
      setUserExists(verifyUserRes.data);
    }
  }, [verifyUserRes, setUserExists, debounceEmail]);

  useEffect(() => {
    refetch();
  }, [debounceEmail, refetch]);

  const auth = useAuth();

  const onSubmit = ({ password, email }) => {
    registration(
      { password, email },
      {
        onSuccess: (res) => {
          localStorage.setItem("token", res.data.token);
          const decode: DecodeType = jwt_decode(res?.data?.token);
          auth?.setUser(decode);
          if (decode.roles?.includes("ADMIN")) {
            navigate("/admin/business");
          } else {
            navigate("/app");
          }
          setShowMsg({ successMsg: true, errorMsg: false });
        },
        onError: () => setShowMsg({ successMsg: false, errorMsg: true }),
      }
    );
  };

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  return (
    <>
      <>
        <Header />
        <Stack
          alignItems='center'
          justifyContent='center'
          spacing={5}
          sx={{ mt: 12, mb: 5 }}
        >
          <Stack
            spacing={3}
            alignItems='center'
            justifyContent='center'
            sx={{ width: isNarrow ? "95%" : "60%", textAlign: "center" }}
          >
            <Typography variant='h4' color='primary' sx={{ fontWeight: 500 }}>
              {t("shared.registration.adminTitle")}
            </Typography>
            <Typography
              variant='h4'
              color='text.primary'
              sx={{ fontWeight: 500 }}
            >
              {t("shared.registration.adminSubtitle")}
            </Typography>
          </Stack>
          <Paper
            variant='outlined'
            sx={{
              backgroundColor: "secondary.light",
              borderRadius: "50px",
              borderWidth: "1px",
              borderColor: (theme) => theme.palette.primary.main,
              width: isNarrow ? "95%" : "60%",
              color: (theme) => theme.palette.primary.light,
            }}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              spacing={3}
              sx={{ m: 5 }}
            >
              <Typography
                variant='h4'
                sx={{
                  color: (theme) => theme.palette.primary.dark,
                  fontWeight: 500,
                }}
              >
                {t("shared.registration.registration")}
              </Typography>
              <Typography variant='subtitle1' color='text.secondary'>
                {t("shared.registration.warning")}
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                  <Stack
                    direction='row'
                    spacing={3}
                    alignItems='center'
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      variant='h6'
                      color='text.secondary'
                      sx={{ flex: 1, textAlign: "end" }}
                    >
                      {t("shared.email")}:
                    </Typography>
                    <Controller
                      control={control}
                      name='email'
                      render={({ field, fieldState, formState }) => (
                        <StyledTextField
                          {...field}
                          placeholder={t("shared.email").toString()}
                          variant='outlined'
                          size='small'
                          error={!!fieldState.error}
                          helperText={
                            !!fieldState.error
                              ? fieldState.error.message
                              : undefined
                          }
                          inputProps={{
                            sx: {
                              color: (theme) => theme.palette.text.primary,
                              width: isNarrow ? "200px" : "300px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack
                    direction='row'
                    spacing={3}
                    alignItems='center'
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      variant='h6'
                      color='text.secondary'
                      sx={{ flex: 1, textAlign: "end" }}
                    >
                      {t("shared.password")}:
                    </Typography>
                    <Controller
                      control={control}
                      name='password'
                      render={({ field, fieldState, formState }) => (
                        <StyledTextField
                          {...field}
                          type={showPassword ? "text" : "password"}
                          id='password'
                          placeholder={t("shared.password").toString()}
                          variant='outlined'
                          autoComplete='new-password'
                          size='small'
                          error={!!fieldState.error}
                          sx={{ width: isNarrow ? "228px" : "328px" }}
                          helperText={
                            !!fieldState.error
                              ? fieldState.error.message
                              : undefined
                          }
                          inputProps={{
                            sx: {
                              color: (theme) => theme.palette.text.primary,
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                          InputProps={{
                            sx: {
                              color: (theme) => theme.palette.secondary.main,
                            },
                            endAdornment: (
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={() => setShowPassword(!showPassword)}
                                edge='end'
                                data-cy='show-password-toggler'
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "secondary.dark",
                                  },
                                }}
                              >
                                {showPassword ? (
                                  <VisibilityOff
                                    fontSize='small'
                                    sx={{
                                      color: "primary.dark",
                                      "&:hover": {
                                        color: "primary.light",
                                        transition: "smooth",
                                      },
                                    }}
                                    data-cy='eye-off'
                                  />
                                ) : (
                                  <Visibility
                                    fontSize='small'
                                    sx={{
                                      color: "primary.dark",
                                      "&:hover": {
                                        color: "primary.light",
                                        transition: "smooth",
                                      },
                                    }}
                                    data-testid='eye-on'
                                  />
                                )}
                              </IconButton>
                            ),
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack spacing={0}>
                    <Stack
                      direction='row'
                      spacing={3}
                      alignItems='center'
                      justifyContent='center'
                    >
                      <Controller
                        control={control}
                        name='conditions'
                        render={({ field, fieldState, formState }) => (
                          <FormControl>
                            <Stack
                              direction='row'
                              alignItems='center'
                              spacing={0}
                            >
                              <FormControlLabel
                                disabled={formState.isSubmitting}
                                value={field.value}
                                onChange={() => field.onChange(!field.value)}
                                label={t("shared.registration.agreeWith")}
                                sx={{ mr: 1 }}
                                control={
                                  <Checkbox
                                    checked={field.value}
                                    name='conditions'
                                    size='small'
                                  />
                                }
                                componentsProps={{
                                  typography: {
                                    color: "text.secondary",
                                    variant: "body2",
                                  },
                                }}
                              />
                              <LinkMui
                                href={conditions}
                                target='_blank'
                                underline='always'
                                sx={{ color: "text.secondary" }}
                              >
                                <Typography
                                  variant='body2'
                                  color='text.secondary'
                                >
                                  {t("shared.registration.conditions")}
                                </Typography>
                              </LinkMui>
                            </Stack>
                            {fieldState.error && (
                              <FormHelperText error sx={{ mt: 0, ml: 0 }}>
                                {fieldState.error?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <Controller
                        control={control}
                        name='gdpr'
                        render={({ field, fieldState, formState }) => (
                          <FormControl>
                            <Stack
                              direction='row'
                              alignItems='center'
                              spacing={0}
                            >
                              <FormControlLabel
                                disabled={formState.isSubmitting}
                                value={field.value}
                                onChange={() => field.onChange(!field.value)}
                                label={t("shared.registration.agreeWith")}
                                sx={{ mr: 1 }}
                                control={
                                  <Checkbox
                                    checked={field.value}
                                    name='gdpr'
                                    size='small'
                                  />
                                }
                                componentsProps={{
                                  typography: {
                                    color: "text.secondary",
                                    variant: "body2",
                                  },
                                }}
                              />
                              <LinkMui
                                href={gdpr}
                                target='_blank'
                                underline='always'
                                sx={{ color: "text.secondary" }}
                              >
                                <Typography
                                  variant='body2'
                                  color='text.secondary'
                                >
                                  {t("shared.registration.gdpr")}
                                </Typography>
                              </LinkMui>
                            </Stack>
                            {fieldState.error && (
                              <FormHelperText error sx={{ mt: 0, ml: 0 }}>
                                {fieldState.error?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Stack>
                  </Stack>
                  <Button
                    variant='contained'
                    type='submit'
                    sx={{ borderRadius: "50px", color: "secondary.light" }}
                  >
                    {isLoading && (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: (theme) => theme.palette.secondary.light,
                          mr: 1,
                        }}
                      />
                    )}
                    {t("shared.registration.registerButton")}
                  </Button>
                </Stack>
              </form>
              <StyledLink to='/login'>
                <Button variant='text'>
                  {t("shared.registration.existingAccountButton")}
                </Button>
              </StyledLink>
              <StyledLink to='/registration/customer'>
                <Button variant='outlined' sx={{ borderRadius: "50px" }}>
                  {t("shared.registration.buy")}
                </Button>
              </StyledLink>
            </Stack>
          </Paper>
        </Stack>
      </>
      <SnackbarMsg
        open={showMsg.successMsg}
        handleClose={() => setShowMsg({ successMsg: false, errorMsg: false })}
        severity='success'
        message={t(welcomeMsg)}
      />
      <SnackbarMsg
        open={showMsg.errorMsg}
        handleClose={() => setShowMsg({ successMsg: false, errorMsg: false })}
        severity='error'
        message={t(somethingWentWrong)}
      />
    </>
  );
};
