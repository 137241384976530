import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowBack, InfoOutlined } from "@mui/icons-material";
import { useCreateAdminBusiness } from "../../../apiClient/apiClient";
import { useState } from "react";
import { uploadFile } from "react-s3";
import { UploadImageButton } from "../UI/UploadImageButton";
import { StyledTextField } from "../../../shared/components/UI/StyledTextField";
import {
  NARROW_SCREEN,
  requiredField,
  requiredImage,
} from "../../../shared/helpers/values";
import { Header } from "../../../customer/components/TopBar/Header";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";

const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;
const ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

// todo - picture validation message

const BusinessSchema = () => {
  const { t } = useTranslation("common");
  return yup.object({
    name: yup.string().required(t(requiredField).toString()),
    description: yup.string().required(t(requiredField).toString()),
    images: yup.array().of(yup.string()).required(t(requiredImage).toString()),
    terms: yup.string().optional(),
    gdpr: yup.string().optional(),
  });
};

export const AddBusinessDetail = () => {
  const { t } = useTranslation("common");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const navigate = useNavigate();

  const { mutate: createBusiness, isLoading: isCreating } =
    useCreateAdminBusiness();

  const handleUpload = (file) => {
    setIsUploading(true);
    uploadFile(file, config)
      .then((data: { location: string }) => {
        setUploadedImageUrl(data.location);
        setIsUploading(false);
      })
      .catch((err) => console.error(err));
  };

  const handleFileInput = (e) => {
    handleUpload(e.target.files[0]);
  };

  const { control, handleSubmit, watch } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(BusinessSchema()),
    defaultValues: {
      name: "",
      description: "",
      images: [],
      terms: "",
      gdpr: "",
    },
  });

  const watchValues = watch();

  const onSubmit = () => {
    createBusiness(
      {
        name: watchValues.name,
        description: watchValues.description,
        images: [uploadedImageUrl],
        terms: watchValues.terms,
        gdpr: watchValues.gdpr,
      },
      {
        onSuccess: (data) =>
          navigate(`/admin/business`, {
            replace: true,
          }),
        onError: (err) => console.log(err),
      }
    );
  };

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  return (
    <Stack>
      <Header />
      <Stack alignItems='center' justifyContent='center' sx={{ mt: 10, mb: 1 }}>
        <Stack
          spacing={3}
          alignItems='center'
          justifyContent='center'
          sx={{ width: isNarrow ? "95%" : "60%", textAlign: "center" }}
        >
          <Typography
            variant='h3'
            color='text.primary'
            sx={{ fontWeight: 500 }}
          >
            {t("admin.business.addBusiness")}
          </Typography>
          <Typography variant='h5' color='primary' sx={{ fontWeight: 500 }}>
            {t("admin.business.fillInfo")}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction={isNarrow ? "column" : "row"}
        spacing={isNarrow ? 1 : 5}
        justifyContent='center'
        alignItems={isNarrow ? "center" : "start"}
        sx={{
          mr: !isNarrow ? 12 : 0,
          my: 2,
          mb: 5,
        }}
      >
        <StyledLink to='/admin/business'>
          <IconButton>
            <ArrowBack fontSize='large' color='primary' />
          </IconButton>
        </StyledLink>
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{ m: isNarrow ? 0 : 3, width: isNarrow ? "98%" : "80%" }}
        >
          <Card
            variant='outlined'
            sx={{
              backgroundColor: "transparent",
              borderRadius: "20px",
              borderWidth: "2px",
              borderColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.light,
              alignItems: "center",
              justifyContent: "center",
              p: isNarrow ? 0 : 5,
              py: 2,
              width: "100%",
            }}
          >
            <Stack
              spacing={3}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <Card
                sx={{
                  width: 300,
                  height: 200,
                  display: "flex",
                  backgroundColor: "transparent",
                  borderRadius: "20px",
                  borderStyle: "dashed",
                  borderWidth: "2px",
                  borderColor: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.palette.primary.light,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                variant='outlined'
              >
                {uploadedImageUrl === "" ? (
                  <Typography>{t("admin.business.uploadImage")}</Typography>
                ) : (
                  <img
                    src={uploadedImageUrl}
                    alt='business'
                    style={{ width: 300, height: 200 }}
                  />
                )}
              </Card>
              <UploadImageButton
                handleUpload={handleFileInput}
                isLoading={isUploading}
              />
            </Stack>
            <Divider
              sx={{ pt: 2, borderColor: (theme) => theme.palette.primary.main }}
            />
            <CardContent
              sx={{
                pt: 6,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                  <Stack
                    direction='row'
                    alignItems='center'
                    spacing={4}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      variant='h6'
                      color='primary.dark'
                      sx={{ flex: 1, textAlign: "end" }}
                    >
                      {t("admin.business.businessName")}:
                    </Typography>
                    <Controller
                      control={control}
                      name='name'
                      render={({ field, fieldState, formState }) => (
                        <StyledTextField
                          {...field}
                          variant='outlined'
                          size='small'
                          error={!!fieldState.error}
                          helperText={
                            !!fieldState.error
                              ? fieldState.error.message
                              : undefined
                          }
                          inputProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.dark,
                              backgroundColor: "transparent !important",
                              width: isNarrow ? "180px" : "300px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack
                    direction='row'
                    alignItems='center'
                    spacing={4}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      variant='h6'
                      color='primary.dark'
                      sx={{ flex: 1, textAlign: "end" }}
                    >
                      {t("shared.description")}:
                    </Typography>
                    <Controller
                      control={control}
                      name='description'
                      render={({ field, fieldState, formState }) => (
                        <StyledTextField
                          {...field}
                          multiline
                          variant='outlined'
                          size='small'
                          error={!!fieldState.error}
                          helperText={
                            !!fieldState.error
                              ? fieldState.error.message
                              : undefined
                          }
                          inputProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.dark,
                              backgroundColor: "transparent !important",
                              width: isNarrow ? "180px" : "300px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>

                  <Stack
                    direction='row'
                    alignItems='center'
                    spacing={4}
                    sx={{ justifyContent: "end" }}
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Tooltip
                        title={t("admin.business.termsTooltip")}
                        placement='top'
                        arrow
                      >
                        <InfoOutlined color='primary' />
                      </Tooltip>
                      <Typography
                        variant='h6'
                        color='primary.dark'
                        sx={{ flex: 1, textAlign: "end" }}
                      >
                        {t("admin.business.businessTerms")}:
                      </Typography>
                    </Stack>
                    <Controller
                      control={control}
                      name='terms'
                      render={({ field, fieldState, formState }) => (
                        <StyledTextField
                          {...field}
                          variant='outlined'
                          size='small'
                          error={!!fieldState.error}
                          helperText={
                            !!fieldState.error
                              ? fieldState.error.message
                              : undefined
                          }
                          inputProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.dark,
                              backgroundColor: "transparent !important",
                              width: isNarrow ? "180px" : "300px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>

                  <Stack
                    direction='row'
                    alignItems='center'
                    spacing={4}
                    sx={{ justifyContent: "end" }}
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Tooltip
                        title={t("admin.business.gdprTooltip")}
                        placement='top'
                        arrow
                      >
                        <InfoOutlined color='primary' />
                      </Tooltip>
                      <Typography
                        variant='h6'
                        color='primary.dark'
                        sx={{ flex: 1, textAlign: "end" }}
                      >
                        {t("admin.business.businessGDPR")}:
                      </Typography>
                    </Stack>
                    <Controller
                      control={control}
                      name='gdpr'
                      render={({ field, fieldState, formState }) => (
                        <StyledTextField
                          {...field}
                          variant='outlined'
                          size='small'
                          error={!!fieldState.error}
                          helperText={
                            !!fieldState.error
                              ? fieldState.error.message
                              : undefined
                          }
                          inputProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.dark,
                              backgroundColor: "transparent !important",
                              width: isNarrow ? "180px" : "300px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Button
                    type='submit'
                    variant='contained'
                    sx={{ alignSelf: "center" }}
                  >
                    {isCreating && (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: (theme) => theme.palette.secondary.light,
                          mr: 1,
                        }}
                      />
                    )}
                    {t("shared.save")}
                  </Button>
                </Stack>
              </form>
            </CardContent>
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              justifyContent='center'
            >
              <InfoOutlined sx={{ color: "text.secondary" }} />
              <Typography
                variant='subtitle1'
                color='text.secondary'
                sx={{ textAlign: "center" }}
              >
                {t("admin.business.mustBeVerified")}
              </Typography>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </Stack>
  );
};
