import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material';
import { CSSProperties, useState } from 'react';

type CarouselSlidesProps = {
  slides: string[];
};

export const CarouselSlider = ({ slides }: CarouselSlidesProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slideStyles: CSSProperties = {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    backgroundPosition: 'cover',
    backgroundSize: '100%',
  };

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div
      style={{
        height: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translate(0, -50%)',
          left: '20px',
          color: '#fff',
          zIndex: 1,
          cursor: 'pointer',
          opacity: 0.9,
        }}
        onClick={goToPrevious}
      >
        <ArrowCircleLeft fontSize='large' />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translate(0, -50%)',
          right: '20px',
          color: '#fff',
          zIndex: 1,
          cursor: 'pointer',
          opacity: 0.9,
        }}
        onClick={goToNext}
      >
        <ArrowCircleRight fontSize='large' />
      </div>
      {slides?.map((slide, slideIndex) => (
        <div
          style={slideStyles}
          className={slideIndex === currentIndex ? 'active picture' : 'picture'}
          key={slideIndex}
        >
          {slideIndex === currentIndex && (
            <img src={slide} alt={`slide-${slideIndex}`} style={slideStyles} />
          )}
        </div>
      ))}
    </div>
  );
};
