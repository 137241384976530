import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useParams } from "react-router-dom";
import { CarouselSlider } from "../../../shared/components/UI/CarouselSlider";
import { useGetCustomerBusiness } from "../../../apiClient/apiClient";
import { Spinner } from "../../../shared/components/UI/Spinner";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { NARROW_SCREEN } from "../../../shared/helpers/values";
import { TermsAndGDPRLinks } from "../../../shared/components/UI/TermsAndGDPRLinks";

export const containerStyles = {
  width: "500px",
  height: "280px",
  margin: "0 auto",
  marginTop: 38,
};
export const BusinessDetailCustomer = () => {
  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useGetCustomerBusiness({
    businessId: String(id),
  });
  const business = data?.data;

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Stack
          alignItems='start'
          sx={{ m: isNarrow ? 1 : 5, ml: isNarrow ? 1 : 35, mt: 10 }}
          direction='row'
        >
          <Card
            variant='outlined'
            sx={{
              backgroundColor: (theme) => theme.palette.secondary.light,
              borderRadius: "50px",
              borderWidth: "2px",
              borderColor: (theme) => theme.palette.primary.main,
              width: "100%",
              color: (theme) => theme.palette.primary.light,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack alignItems='center' justifyContent='center'>
              {isNarrow ? (
                <CardMedia sx={{ width: "100%" }}>
                  <CarouselSlider slides={business?.images} />
                </CardMedia>
              ) : (
                <CardMedia>
                  <div style={containerStyles}>
                    <CarouselSlider slides={business?.images} />
                  </div>
                </CardMedia>
              )}
              <CardContent sx={{ pt: 6, mx: 5 }}>
                <Stack spacing={2}>
                  <Stack alignItems='center'>
                    <Typography variant='h3' color='primary.dark'>
                      {business?.name}
                    </Typography>
                  </Stack>
                  <Stack alignItems='start'>
                    <Typography color='text.secondary'>
                      {business?.description}
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Stack>
            <Stack sx={{ pl: 2, pb: 2 }}>
              {business?.terms && business?.gdpr && (
                <TermsAndGDPRLinks
                  terms={business?.terms}
                  gdpr={business?.gdpr}
                  small
                />
              )}
            </Stack>
          </Card>
        </Stack>
      )}
    </>
  );
};
