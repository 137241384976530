import { Divider, List } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { DrawerListCategoryAboutUsAdmin } from './DrawerListCategoryAboutUsAdmin';
import { DrawerListCategoryBusinessProductsAdmin } from './DrawerListCategoryBusinessProductsAdmin';

export const DrawerListBusinessAdmin = () => (
  <>
    <Divider
      sx={{ backgroundColor: (theme) => theme.palette.secondary.dark }}
    />
    <List sx={{ width: '100%', maxWidth: 360, pt: 0 }} component='nav'>
      <Stack spacing={1}>
        <DrawerListCategoryAboutUsAdmin />
        <Divider
          sx={{ backgroundColor: (theme) => theme.palette.secondary.dark }}
        />
        <DrawerListCategoryBusinessProductsAdmin />
      </Stack>
    </List>
  </>
);
