import { DoNotDisturb, Search } from "@mui/icons-material";
import {
  Avatar,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { StyledTextFieldSearch } from "../../../shared/components/UI/StyledTextFieldSearch";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { useGetAllProducts } from "../../../apiClient/apiClient";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { NARROW_SCREEN } from "../../../shared/helpers/values";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";

export const SearchBar = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const { t } = useTranslation("common");

  const searchRef = useRef(null);

  const {
    data: searchedProducts,
    isLoading,
    refetch,
  } = useGetAllProducts({
    search: searchValue,
  });

  const onFieldChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    refetch();
    setAnchorEl(searchRef.current);
  };

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  return (
    <>
      <form>
        <StyledTextFieldSearch
          type='text'
          ref={searchRef}
          id='search-bar'
          color='secondary'
          placeholder={t("shared.search").toString() + "..."}
          variant='outlined'
          size='small'
          onChange={onFieldChange}
          onSubmit={handleSearch}
          sx={{ width: isNarrow ? "70px" : "500px" }}
          inputProps={{
            sx: {
              color: (theme) => theme.palette.primary.dark,
            },
          }}
          InputLabelProps={{
            sx: { color: (theme) => theme.palette.primary.dark },
          }}
          InputProps={{
            sx: {
              color: (theme) => theme.palette.secondary.main,
              backgroundColor: (theme) => theme.palette.secondary.main,
              borderRadius: "50px",
              height: "36px",
            },
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  edge='end'
                  onClick={handleSearch}
                  disabled={searchValue === ""}
                  size='small'
                  type='submit'
                >
                  <Search
                    fontSize='medium'
                    sx={{
                      color: "primary.main",
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
      <Menu
        open={openMenu}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{ mt: 2.5 }}
        PaperProps={{
          sx: { minWidth: isNarrow ? "90%" : 500, maxHeight: "50%" },
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : searchedProducts?.data?.length < 1 ? (
          <Stack direction='row' spacing={2} sx={{ m: 2 }}>
            <DoNotDisturb />
            <Typography>{t("customer.search.noProduct")}</Typography>
          </Stack>
        ) : (
          searchedProducts?.data?.map((product, index) => (
            <span key={product.id}>
              <StyledLink to={`/app/product/${product.id}`}>
                <MenuItem>
                  <Stack
                    direction='row'
                    spacing={1}
                    alignItems='center'
                    sx={{
                      overflow: "hidden",
                    }}
                  >
                    <Avatar
                      src={product.images[0]}
                      sx={{ width: 56, height: 56 }}
                    />
                    <Stack>
                      <Typography
                        variant='h6'
                        color='primary.dark'
                        sx={{
                          maxWidth: "400px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {product.name}
                      </Typography>
                      <Typography
                        variant='subtitle2'
                        color='text.secondary'
                        sx={{
                          maxWidth: "400px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "normal",
                          display: "-webkit-box !important",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {product.description}
                      </Typography>
                    </Stack>
                  </Stack>
                </MenuItem>
              </StyledLink>
              {searchedProducts?.data.length > 1 &&
                searchedProducts?.data.length !== index + 1 && <Divider />}
            </span>
          ))
        )}
      </Menu>
    </>
  );
};
