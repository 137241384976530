import { StepLabel, stepLabelClasses, styled } from '@mui/material';

export const StyledStepLabel = styled(StepLabel)(() => ({
  [`& .${stepLabelClasses.label}`]: {
    [`&.${stepLabelClasses.completed}`]: {
      color: '#e1994b',
    },
    [`&.${stepLabelClasses.active}`]: {
      color: '#e1994b',
    },

    color: '#e9b782',
  },
}));
