import { Alert, Snackbar } from '@mui/material';

type SnackbarProps = {
  open: boolean;
  handleClose: () => void;
  message: string;
  severity: 'error' | 'info' | 'success' | 'warning';
};

export const SnackbarMsg = ({
  message,
  open,
  handleClose,
  severity,
}: SnackbarProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: '100%' }}
        elevation={6}
        variant='filled'
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
