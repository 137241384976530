import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../../apiClient/apiClient";
import { StyledTextField } from "../../../shared/components/UI/StyledTextField";
import { useAuth } from "../../context/AuthContext";
import jwt_decode from "jwt-decode";
import { DecodeType } from "../../../types/types";
import { Header } from "../TopBar/Header";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { useTranslation } from "react-i18next";
import {
  NARROW_SCREEN,
  invalidCredentials,
  requiredField,
  welcomeMsg,
} from "../../../shared/helpers/values";
import { SnackbarMsg } from "../../../shared/components/UI/SnackbarMsg";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";

export const Login = () => {
  const { t } = useTranslation("common");
  const [showPassword, setShowPassword] = useState(false);
  const [showMsg, setShowMsg] = useState<{
    successMsg: boolean;
    errorMsg: boolean;
  }>({ successMsg: false, errorMsg: false });
  const LoginSchema = () => {
    return yup.object({
      email: yup.string().required(t(requiredField).toString()),
      password: yup.string().required(t(requiredField).toString()),
    });
  };
  const navigate = useNavigate();

  const { mutate: login } = useLogin();
  const { control, handleSubmit } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(LoginSchema()),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const auth = useAuth();

  const onSubmit = ({ email, password }) => {
    login(
      { email, password },
      {
        onSuccess: (res) => {
          localStorage.setItem("token", res.data.token);
          const decode: DecodeType = jwt_decode(res?.data?.token);
          auth?.setUser(decode);
          if (decode.roles?.includes("ADMIN")) {
            navigate("/admin/business");
          } else {
            navigate("/app");
          }
          setShowMsg({ successMsg: true, errorMsg: false });
        },
        onError: () => setShowMsg({ successMsg: false, errorMsg: true }),
      }
    );
  };

  const handleRegistrationClick = (e) => {
    e.preventDefault();
    navigate("/registration");
  };

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  return (
    <>
      <>
        <Header />
        <Stack
          alignItems='center'
          justifyContent='center'
          spacing={5}
          sx={{ m: isNarrow ? 0 : 5, mt: 12, mb: 5, overflowX: "hidden" }}
        >
          <Stack spacing={2} alignItems='center' justifyContent='center'>
            <Typography
              variant='h3'
              color='text.secondary'
              sx={{ fontWeight: 500 }}
            >
              {t("shared.login.title")}
            </Typography>
          </Stack>
          <Paper
            variant='outlined'
            sx={{
              backgroundColor: "secondary.light",
              borderRadius: "50px",
              borderWidth: "2px",
              borderColor: (theme) => theme.palette.primary.main,
              width: isNarrow ? "95%" : "60%",
              color: (theme) => theme.palette.primary.light,
            }}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              spacing={4}
              sx={{ m: 5 }}
            >
              <Typography
                variant='h4'
                sx={{
                  color: (theme) => theme.palette.primary.dark,
                  textAlign: "center",
                }}
              >
                {t("shared.login.subtitle")}
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                  <Stack
                    direction='row'
                    spacing={3}
                    alignItems='center'
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      variant='h6'
                      color='text.secondary'
                      sx={{ flex: 1, textAlign: "end" }}
                    >
                      {t("shared.email")}:
                    </Typography>
                    <Controller
                      control={control}
                      name='email'
                      render={({ field, fieldState, formState }) => (
                        <StyledTextField
                          {...field}
                          variant='outlined'
                          size='small'
                          error={!!fieldState.error}
                          helperText={
                            !!fieldState.error
                              ? fieldState.error.message
                              : undefined
                          }
                          inputProps={{
                            sx: {
                              color: (theme) => theme.palette.text.primary,
                              backgroundColor: "transparent !important",
                              width: isNarrow ? "200px" : "300px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack
                    direction='row'
                    spacing={3}
                    alignItems='center'
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      variant='h6'
                      color='text.secondary'
                      sx={{ flex: 1, textAlign: "end" }}
                    >
                      {t("shared.password")}:
                    </Typography>
                    <Controller
                      control={control}
                      name='password'
                      render={({ field, fieldState, formState }) => (
                        <StyledTextField
                          {...field}
                          type={showPassword ? "text" : "password"}
                          id='password'
                          variant='outlined'
                          autoComplete='new-password'
                          size='small'
                          error={!!fieldState.error}
                          sx={{ width: isNarrow ? "228px" : "328px" }}
                          helperText={
                            !!fieldState.error
                              ? fieldState.error.message
                              : undefined
                          }
                          inputProps={{
                            sx: {
                              color: (theme) => theme.palette.text.primary,
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                          InputProps={{
                            sx: {
                              color: (theme) => theme.palette.secondary.main,
                            },
                            endAdornment: (
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={() => setShowPassword(!showPassword)}
                                edge='end'
                                data-cy='show-password-toggler'
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "secondary.dark",
                                  },
                                }}
                              >
                                {showPassword ? (
                                  <VisibilityOff
                                    fontSize='small'
                                    sx={{
                                      color: "primary.dark",
                                      "&:hover": {
                                        color: "primary.light",
                                        transition: "smooth",
                                      },
                                    }}
                                    data-cy='eye-off'
                                  />
                                ) : (
                                  <Visibility
                                    fontSize='small'
                                    sx={{
                                      color: "primary.dark",
                                      "&:hover": {
                                        color: "primary.light",
                                        transition: "smooth",
                                      },
                                    }}
                                    data-testid='eye-on'
                                  />
                                )}
                              </IconButton>
                            ),
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Button
                    variant='contained'
                    type='submit'
                    sx={{ borderRadius: "50px", color: "secondary.light" }}
                  >
                    {t("shared.login.loginButton")}
                  </Button>
                </Stack>
              </form>
              <Stack spacing={1}>
                <StyledLink to='/password-recovery'>
                  <Button variant='text'>
                    {t("shared.login.forgotButton")}
                  </Button>
                </StyledLink>
                <Button
                  variant='outlined'
                  sx={{ borderRadius: "50px" }}
                  onClick={handleRegistrationClick}
                >
                  {t("shared.login.registrationButton")}
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </>
      <SnackbarMsg
        open={showMsg.successMsg}
        handleClose={() => setShowMsg({ successMsg: false, errorMsg: false })}
        severity='success'
        message={t(welcomeMsg)}
      />
      <SnackbarMsg
        open={showMsg.errorMsg}
        handleClose={() => setShowMsg({ successMsg: false, errorMsg: false })}
        severity='error'
        message={t(invalidCredentials)}
      />
    </>
  );
};
