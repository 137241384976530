import { ShoppingBagOutlined } from "@mui/icons-material";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";

export const DrawerListCategoryBusinessProductsAdmin = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();

  return (
    <StyledLink to={`/admin/business/${id}/products`}>
      <ListItemButton
        sx={{
          height: 25,
          borderRadius: 2,
          ":hover": {
            backgroundColor: "#d4d5f235",
          },
          display: "flex",
          pl: 2,
        }}
      >
        <Stack direction='row' spacing={1} alignItems='center'>
          <ListItemIcon>
            <ShoppingBagOutlined color='secondary' fontSize='small' />
          </ListItemIcon>
          <ListItemText
            primary={t("shared.products")}
            sx={{ color: (theme) => theme.palette.secondary.main }}
          />
        </Stack>
      </ListItemButton>
    </StyledLink>
  );
};
