import { Card, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

export const StyledCard = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps<Theme>;
}) => {
  return (
    <Card
      elevation={2}
      sx={{
        width: 280,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: (theme) => theme.palette.primary.main,
        borderRadius: 4,
        mb: 4,
        mx: 1,
        ':hover': {
          boxShadow: 6,
          transition: '250ms',
          transitionDelay: 'inherit',
        },
        ...sx,
      }}
    >
      {children}
    </Card>
  );
};
