import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import { useGetAllProducts } from "../../../apiClient/apiClient";
import { ProductCard } from "./ProductCard";
import "./Products.css";
import { ProductsSkeleton } from "../../../shared/components/UI/ProductsSkeleton";
import { CategoryHeader } from "./CategoryHeader";
import foodImg from "./categoryImgs/foodCategoryImg.svg";
import clothesImg from "./categoryImgs/clothesCategoryImg.svg";
import dishesImg from "./categoryImgs/dishesCategoryImg.svg";
import educationImg from "./categoryImgs/educationCategoryImg.svg";
import cosmeticsImg from "./categoryImgs/cosmeticsCategoryImg.svg";
import accessoriesImg from "./categoryImgs/accessoriesCategoryImg.svg";
import furnitureImg from "./categoryImgs/furnitureCategoryImg.svg";
import jewelryImg from "./categoryImgs/jewelryCategoryImg.svg";
import petsImg from "./categoryImgs/petsCategoryImg.svg";
import servicesImg from "./categoryImgs/servicesCategoryImg.svg";
import artImg from "./categoryImgs/artCategoryImg.svg";
import allCategories from "./categoryImgs/allCategories.svg";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";

export const AllProducts = ({ categories }: { categories?: string }) => {
  const { t } = useTranslation("common");
  const {
    data: productsRes,
    isSuccess,
    isLoading,
    refetch,
  } = useGetAllProducts({
    categories: categories ?? undefined,
    page: 0,
    pageSize: 200,
  });

  useEffect(() => {
    refetch();
  }, [refetch, categories]);

  const filteredProducts = productsRes?.data;

  if (isLoading) {
    return (
      <Stack
        sx={{ alignItems: "center", justifyContent: "center", pt: 12, pb: 5 }}
      >
        <div className='masonry'>
          <ProductsSkeleton n={8} isMasonry />
        </div>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{ alignItems: "center", justifyContent: "center", pt: 10, pb: 5 }}
    >
      <>
        {!categories && (
          <CategoryHeader
            allCategories
            mainImg={allCategories}
            categoryName={t("customer.drawer.categories.all")}
          />
        )}
        {categories && categories.length && categories.includes("FOOD") && (
          <CategoryHeader
            mainImg={foodImg}
            categoryName={t("customer.drawer.categories.food")}
          />
        )}
        {categories && categories.length && categories.includes("CLOTHING") && (
          <CategoryHeader
            mainImg={clothesImg}
            categoryName={t("customer.drawer.categories.clothing")}
          />
        )}
        {categories && categories.length && categories.includes("DISHES") && (
          <CategoryHeader
            mainImg={dishesImg}
            categoryName={t("customer.drawer.categories.dishes")}
          />
        )}
        {categories &&
          categories.length &&
          categories.includes("EDUCATION") && (
            <CategoryHeader
              mainImg={educationImg}
              categoryName={t("customer.drawer.categories.education")}
            />
          )}
        {categories &&
          categories.length &&
          categories.includes("COSMETICS") && (
            <CategoryHeader
              mainImg={cosmeticsImg}
              categoryName={t("customer.drawer.categories.cosmetics")}
            />
          )}
        {categories &&
          categories.length &&
          categories.includes("ACCESSORIES") && (
            <CategoryHeader
              mainImg={accessoriesImg}
              categoryName={t("customer.drawer.categories.accessories")}
            />
          )}
        {categories &&
          categories.length &&
          categories.includes("FURNITURE") && (
            <CategoryHeader
              mainImg={furnitureImg}
              categoryName={t("customer.drawer.categories.furniture")}
            />
          )}
        {categories && categories.length && categories.includes("JEWELRY") && (
          <CategoryHeader
            mainImg={jewelryImg}
            categoryName={t("customer.drawer.categories.jewelry")}
          />
        )}
        {categories && categories.length && categories.includes("PETS") && (
          <CategoryHeader
            mainImg={petsImg}
            categoryName={t("customer.drawer.categories.pets")}
          />
        )}
        {categories && categories.length && categories.includes("SERVICES") && (
          <CategoryHeader
            mainImg={servicesImg}
            categoryName={t("customer.drawer.categories.services")}
          />
        )}
        {categories && categories.length && categories.includes("ART") && (
          <CategoryHeader
            mainImg={artImg}
            categoryName={t("customer.drawer.categories.art")}
          />
        )}
        {isSuccess && filteredProducts?.length > 0 ? (
          <div className='masonry'>
            {filteredProducts?.map(
              ({ id, name, description, images, price, inStock }) => (
                <StyledLink to={`product/${id}`} key={id}>
                  <ProductCard
                    id={id}
                    name={name}
                    description={description}
                    images={images}
                    price={price}
                    inStock={inStock}
                  />
                </StyledLink>
              )
            )}
          </div>
        ) : (
          <Stack
            alignItems='center'
            justifyContent='center'
            sx={{ height: "100%", width: "100%" }}
          >
            <Typography variant='h4' color='primary.dark'>
              {t("customer.businesses.notFound")}
            </Typography>
          </Stack>
        )}
        <Outlet />
      </>
    </Stack>
  );
};
