import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export type CartContextType = {
  showCart: boolean;
  setShowCart: Dispatch<SetStateAction<boolean>>;
};

export const CartContext = createContext({} as CartContextType);
export const useCartContext = () => useContext(CartContext);

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const [showCart, setShowCart] = useState(false);

  return (
    <CartContext.Provider
      value={{
        showCart,
        setShowCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
