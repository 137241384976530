import { Button, CircularProgress, Input } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { uploadFile } from "react-s3";
import {
  useGetAdminBusiness,
  useUpdateAdminBusiness,
} from "../../../apiClient/apiClient";
import { useTranslation } from "react-i18next";
window.Buffer = window.Buffer || require("buffer").Buffer;

const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;
const ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

export const UploadImage = () => {
  const { t } = useTranslation("common");
  const [isUpload, setIsUpload] = useState(false);
  const { id } = useParams<{ id: string }>();

  const { data: getBusiness, isLoading: isLoadingBusiness } =
    useGetAdminBusiness({ businessId: String(id) });

  const { mutate: updateBusiness, isLoading: isUpdating } =
    useUpdateAdminBusiness();

  const handleUpload = (file) => {
    setIsUpload(true);
    uploadFile(file, config)
      .then((data: { location: string }) => {
        updateBusiness({
          id: String(id),
          images: [data.location, ...getBusiness?.data.images],
        });
        setIsUpload(false);
      })
      .catch((err) => console.error(err));
  };

  const handleFileInput = (e) => {
    handleUpload(e.target.files[0]);
  };

  const isLoading = isLoadingBusiness || isUpload || isUpdating;

  return (
    <Button component='label' variant='contained' sx={{ width: 180 }}>
      {isLoading && (
        <CircularProgress
          size={20}
          sx={{ color: (theme) => theme.palette.secondary.light, mr: 1 }}
        />
      )}
      {t("admin.business.fileUpload")}
      <Input
        onChange={handleFileInput}
        sx={{
          display: "none",
        }}
        type='file'
      />
    </Button>
  );
};
