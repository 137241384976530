import { Paper, Stack, Typography } from '@mui/material';
import { Header } from '../../../customer/components/TopBar/Header';
import { useWindowSize } from '../../hooks/useWindowSize';
import { NARROW_SCREEN } from '../../helpers/values';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const EmailSent = () => {
  const { t } = useTranslation('common');
  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;
  return (
    <>
      <Header />
      <Stack
        alignItems='center'
        justifyContent='center'
        spacing={5}
        sx={{ m: isNarrow ? 0 : 5, mt: 12, mb: 5, overflowX: 'hidden' }}
      >
        <Paper
          variant='outlined'
          sx={{
            backgroundColor: 'secondary.light',
            borderRadius: '50px',
            borderWidth: '2px',
            borderColor: (theme) => theme.palette.primary.main,
            width: isNarrow ? '95%' : '60%',
            color: (theme) => theme.palette.primary.light,
          }}
        >
          <Stack
            alignItems='center'
            justifyContent='center'
            spacing={4}
            sx={{ m: 5 }}
          >
            <Check color='success' fontSize='large' />

            <Typography
              variant='h4'
              sx={{
                color: (theme) => theme.palette.text.secondary,
                textAlign: 'center',
                fontWeight: 500,
              }}
            >
              {t('shared.recovery.emailSent')}
            </Typography>
            <Typography
              variant='h6'
              sx={{
                color: (theme) => theme.palette.primary.dark,
                textAlign: 'center',
              }}
            >
              {t('shared.recovery.checkEmail')}
            </Typography>
            <Typography
              variant='body1'
              sx={{
                color: (theme) => theme.palette.text.secondary,
                textAlign: 'center',
              }}
            >
              {t('shared.recovery.checkSpam')}
            </Typography>
            <Stack spacing={3}></Stack>
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};
