import { FileOpenOutlined } from "@mui/icons-material";
import { Link, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type TermsAndGDPRLinksProps = {
  terms: string;
  gdpr: string;
  small?: boolean;
};

export const TermsAndGDPRLinks = ({
  terms,
  gdpr,
  small,
}: TermsAndGDPRLinksProps) => {
  const { t } = useTranslation("common");
  return (
    <>
      <Stack direction='row' spacing={1} alignItems='center'>
        <FileOpenOutlined
          fontSize={small ? "inherit" : "small"}
          sx={{
            color: (theme) => theme.palette.text.secondary,
          }}
        />
        <Link href={terms} target='_blank'>
          <Typography
            color='text.primary'
            variant={small ? "caption" : "body1"}
          >
            {t("admin.business.sellersTerms")}
          </Typography>
        </Link>
      </Stack>
      <Stack direction='row' spacing={1} alignItems='center'>
        <FileOpenOutlined
          fontSize={small ? "inherit" : "small"}
          sx={{
            color: (theme) => theme.palette.text.secondary,
          }}
        />
        <Link href={gdpr} target='_blank'>
          <Typography
            color='text.primary'
            variant={small ? "caption" : "body1"}
          >
            {t("admin.business.sellersGDPR")}
          </Typography>
        </Link>
      </Stack>
    </>
  );
};
