import { Grid, Skeleton } from '@mui/material';
import { Stack } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useGetAdminProducts } from '../../../apiClient/apiClient';
import { Header } from '../../../customer/components/TopBar/Header';
import { DrawerListBusinessAdmin } from '../DrawerBusinessAdmin/DrawerListBusinessAdmin';
import { AddProductCard } from './AddProductCard';
import { ProductCardAdmin, ProductCardProps } from './ProductCardAdmin';
import { useWindowSize } from '../../../shared/hooks/useWindowSize';
import { NARROW_SCREEN } from '../../../shared/helpers/values';

export const ProductsAdmin = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetAdminProducts({ businessId: String(id) });

  const products: ProductCardProps[] = data?.data;

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  if (isLoading) {
    return (
      <>
        <Header children={<DrawerListBusinessAdmin />} />
        <Grid
          container
          sx={{ mt: 10, mb: 5, pl: isNarrow ? 0 : 30 }}
          spacing={2}
          justifyContent='center'
        >
          <Grid item>
            <Skeleton
              variant='rounded'
              width={300}
              height={350}
              sx={{ mb: 3 }}
            />
          </Grid>
          <Grid item>
            <Skeleton
              variant='rounded'
              width={300}
              height={350}
              sx={{ mb: 3 }}
            />
          </Grid>
          <Grid item>
            <Skeleton
              variant='rounded'
              width={300}
              height={350}
              sx={{ mb: 3 }}
            />
          </Grid>
          <Grid item>
            <Skeleton
              variant='rounded'
              width={300}
              height={350}
              sx={{ mb: 3 }}
            />
          </Grid>
          <Grid item>
            <Skeleton
              variant='rounded'
              width={300}
              height={350}
              sx={{ mb: 3 }}
            />
          </Grid>
          <Grid item>
            <Skeleton
              variant='rounded'
              width={300}
              height={350}
              sx={{ mb: 3 }}
            />
          </Grid>
          <Grid item>
            <Skeleton
              variant='rounded'
              width={300}
              height={350}
              sx={{ mb: 3 }}
            />
          </Grid>
          <Grid item>
            <Skeleton
              variant='rounded'
              width={300}
              height={350}
              sx={{ mb: 3 }}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Header children={<DrawerListBusinessAdmin />} />
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          overflow: 'auto',
        }}
      >
        <Stack>
          <Grid
            container
            sx={{ mt: 10, mb: 5, pl: isNarrow ? 0 : 30 }}
            spacing={2}
            justifyContent='center'
          >
            <Grid item>
              <AddProductCard />
            </Grid>
            {products?.map((product) => (
              <Grid item key={product.id}>
                <ProductCardAdmin {...product} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </div>
    </>
  );
};
