import "./App.css";
import React from "react";
import { ThemeProvider } from "@mui/material";
import theme from "./theme/theme";
import { AppRoutes } from "./routes/Routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { CartProvider } from "./customer/context/CartContext";
import { AuthProvider } from "./customer/context/AuthContext";
import { TranslationProvider } from "./shared/context/TranslationContext";
import { DeliveryDetailsProvider } from "./customer/context/DeliveryDetailsContext";
import { CookieConsentProvider } from "./shared/components/UI/CookieConsentProvider";

const App = () => {
  const queryClient = new QueryClient();

  return (
    <TranslationProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <CartProvider>
              <DeliveryDetailsProvider>
                <div className='bg'>
                  <AppRoutes />
                </div>
              </DeliveryDetailsProvider>
            </CartProvider>
            <CookieConsentProvider />
          </AuthProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </TranslationProvider>
  );
};

export default App;
