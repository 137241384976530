import { FileOpenOutlined } from "@mui/icons-material";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import conditionsBuyer from "../../../shared/components/Settings/docs/conditions-buyer.pdf";
import conditionsSeller from "../../../shared/components/Settings/docs/conditions-seller.pdf";
import gdpr from "../../../shared/components/Settings/docs/gdpr.pdf";

type SettingsTermsAndConditionsProps = {
  isBuyer?: boolean;
};

export const SettingsTermsAndConditions = ({
  isBuyer,
}: SettingsTermsAndConditionsProps) => {
  const { t } = useTranslation("common");

  return (
    <Card
      variant='outlined'
      sx={{
        backgroundColor: (theme) => theme.palette.secondary.light,
        borderRadius: "50px",
        borderWidth: "2px",
        borderColor: (theme) => theme.palette.primary.main,
        width: "100%",
        color: (theme) => theme.palette.primary.light,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CardContent
        sx={{
          my: 6,
          mx: 5,
        }}
      >
        <Stack spacing={3} alignItems='center' justifyContent='center'>
          <Stack
            direction='row'
            spacing={2}
            alignItems='center'
            sx={{ justifyContent: "space-between" }}
          >
            <Typography
              variant='h6'
              color='text.secondary'
              sx={{ flex: 1, textAlign: "end" }}
            >
              {t("shared.conditions")}:
            </Typography>
            <Link
              to={isBuyer ? conditionsBuyer : conditionsSeller}
              target='_blank'
              style={{ textDecoration: "none" }}
            >
              <Button variant='text'>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <FileOpenOutlined
                    fontSize='small'
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  />
                  <Typography color='text.secondary'>
                    {t("shared.conditionsOpen")}
                  </Typography>
                </Stack>
              </Button>
            </Link>
          </Stack>
          <Stack
            direction='row'
            spacing={3}
            alignItems='center'
            sx={{ justifyContent: "space-between" }}
          >
            <Typography
              variant='h6'
              color='text.secondary'
              sx={{ flex: 1, textAlign: "end" }}
            >
              {t("shared.gdpr")}:
            </Typography>
            <Link to={gdpr} target='_blank' style={{ textDecoration: "none" }}>
              <Button variant='text'>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <FileOpenOutlined
                    fontSize='small'
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  />
                  <Typography color='text.secondary'>
                    {t("shared.gdprOpen")}
                  </Typography>
                </Stack>
              </Button>
            </Link>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
