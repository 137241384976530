import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import { useEffect, useState } from "react";
import { uploadFile } from "react-s3";
import { useCreateAdminProduct } from "../../../apiClient/apiClient";
import { UploadImageButton } from "../UI/UploadImageButton";
import { StyledTextField } from "../../../shared/components/UI/StyledTextField";
import {
  NARROW_SCREEN,
  requiredField,
  requiredImage,
} from "../../../shared/helpers/values";
import { Header } from "../../../customer/components/TopBar/Header";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { categories as options } from "../../../customer/components/Drawer/DrawerHome/DrawerListCategories";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";

const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;
const ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

const ProductSchema = () => {
  const { t } = useTranslation("common");
  return yup.object({
    name: yup.string().required(t(requiredField).toString()),
    description: yup.string().required(t(requiredField).toString()),
    price: yup.number().required(t(requiredField).toString()),
    images: yup.array().required(t(requiredImage).toString()),
    inStock: yup.number().required(t(requiredField).toString()),
    categories: yup
      .array()
      .of(yup.string())
      .required(t(requiredField).toString()),
  });
};

export const AddProductDetail = () => {
  const { t } = useTranslation("common");
  const { id } = useParams<{ id: string }>();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [categories, setCategories] = useState<string[]>([]);
  const [priceWithProvision, setPriceWithProvision] = useState<number>(0);

  const { mutate: createProduct, isLoading: isCreating } =
    useCreateAdminProduct();

  const handleUpload = (file) => {
    setIsUploading(true);
    uploadFile(file, config)
      .then((data: { location: string }) => {
        setUploadedImageUrl(data.location);
        setIsUploading(false);
      })
      .catch((err) => console.error(err));
  };

  const handleFileInput = (e) => {
    handleUpload(e.target.files[0]);
  };

  const { control, handleSubmit, watch } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(ProductSchema()),
    defaultValues: {
      name: "",
      description: "",
      images: [],
      price: 0,
      inStock: 0,
      categories: [],
    },
  });

  const watchValues = watch();

  useEffect(() => {
    setPriceWithProvision(Math.round(watchValues.price * 1.2));
  }, [watchValues.price]);

  const navigate = useNavigate();

  const selectedCategories = options.filter((category) =>
    categories.includes(category.locale)
  );

  const onSubmit = () => {
    createProduct(
      {
        businessId: String(id),
        name: watchValues.name,
        description: watchValues.description,
        images: [uploadedImageUrl],
        price: +watchValues.price,
        inStock: +watchValues.inStock,
        categories: selectedCategories.map(({ name }) => name),
      },
      {
        onSuccess: (data) =>
          navigate(`/admin/business/${id}/products/`, {
            replace: true,
          }),
        onError: (err) => console.log(err),
      }
    );
  };

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  return (
    <Stack>
      <Header />
      {/* TODO - create a one separate component for this editor (add/edit product/business)  */}
      <Stack alignItems='center' justifyContent='center' sx={{ mt: 10, mb: 1 }}>
        <Stack
          spacing={3}
          alignItems='center'
          justifyContent='center'
          sx={{ width: isNarrow ? "95%" : "60%", textAlign: "center" }}
        >
          <Typography
            variant='h4'
            color='text.primary'
            sx={{ fontWeight: 500 }}
          >
            {t("admin.product.addProduct")}
          </Typography>
          <Typography variant='h5' color='primary' sx={{ fontWeight: 500 }}>
            {t("admin.product.fillInfo")}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction={isNarrow ? "column" : "row"}
        spacing={isNarrow ? 1 : 5}
        justifyContent='center'
        alignItems={isNarrow ? "center" : "start"}
        sx={{ my: 2, mr: isNarrow ? 0 : 12, mb: 5 }}
      >
        <StyledLink to={`/admin/business/${id}/products`}>
          <IconButton>
            <ArrowBack fontSize='large' color='primary' />
          </IconButton>
        </StyledLink>
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{ m: 3, mr: isNarrow ? 0 : 5, width: isNarrow ? "98%" : "80%" }}
        >
          <Card
            variant='outlined'
            sx={{
              backgroundColor: "transparent",
              borderRadius: "20px",
              borderWidth: "2px",
              borderColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.light,
              alignItems: "center",
              justifyContent: "center",
              p: isNarrow ? 0 : 5,
              pt: isNarrow ? 2 : 5,
              width: "100%",
            }}
          >
            <Stack
              spacing={3}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <Card
                sx={{
                  width: 300,
                  height: 200,
                  display: "flex",
                  backgroundColor: "transparent",
                  borderRadius: "20px",
                  borderStyle: "dashed",
                  borderWidth: "2px",
                  borderColor: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.palette.primary.light,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                variant='outlined'
              >
                {uploadedImageUrl === "" ? (
                  <Typography>{t("admin.business.uploadImage")}</Typography>
                ) : (
                  <img
                    src={uploadedImageUrl}
                    alt='product'
                    style={{ width: 300, height: 200 }}
                  />
                )}
              </Card>
              <UploadImageButton
                handleUpload={handleFileInput}
                isLoading={isUploading}
              />
            </Stack>
            <Divider
              sx={{
                pt: 2,
                borderColor: (theme) => theme.palette.primary.main,
              }}
            />
            <CardContent sx={{ pt: 6, mx: 5 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack
                  spacing={2}
                  justifyContent='center'
                  alignItems='center'
                  sx={{ pt: 5 }}
                >
                  <Stack spacing={3}>
                    <Stack
                      direction='row'
                      spacing={3}
                      alignItems='center'
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Typography
                        variant='h6'
                        color='text.secondary'
                        sx={{ flex: 1, textAlign: "end" }}
                      >
                        {t("shared.naming")}:
                      </Typography>
                      <Controller
                        control={control}
                        name='name'
                        render={({ field, fieldState, formState }) => (
                          <StyledTextField
                            {...field}
                            variant='outlined'
                            size='small'
                            error={!!fieldState.error}
                            helperText={
                              !!fieldState.error
                                ? fieldState.error.message
                                : undefined
                            }
                            inputProps={{
                              sx: {
                                color: (theme) => theme.palette.primary.dark,
                                backgroundColor: "transparent !important",
                                width: isNarrow ? "180px" : "300px",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: (theme) => theme.palette.primary.main,
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={3}
                      alignItems='center'
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Typography
                        variant='h6'
                        color='text.secondary'
                        sx={{ flex: 1, textAlign: "end" }}
                      >
                        {t("shared.description")}:
                      </Typography>
                      <Controller
                        control={control}
                        name='description'
                        render={({ field, fieldState, formState }) => (
                          <StyledTextField
                            {...field}
                            multiline
                            variant='outlined'
                            size='small'
                            error={!!fieldState.error}
                            helperText={
                              !!fieldState.error
                                ? fieldState.error.message
                                : undefined
                            }
                            inputProps={{
                              sx: {
                                color: (theme) => theme.palette.primary.dark,
                                backgroundColor: "transparent !important",
                                width: isNarrow ? "180px" : "300px",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: (theme) => theme.palette.primary.main,
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={3}
                      alignItems='center'
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Typography
                        variant='h6'
                        color='text.secondary'
                        sx={{ flex: 1, textAlign: "end" }}
                      >
                        {t("shared.inStock")}:
                      </Typography>
                      <Controller
                        control={control}
                        name='inStock'
                        render={({ field, fieldState, formState }) => (
                          <StyledTextField
                            {...field}
                            variant='outlined'
                            size='small'
                            type='number'
                            error={!!fieldState.error}
                            helperText={
                              !!fieldState.error
                                ? fieldState.error.message
                                : undefined
                            }
                            inputProps={{
                              sx: {
                                color: (theme) => theme.palette.primary.dark,
                                backgroundColor: "transparent !important",
                                width: isNarrow ? "180px" : "300px",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: (theme) => theme.palette.primary.main,
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      alignItems='center'
                      spacing={4}
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Typography
                        variant='h6'
                        color='text.secondary'
                        sx={{ flex: 1, textAlign: "end" }}
                      >
                        Kategorie:
                      </Typography>
                      <Controller
                        control={control}
                        name='categories'
                        render={() => (
                          <Autocomplete
                            multiple
                            options={options.map(({ locale }) =>
                              t(locale as any)
                            )}
                            filterSelectedOptions
                            sx={{
                              backgroundColor: "transparent !important",
                              width: isNarrow ? "208px" : "328px",
                            }}
                            onChange={(e, data) => setCategories(data)}
                            renderInput={(params) => (
                              <StyledTextField
                                {...params}
                                variant='outlined'
                                size='small'
                                error={categories.length < 1}
                              />
                            )}
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={3}
                      alignItems='center'
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Typography
                        variant='h6'
                        color='text.secondary'
                        sx={{ flex: 1, textAlign: "end" }}
                      >
                        {t("shared.price")}:
                      </Typography>
                      <Controller
                        control={control}
                        name='price'
                        render={({ field, fieldState, formState }) => (
                          <StyledTextField
                            {...field}
                            variant='outlined'
                            size='small'
                            type='number'
                            error={!!fieldState.error}
                            helperText={
                              !!fieldState.error
                                ? fieldState.error.message
                                : undefined
                            }
                            inputProps={{
                              sx: {
                                color: (theme) => theme.palette.primary.dark,
                                backgroundColor: "transparent !important",
                                width: isNarrow ? "180px" : "300px",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: (theme) => theme.palette.primary.main,
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack alignItems='end'>
                      {/* todo - cenu tahat z businessu, kazdy business si odsuhlasi cenu zvlast a bude rovnaka na vsetky produkty */}
                      <Typography variant='subtitle1' color='text.secondary'>
                        + 20%
                      </Typography>
                      <Typography
                        variant='h6'
                        fontWeight={500}
                        color='text.secondary'
                      >
                        {priceWithProvision} {t("shared.currencyCZ")}
                      </Typography>
                    </Stack>
                    <Button
                      type='submit'
                      variant='contained'
                      sx={{ alignSelf: "center" }}
                    >
                      {isCreating && (
                        <CircularProgress
                          size={20}
                          sx={{
                            color: (theme) => theme.palette.secondary.light,
                            mr: 1,
                          }}
                        />
                      )}
                      {t("shared.save")}
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </CardContent>
          </Card>
        </Stack>
      </Stack>
    </Stack>
  );
};
