import imgTop from "../../../shared/components/UI/LandingPageImages/landingPageTop.svg";
import { Stack, Typography } from "@mui/material";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { NARROW_SCREEN } from "../../../shared/helpers/values";
import { useTranslation } from "react-i18next";

export const CategoryHeader = ({
  mainImg,
  categoryName,
  allCategories,
}: {
  mainImg: string;
  categoryName: string;
  allCategories?: boolean;
}) => {
  const { t } = useTranslation("common");
  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  return isNarrow ? (
    <Stack direction='column' spacing={2} sx={{ overflowX: "hidden", mb: 2 }}>
      <Stack justifyContent='space-around' alignItems='center'>
        <img
          src={imgTop}
          alt='top'
          style={{ position: "absolute", top: 0, right: 0 }}
        />
        {allCategories && (
          <Typography
            variant='h2'
            color='text.primary'
            sx={{ fontWeight: 600 }}
          >
            {categoryName}
          </Typography>
        )}
        <Typography variant='h3' color='primary.main' sx={{ fontWeight: 600 }}>
          {t("shared.categories")}
        </Typography>
        {!allCategories && (
          <Typography
            variant='h2'
            color='text.primary'
            sx={{ fontWeight: 600 }}
          >
            {categoryName}
          </Typography>
        )}
        <img src={mainImg} alt='food' style={{ zIndex: 1, width: "23rem" }} />
      </Stack>
    </Stack>
  ) : (
    <Stack
      direction='row'
      spacing={12}
      sx={{ pl: 50, mb: 3 }}
      alignItems='center'
    >
      <Stack spacing={1} sx={{ zIndex: 1 }}>
        {allCategories && (
          <Typography
            variant='h2'
            color='text.primary'
            sx={{ fontWeight: 600 }}
          >
            {categoryName}
          </Typography>
        )}
        <Typography variant='h3' color='primary.main' sx={{ fontWeight: 600 }}>
          {t("shared.categories")}
        </Typography>
        {!allCategories && (
          <Typography
            variant='h2'
            color='text.primary'
            sx={{ fontWeight: 600 }}
          >
            {categoryName}
          </Typography>
        )}
      </Stack>
      <Stack>
        <img
          src={imgTop}
          alt='top'
          style={{ position: "absolute", top: 0, right: 0, zIndex: 0 }}
        />
        <img src={mainImg} alt='food' style={{ zIndex: 1, width: "80%" }} />
      </Stack>
    </Stack>
  );
};
