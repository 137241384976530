import { Button, Stack, Typography } from '@mui/material';

type QuantityInputProps = {
  isLoading: boolean;
  cartItemQuantity: number;
  onLessClick: () => void;
  onMoreClick: () => void;
  moreBtnDisabled?: boolean;
  size?: string;
};

export const QuantityInput = ({
  isLoading,
  cartItemQuantity,
  onLessClick,
  onMoreClick,
  moreBtnDisabled,
  size = '24px',
}: QuantityInputProps) => {
  return (
    <Stack
      direction='row'
      spacing={0}
      sx={{ alignItems: 'center', justifySelf: 'center' }}
    >
      {/* // todo - delete modal */}
      <Button
        variant='contained'
        size='small'
        disabled={isLoading}
        sx={{
          maxWidth: size,
          maxHeight: size,
          minWidth: size,
          minHeight: size,
          mr: 1.5,
        }}
        onClick={() => onLessClick()}
      >
        <Typography variant='h5' sx={{ color: 'secondary.light' }}>
          -
        </Typography>
      </Button>
      {/* todo - all buttons are disabled now + reuse this in product card */}
      <Typography variant='subtitle1' color='primary.dark'>
        {cartItemQuantity}
      </Typography>
      <Button
        variant='contained'
        size='small'
        disabled={isLoading || moreBtnDisabled}
        sx={{
          maxWidth: size,
          maxHeight: size,
          minWidth: size,
          minHeight: size,
          ml: 1.5,
        }}
        onClick={() => onMoreClick()}
      >
        <Typography variant='h5' sx={{ color: 'secondary.light' }}>
          +
        </Typography>
      </Button>
    </Stack>
  );
};
