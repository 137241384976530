import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import {
  LocalDining,
  Pets,
  School,
  Apps,
  AttachMoney,
  ColorLens,
} from "@mui/icons-material";
import {
  mdiLipstick,
  mdiTshirtCrew,
  mdiDiamondStone,
  mdiSofaSingleOutline,
  mdiSilverwareForkKnife,
  mdiSunglasses,
  mdiAccountWrench,
} from "@mdi/js";
import { StyledMdiIcon } from "../../../../shared/components/UI/StyledMdiIcon";
import { useTranslation } from "react-i18next";
import { StyledLink } from "../../../../shared/components/StyledLink/StyledLink";

export const categories = [
  {
    name: "FOOD",
    icon: <LocalDining color='secondary' fontSize='small' />,
    locale: "customer.drawer.categories.food",
  },
  {
    name: "COSMETICS",
    locale: "customer.drawer.categories.cosmetics",
    icon: <StyledMdiIcon path={mdiLipstick} />,
  },
  {
    name: "CLOTHING",
    locale: "customer.drawer.categories.clothing",
    icon: <StyledMdiIcon path={mdiTshirtCrew} />,
  },
  {
    name: "JEWELRY",
    locale: "customer.drawer.categories.jewelry",
    icon: <StyledMdiIcon path={mdiDiamondStone} />,
  },
  {
    name: "DISHES",
    locale: "customer.drawer.categories.dishes",
    icon: <StyledMdiIcon path={mdiSilverwareForkKnife} />,
  },
  {
    name: "ACCESSORIES",
    locale: "customer.drawer.categories.accessories",
    icon: <StyledMdiIcon path={mdiSunglasses} />,
  },
  {
    name: "PETS",
    locale: "customer.drawer.categories.pets",
    icon: <Pets color='secondary' fontSize='small' />,
  },
  {
    name: "FURNITURE",
    locale: "customer.drawer.categories.furniture",
    icon: <StyledMdiIcon path={mdiSofaSingleOutline} />,
  },
  {
    name: "EDUCATION",
    locale: "customer.drawer.categories.education",
    icon: <School color='secondary' fontSize='small' />,
  },
  {
    name: "SERVICES",
    locale: "customer.drawer.categories.services",
    icon: <StyledMdiIcon path={mdiAccountWrench} />,
  },
  {
    name: "ART",
    locale: "customer.drawer.categories.art",
    icon: <ColorLens color='secondary' fontSize='small' />,
  },
];

export const DrawerListCategories = () => {
  const { t } = useTranslation("common");
  return (
    <>
      <Typography variant='h6' color='secondary' sx={{ pl: 2 }}>
        {t("shared.categories")}
      </Typography>
      <List sx={{ width: "100%", maxWidth: 360 }} component='nav'>
        <Stack spacing={2}>
          <StyledLink to='/app'>
            <ListItemButton
              sx={{
                height: 25,
                borderRadius: 2,
                ":hover": {
                  backgroundColor: "#d4d5f235",
                },
              }}
            >
              <ListItemIcon>
                <Apps color='secondary' fontSize='small' />
              </ListItemIcon>
              <ListItemText
                primary={t("shared.all")}
                sx={{ color: (theme) => theme.palette.secondary.main }}
              />
            </ListItemButton>
          </StyledLink>
          {categories.map((category, i) => (
            <StyledLink key={i} to={`/${category.name.toLowerCase()}`}>
              <ListItemButton
                sx={{
                  height: 25,
                  borderRadius: 2,
                  ":hover": {
                    backgroundColor: "#d4d5f235",
                  },
                }}
              >
                <ListItemIcon>{category.icon}</ListItemIcon>
                <ListItemText
                  primary={t(category.locale as any)}
                  sx={{ color: (theme) => theme.palette.secondary.main }}
                />
              </ListItemButton>
            </StyledLink>
          ))}
          <Divider
            sx={{ borderColor: (theme) => theme.palette.primary.light }}
          />
          <StyledLink to='/businesses'>
            <ListItemButton
              sx={{
                height: 25,
                borderRadius: 2,
                ":hover": {
                  backgroundColor: "#d4d5f235",
                },
              }}
            >
              <ListItemIcon>
                <AttachMoney color='secondary' fontSize='small' />
              </ListItemIcon>
              <ListItemText
                primary={t("shared.shops")}
                sx={{ color: (theme) => theme.palette.secondary.main }}
              />
            </ListItemButton>
          </StyledLink>
        </Stack>
      </List>
    </>
  );
};
