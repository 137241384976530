import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { CartStepOverview } from "./CartStepOverview";
import { CartStepDeliveryDetails } from "./CartStepDeliveryDetails";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  useCreateDeliveryDetails,
  useGetDeliveryDetails,
  useUpdateDeliveryDetails,
} from "../../../apiClient/apiClient";
import { StyledStepLabel } from "../UI/StyledStepLabel";
import {
  NARROW_SCREEN,
  invalidPhone,
  requiredField,
} from "../../../shared/helpers/values";
import { CircularProgress } from "@mui/material";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { useTranslation } from "react-i18next";
import { CartStepDeliveryAndPayment } from "./CartStepDeliveryAndPayment";
import { CartStepFinal } from "./CartStepFinal";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const DeliveryDetailsSchema = () => {
  const { t } = useTranslation("common");
  return yup.object({
    firstName: yup.string().required(t(requiredField).toString()),
    lastName: yup.string().required(t(requiredField).toString()),
    phone: yup
      .string()
      .matches(phoneRegExp, t(invalidPhone).toString())
      .required(t(requiredField).toString()),
    streetAndStreetNumber: yup.string().required(t(requiredField).toString()),
    city: yup.string().required(t(requiredField).toString()),
    zipCode: yup.string().required(t(requiredField).toString()),
  });
};

const steps = [
  "customer.cart.cart",
  "customer.cart.deliveryDetails",
  "customer.cart.deliveryAndPayment",
  "customer.cart.orderSummary",
];

export const CartSteps = () => {
  const { t } = useTranslation("common");
  const [activeStep, setActiveStep] = useState(0);

  const { mutate: addDeliveryDetail, isLoading: isCreatingDetails } =
    useCreateDeliveryDetails();

  const { data: deliveryDetailsData, isLoading: isLoadingDetails } =
    useGetDeliveryDetails();

  const { mutate: updateDetail, isLoading: isUpdating } =
    useUpdateDeliveryDetails();

  const isLoading = isCreatingDetails || isLoadingDetails || isUpdating;

  const deliveryDetails = deliveryDetailsData?.data;

  const { control, handleSubmit, watch, setValue } = useForm({
    mode: "onBlur",
    resolver: yupResolver(DeliveryDetailsSchema()),
    defaultValues: {
      firstName: deliveryDetails?.firstName ?? "",
      lastName: deliveryDetails?.lastName ?? "",
      phone: deliveryDetails?.phone ?? "",
      streetAndStreetNumber: deliveryDetails?.streetAndStreetNumber ?? "",
      city: deliveryDetails?.city ?? "",
      zipCode: deliveryDetails?.zipCode ?? "",
    },
  });

  useEffect(() => {
    if (deliveryDetails) {
      setValue("firstName", deliveryDetails.firstName || "");
      setValue("lastName", deliveryDetails.lastName || "");
      setValue("phone", deliveryDetails.phone || "");
      setValue(
        "streetAndStreetNumber",
        deliveryDetails.streetAndStreetNumber || ""
      );
      setValue("city", deliveryDetails.city || "");
      setValue("zipCode", deliveryDetails.zipCode || "");
    }
  }, [deliveryDetails, setValue]);
  const watchValues = watch();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmit = () => {
    if (deliveryDetails?.firstName) {
      updateDetail(
        {
          firstName: watchValues.firstName ?? deliveryDetails?.firstName,
          lastName: watchValues.lastName ?? deliveryDetails?.lastName,
          phone: watchValues.phone ?? deliveryDetails?.phone,
          streetAndStreetNumber:
            watchValues.streetAndStreetNumber ??
            deliveryDetails?.streetAndStreetNumber,
          city: watchValues.city ?? deliveryDetails?.city,
          zipCode: watchValues.zipCode ?? deliveryDetails?.zipCode,
        },
        { onSuccess: () => handleNext() }
      );
    } else {
      addDeliveryDetail(
        {
          firstName: watchValues.firstName ?? "",
          lastName: watchValues.lastName ?? "",
          phone: watchValues.phone ?? "",
          streetAndStreetNumber: watchValues.streetAndStreetNumber ?? "",
          city: watchValues.city ?? "",
          zipCode: watchValues.zipCode ?? "",
        },
        { onSuccess: () => handleNext() }
      );
    }
  };

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  return (
    <Box sx={{ ml: isNarrow ? 1 : 40, mr: isNarrow ? 1 : 8, mt: 15 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={index} {...stepProps}>
              <StyledStepLabel {...labelProps}>
                {t(label as any)}
              </StyledStepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === 0 && <CartStepOverview />}
      <form id='delivery-details-form'>
        {activeStep === 1 && (
          <CartStepDeliveryDetails
            control={control}
            deliveryDetails={deliveryDetails}
          />
        )}
      </form>
      {activeStep === 2 && (
        <CartStepDeliveryAndPayment handleNext={handleNext} />
      )}
      {activeStep === 3 && <CartStepFinal />}
      {activeStep === steps.length && (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            {t("customer.cart.completedMessage")}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
          </Box>
        </>
      )}
      <>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 3, mb: 6 }}>
          <Button
            color='primary'
            disabled={activeStep === 0 || isLoading}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            {t("shared.back")}
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          {activeStep === 0 && (
            <Button
              onClick={handleNext}
              variant='contained'
              form='delivery-details-form'
              disabled={isLoading}
              sx={{ borderRadius: "50px", color: "secondary.light" }}
            >
              {isLoading && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: (theme) => theme.palette.secondary.light,
                    mr: 1,
                  }}
                />
              )}
              {t("shared.proceed")}
            </Button>
          )}
          {activeStep === 1 && (
            <Button
              onClick={handleSubmit(onSubmit)}
              variant='contained'
              form='delivery-details-form'
              disabled={isLoading}
              sx={{ borderRadius: "50px", color: "secondary.light" }}
            >
              {isLoading && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: (theme) => theme.palette.secondary.light,
                    mr: 1,
                  }}
                />
              )}
              {t("shared.proceed")}
            </Button>
          )}
          {/* {activeStep === steps.length - 1 && (
            <Link to='/payment' style={{ textDecoration: "none" }}>
              <Button
                variant='contained'
                disabled={isLoading}
                sx={{ borderRadius: "50px", color: "secondary.light" }}
              >
                {isLoading && (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: (theme) => theme.palette.secondary.light,
                      mr: 1,
                    }}
                  />
                )}
                {t("shared.buy")}
              </Button>
            </Link>
          )} */}
        </Box>
      </>
    </Box>
  );
};
