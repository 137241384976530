import React from "react";
import { CardContent, CardMedia, Stack, Typography } from "@mui/material";
import "./Businesses.css";
import { StyledCard } from "../../../shared/components/UI/StyledCard";
import { StyledCardActionArea } from "../../../shared/components/UI/StyledCardActionArea";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";

type Props = {
  id: string;
  name: string;
  description: string;
  images?: string[];
};

export const Business = ({ id, name, description, images }: Props) => {
  return (
    <StyledCard sx={{ zIndex: 1 }}>
      <StyledLink to={`/business/${id}`}>
        <StyledCardActionArea disableRipple sx={{ p: 2 }}>
          <CardMedia
            component='img'
            image={images?.[0]}
            alt='alt'
            height={180}
            sx={{ borderRadius: 2 }}
          />
          <CardContent>
            <Stack spacing={1}>
              <Typography variant='h6' color='primary.dark'>
                {name}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {description}
              </Typography>
            </Stack>
          </CardContent>
        </StyledCardActionArea>
      </StyledLink>
    </StyledCard>
  );
};
