import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import jwt_decode from "jwt-decode";
import { DecodeType } from "../../types/types";

const AuthContext = createContext<{
  user: DecodeType | null;
  setUser: React.Dispatch<React.SetStateAction<DecodeType | null>>;
  isAuthInit: boolean;
  setIsAuthInit: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<DecodeType | null>(null);
  const [isAuthInit, setIsAuthInit] = useState<boolean>(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decode: DecodeType = jwt_decode(token);
      setUser(decode);
    }
    setIsAuthInit(true);
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, isAuthInit, setIsAuthInit }}>
      {children}
    </AuthContext.Provider>
  );
};
