import { ReactNode } from "react";
import { Link } from "react-router-dom";

type StyledLinkProps = { to: string; children: ReactNode };

export const StyledLink = ({ to, children }: StyledLinkProps) => (
  <Link to={to} style={{ textDecoration: "none" }}>
    {children}
  </Link>
);
