import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LandingPageSection } from "./LandingPageSection";
import landingImg from "./LandingPageImages/landingPage.svg";
import landingTopImg from "./LandingPageImages/landingPageTop.svg";
import lpRocks from "./LandingPageImages/lpRocks.svg";
import lpBuy from "./LandingPageImages/lpBuy.svg";
import lpFind from "./LandingPageImages/lpFind.svg";
import lpCommunity from "./LandingPageImages/lpCommunity.svg";
import lpSafe from "./LandingPageImages/lpSafe.svg";
import lpHorizont from "./LandingPageImages/lpHorizont.svg";
import lpTrust from "./LandingPageImages/lpTrust.svg";
import lpStart from "./LandingPageImages/lpStart.svg";
import lpTransparent from "./LandingPageImages/lpTransparent.svg";
import lpMountains from "./LandingPageImages/lpMountains.svg";
import { StyledLink } from "../StyledLink/StyledLink";

export const buySections = [
  {
    src: lpBuy,
    title: "shared.landing.lpBuy",
    desc: "shared.landing.lpBuyDesc",
  },
  {
    src: lpFind,
    title: "shared.landing.lpFind",
    desc: "shared.landing.lpFindDesc",
  },
  {
    src: lpCommunity,
    title: "shared.landing.lpCommunity",
    desc: "shared.landing.lpCommunityDesc",
  },
  {
    src: lpSafe,
    title: "shared.landing.lpSafe",
    desc: "shared.landing.lpSafeDesc",
  },
];

export const sellSections = [
  {
    src: lpHorizont,
    title: "shared.landing.lpHorizont",
    desc: "shared.landing.lpHorizontDesc",
  },
  {
    src: lpTrust,
    title: "shared.landing.lpTrust",
    desc: "shared.landing.lpTrustDesc",
  },
  {
    src: lpStart,
    title: "shared.landing.lpStart",
    desc: "shared.landing.lpStartDesc",
  },
  {
    src: lpTransparent,
    title: "shared.landing.lpTransparent",
    desc: "shared.landing.lpTransparentDesc",
  },
];

export const LandingPageMobile = () => {
  const { t } = useTranslation("common");

  return (
    <Stack spacing={0} sx={{ mb: 10 }}>
      <Stack direction='column' spacing={1} sx={{ overflow: "hidden" }}>
        <Stack>
          <img
            src={landingImg}
            alt='landing'
            style={{ zIndex: 1, paddingTop: 70 }}
          />
          <img
            src={landingTopImg}
            alt='landingTop'
            style={{ position: "absolute", right: 0, top: 0 }}
          />
        </Stack>
        <Stack spacing={4} sx={{ mb: 5, px: 2 }}>
          <Typography
            variant='h4'
            color='primary.dark'
            sx={{ fontWeight: 600, textAlign: "center" }}
          >
            {t("shared.landing.title")}
          </Typography>
          <Typography
            variant='h4'
            color='text.primary'
            sx={{ fontWeight: 550, textAlign: "center" }}
          >
            {t("shared.landing.subtitle")}
          </Typography>
          <Typography
            variant='subtitle1'
            color='text.primary'
            sx={{ textAlign: "center" }}
          >
            {t("shared.landing.description")}
          </Typography>
          <Stack
            position='fixed'
            spacing={1}
            sx={{
              bottom: 0,
              left: 0,
              width: "100%",
              pt: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#faf8f5e4",
              zIndex: 2,
            }}
          >
            <Stack direction='row' spacing={2} sx={{ my: 1 }}>
              <StyledLink to='/app'>
                <Button
                  variant='contained'
                  size='medium'
                  sx={{
                    borderRadius: "50px",
                    color: "secondary.light",
                    mb: 2,
                    width: "11rem",
                  }}
                >
                  {t("shared.landing.buy")}
                </Button>
              </StyledLink>
              <StyledLink to='/registration/admin'>
                <Button
                  variant='outlined'
                  size='medium'
                  sx={{
                    borderRadius: "50px",
                    mb: 2,
                    width: "11rem",
                    backgroundColor: "secondary.dark",
                  }}
                >
                  {t("shared.landing.sell")}
                </Button>
              </StyledLink>
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          <img src={lpRocks} alt='lpRocks' style={{ zIndex: 1, margin: 0 }} />
        </Stack>
        <Stack spacing={4} sx={{ px: 2 }}>
          <Typography
            variant='h5'
            color='primary.dark'
            sx={{ fontWeight: 550, textAlign: "center" }}
          >
            {t("shared.landing.welcome")}
          </Typography>
          <Typography
            variant='h5'
            color='text.primary'
            sx={{ fontWeight: 550, textAlign: "center" }}
          >
            {t("shared.landing.explore")}
          </Typography>
          <Typography
            variant='subtitle1'
            color='primary.dark'
            sx={{ textAlign: "center" }}
          >
            {t("shared.landing.description2")}
          </Typography>
          <Typography
            variant='subtitle1'
            color='text.primary'
            sx={{ textAlign: "center" }}
          >
            {t("shared.landing.description3")}
          </Typography>
          <Typography
            variant='h5'
            color='primary.dark'
            sx={{ fontWeight: 550, textAlign: "center" }}
          >
            {t("shared.landing.titleBuy")}
          </Typography>
          <Typography
            variant='h5'
            color='text.primary'
            sx={{ fontWeight: 550, textAlign: "center" }}
          >
            {t("shared.landing.titleInfo")}
          </Typography>
        </Stack>
        {buySections.map(({ src, title, desc }) => (
          <LandingPageSection key={title} src={src} title={title} desc={desc} />
        ))}
        <Stack spacing={1} sx={{ px: 2 }}>
          <Typography
            variant='h5'
            color='primary.dark'
            sx={{ fontWeight: 550, textAlign: "center" }}
          >
            {t("shared.landing.wantSellTitle")}
          </Typography>
          <Typography
            variant='h5'
            color='text.primary'
            sx={{ fontWeight: 550, textAlign: "center" }}
          >
            {t("shared.landing.wantSellTitle2")}
          </Typography>
        </Stack>
        {sellSections.map(({ src, title, desc }) => (
          <LandingPageSection src={src} title={title} desc={desc} />
        ))}
        <Typography
          variant='h5'
          color='primary.dark'
          sx={{ fontWeight: 550, textAlign: "center", px: 2 }}
        >
          {t("shared.landing.registrateNow")}
        </Typography>
      </Stack>
      <img
        src={lpMountains}
        alt={lpMountains}
        style={{ width: "100%", marginBottom: "-2rem" }}
      />
    </Stack>
  );
};
