import { Skeleton } from '@mui/material';

type ProductsSkeletonProps = {
  n: number;
  isMasonry: boolean;
};

export const ProductsSkeleton = ({ n, isMasonry }: ProductsSkeletonProps) => {
  return (
    <>
      {[...Array(n)].map((e, i) => (
        <Skeleton
          key={i}
          variant='rounded'
          width={300}
          height={isMasonry ? +`3${Math.floor(Math.random() * 10)}0` : 350}
          sx={{ mb: 3 }}
        />
      ))}
    </>
  );
};
