import { Box, CardContent, Stack, Typography } from "@mui/material";
import { StyledCard } from "../../../shared/components/UI/StyledCard";
import { useTranslation } from "react-i18next";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";
import addLogo from "../UI/add.png";

export const AddBusinessCard = () => {
  const { t } = useTranslation("common");
  return (
    <StyledLink to='/admin/business/add'>
      <StyledCard
        sx={{
          width: 300,
          minHeight: 438,
        }}
      >
        <CardContent>
          <Stack alignItems='center' justifyContent='center' spacing={6}>
            <Box
              sx={{
                mt: 3,
                p: 3,
                borderStyle: "dashed",
                borderColor: (theme) => theme.palette.primary.light,
                borderWidth: "2px",
                borderRadius: 4,
              }}
            >
              <img src={addLogo} alt='add-business p' style={{ width: 180 }} />
            </Box>
            <Typography variant='h4' color='primary.dark'>
              {t("admin.business.addBusiness")}
            </Typography>
          </Stack>
        </CardContent>
      </StyledCard>
    </StyledLink>
  );
};
