import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type LandingPageSectionProps = {
  src: string;
  title: string;
  desc: string;
};

export const LandingPageSection = ({
  src,
  title,
  desc,
}: LandingPageSectionProps) => {
  const { t } = useTranslation("common");

  return (
    <Stack spacing={1} sx={{ pb: 10, px: 2 }}>
      <img src={src} alt={src} />
      <Typography
        variant='h5'
        color='primary.dark'
        sx={{ fontWeight: 550, textAlign: "center" }}
      >
        {t(title as any)}
      </Typography>
      <Typography
        variant='subtitle1'
        color='text.primary'
        sx={{ textAlign: "center" }}
      >
        {t(desc as any)}
      </Typography>
    </Stack>
  );
};
