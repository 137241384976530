import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { StyledTextField } from "../../../shared/components/UI/StyledTextField";
import {
  useCreateDeliveryDetails,
  useGetDeliveryDetails,
  useUpdateDeliveryDetails,
} from "../../../apiClient/apiClient";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  NARROW_SCREEN,
  dataSaved,
  invalidPhone,
  requiredField,
  somethingWentWrong,
} from "../../../shared/helpers/values";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { useEffect, useState } from "react";
import { SnackbarMsg } from "../../../shared/components/UI/SnackbarMsg";
import { useTranslation } from "react-i18next";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const DeliveryDetailsSchema = () => {
  const { t } = useTranslation("common");
  return yup.object({
    firstName: yup.string().required(t(requiredField).toString()),
    lastName: yup.string().required(t(requiredField).toString()),
    phone: yup
      .string()
      .matches(phoneRegExp, t(invalidPhone).toString())
      .required(t(requiredField).toString()),
    streetAndStreetNumber: yup.string().required(t(requiredField).toString()),
    city: yup.string().required(t(requiredField).toString()),
    zipCode: yup.string().required(t(requiredField).toString()),
  });
};

export const SettingsDeliveryDetailsForm = () => {
  const { t } = useTranslation("common");
  const [showMsg, setShowMsg] = useState<{
    successMsg: boolean;
    errorMsg: boolean;
  }>({ successMsg: false, errorMsg: false });

  const { data: deliveryDetailsData, isLoading: isLoadingDetails } =
    useGetDeliveryDetails();

  const { mutate: addDeliveryDetail, isLoading: isCreatingDetails } =
    useCreateDeliveryDetails();

  const deliveryDetails = deliveryDetailsData?.data;

  const { control, handleSubmit, watch, setValue } = useForm({
    mode: "onBlur",
    resolver: yupResolver(DeliveryDetailsSchema()),
    defaultValues: {
      firstName: deliveryDetails?.firstName ?? "",
      lastName: deliveryDetails?.lastName ?? "",
      phone: deliveryDetails?.phone ?? "",
      streetAndStreetNumber: deliveryDetails?.streetAndStreetNumber ?? "",
      city: deliveryDetails?.city ?? "",
      zipCode: deliveryDetails?.zipCode ?? "",
    },
  });

  useEffect(() => {
    if (deliveryDetails) {
      setValue("firstName", deliveryDetails.firstName || "");
      setValue("lastName", deliveryDetails.lastName || "");
      setValue("phone", deliveryDetails.phone || "");
      setValue(
        "streetAndStreetNumber",
        deliveryDetails.streetAndStreetNumber || ""
      );
      setValue("city", deliveryDetails.city || "");
      setValue("zipCode", deliveryDetails.zipCode || "");
    }
  }, [deliveryDetails, setValue]);

  const { mutate: updateDetail, isLoading: isUpdating } =
    useUpdateDeliveryDetails();

  const isLoading = isUpdating || isCreatingDetails || isLoadingDetails;

  const watchValues = watch();

  const onSubmit = () => {
    if (deliveryDetails?.firstName) {
      updateDetail(
        {
          firstName: watchValues.firstName ?? deliveryDetails?.firstName,
          lastName: watchValues.lastName ?? deliveryDetails?.lastName,
          phone: watchValues.phone ?? deliveryDetails?.phone,
          streetAndStreetNumber:
            watchValues.streetAndStreetNumber ??
            deliveryDetails?.streetAndStreetNumber,
          city: watchValues.city ?? deliveryDetails?.city,
          zipCode: watchValues.zipCode ?? deliveryDetails?.zipCode,
        },
        {
          onSuccess: () => setShowMsg({ successMsg: true, errorMsg: false }),
          onError: () => setShowMsg({ successMsg: false, errorMsg: true }),
        }
      );
    } else {
      addDeliveryDetail(
        {
          firstName: watchValues.firstName ?? "",
          lastName: watchValues.lastName ?? "",
          phone: watchValues.phone ?? "",
          streetAndStreetNumber: watchValues.streetAndStreetNumber ?? "",
          city: watchValues.city ?? "",
          zipCode: watchValues.zipCode ?? "",
        },
        {
          onSuccess: () => setShowMsg({ successMsg: true, errorMsg: false }),
          onError: () => setShowMsg({ successMsg: false, errorMsg: true }),
        }
      );
    }
  };

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Stack
            direction='row'
            spacing={3}
            alignItems='center'
            sx={{ justifyContent: "space-between" }}
          >
            <Typography
              variant='h6'
              color='text.secondary'
              sx={{ flex: 1, textAlign: "end" }}
            >
              {t("shared.name")}:
            </Typography>
            <Controller
              control={control}
              name='firstName'
              render={({ field, fieldState, formState }) => (
                <StyledTextField
                  {...field}
                  variant='outlined'
                  size='small'
                  error={!!fieldState.error}
                  helperText={
                    !!fieldState.error ? fieldState.error.message : undefined
                  }
                  inputProps={{
                    sx: {
                      color: (theme) => theme.palette.text.primary,
                      width: isNarrow ? "200px" : "300px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: (theme) => theme.palette.primary.main,
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Stack
            direction='row'
            spacing={3}
            alignItems='center'
            sx={{ justifyContent: "space-between" }}
          >
            <Typography
              variant='h6'
              color='text.secondary'
              sx={{ flex: 1, textAlign: "end" }}
            >
              {t("shared.surname")}:
            </Typography>
            <Controller
              control={control}
              name='lastName'
              render={({ field, fieldState, formState }) => (
                <StyledTextField
                  {...field}
                  variant='outlined'
                  size='small'
                  error={!!fieldState.error}
                  helperText={
                    !!fieldState.error ? fieldState.error.message : undefined
                  }
                  inputProps={{
                    sx: {
                      color: (theme) => theme.palette.text.primary,
                      width: isNarrow ? "200px" : "300px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: (theme) => theme.palette.primary.main,
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Stack
            direction='row'
            spacing={3}
            alignItems='center'
            sx={{ justifyContent: "space-between" }}
          >
            <Typography
              variant='h6'
              color='text.secondary'
              sx={{ flex: 1, textAlign: "end" }}
            >
              {t("shared.phoneNumber")}:
            </Typography>
            <Controller
              control={control}
              name='phone'
              render={({ field, fieldState, formState }) => (
                <StyledTextField
                  {...field}
                  variant='outlined'
                  size='small'
                  error={!!fieldState.error}
                  helperText={
                    !!fieldState.error ? fieldState.error.message : undefined
                  }
                  inputProps={{
                    sx: {
                      color: (theme) => theme.palette.text.primary,
                      width: isNarrow ? "200px" : "300px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: (theme) => theme.palette.primary.main,
                    },
                  }}
                />
              )}
            />
          </Stack>
          {/* todo - implement autocomplete
https://mui.com/material-ui/react-autocomplete/#google-maps-place
https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service */}
          <Stack
            direction='row'
            spacing={3}
            alignItems='center'
            sx={{ justifyContent: "space-between" }}
          >
            <Typography
              variant='h6'
              color='text.secondary'
              sx={{ flex: 1, textAlign: "end" }}
            >
              {t("shared.street")}:
            </Typography>
            <Controller
              control={control}
              name='streetAndStreetNumber'
              render={({ field, fieldState, formState }) => (
                <StyledTextField
                  {...field}
                  variant='outlined'
                  size='small'
                  error={!!fieldState.error}
                  helperText={
                    !!fieldState.error ? fieldState.error.message : undefined
                  }
                  inputProps={{
                    sx: {
                      color: (theme) => theme.palette.text.primary,
                      width: isNarrow ? "200px" : "300px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: (theme) => theme.palette.primary.main,
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Stack
            direction='row'
            spacing={3}
            alignItems='center'
            sx={{ justifyContent: "space-between" }}
          >
            <Typography
              variant='h6'
              color='text.secondary'
              sx={{ flex: 1, textAlign: "end" }}
            >
              {t("shared.city")}:
            </Typography>
            <Controller
              control={control}
              name='city'
              render={({ field, fieldState, formState }) => (
                <StyledTextField
                  {...field}
                  variant='outlined'
                  size='small'
                  error={!!fieldState.error}
                  helperText={
                    !!fieldState.error ? fieldState.error.message : undefined
                  }
                  inputProps={{
                    sx: {
                      color: (theme) => theme.palette.text.primary,
                      width: isNarrow ? "200px" : "300px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: (theme) => theme.palette.primary.main,
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Stack
            direction='row'
            spacing={3}
            alignItems='center'
            sx={{ justifyContent: "space-between" }}
          >
            <Typography
              variant='h6'
              color='text.secondary'
              sx={{ flex: 1, textAlign: "end" }}
            >
              {t("shared.postalCode")}:
            </Typography>
            <Controller
              control={control}
              name='zipCode'
              render={({ field, fieldState, formState }) => (
                <StyledTextField
                  {...field}
                  variant='outlined'
                  size='small'
                  error={!!fieldState.error}
                  helperText={
                    !!fieldState.error ? fieldState.error.message : undefined
                  }
                  inputProps={{
                    sx: {
                      color: (theme) => theme.palette.text.primary,
                      width: isNarrow ? "200px" : "300px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: (theme) => theme.palette.primary.main,
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Button
            type='submit'
            variant='contained'
            sx={{ alignSelf: "center" }}
          >
            {isLoading && (
              <CircularProgress
                size={20}
                sx={{
                  color: (theme) => theme.palette.secondary.light,
                  mr: 1,
                }}
              />
            )}
            {t("shared.save")}
          </Button>
        </Stack>
      </form>
      <SnackbarMsg
        open={showMsg.successMsg}
        handleClose={() => setShowMsg({ successMsg: false, errorMsg: false })}
        severity='success'
        message={t(dataSaved)}
      />
      <SnackbarMsg
        open={showMsg.errorMsg}
        handleClose={() => setShowMsg({ successMsg: false, errorMsg: false })}
        severity='error'
        message={t(somethingWentWrong)}
      />
    </>
  );
};
