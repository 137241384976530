import { InfoOutlined } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteDialog } from "../UI/DeleteDialog";
import { useDeleteUser } from "../../../apiClient/apiClient";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../customer/context/AuthContext";

type SettingsDeleteAccountProps = {
  isBuyer?: boolean;
};

export const SettingsDeleteAccount = ({
  isBuyer,
}: SettingsDeleteAccountProps) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const user = useAuth();

  const { mutate: deleteUser, isLoading: isDeleting } = useDeleteUser();

  const handleDeleteAccount = () => {
    deleteUser(
      { userId: user?.user?.id ?? "" },
      {
        onSuccess: () => {
          localStorage.removeItem("token");
          user?.setUser(null);
          navigate("/login");
        },
      }
    );
  };

  return (
    <Card
      variant='outlined'
      sx={{
        backgroundColor: (theme) => theme.palette.secondary.light,
        borderRadius: "50px",
        borderWidth: "2px",
        borderColor: (theme) => theme.palette.primary.main,
        width: "100%",
        color: (theme) => theme.palette.primary.light,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CardContent
        sx={{
          my: 6,
          mx: 5,
        }}
      >
        <Stack spacing={3} alignItems='center' justifyContent='center'>
          <Stack direction='row' spacing={1}>
            <InfoOutlined
              sx={{ color: (theme) => theme.palette.text.secondary }}
            />
            <Typography color='text.secondary'>
              {t("shared.deleteAccountText")}
            </Typography>
          </Stack>
          <Button variant='contained' onClick={() => setOpenDialog(true)}>
            {t("shared.deleteAccount")}
          </Button>
        </Stack>
      </CardContent>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: {
            width: "430px",
            position: "fixed",
            backgroundColor: (theme) => theme.palette.secondary.light,
          },
          elevation: 2,
        }}
      >
        <DeleteDialog
          isLoading={isDeleting}
          onClose={() => setOpenDialog(false)}
          textTitle={t("shared.deleteAccount")}
          textBody={t("shared.deleteAccountDialog")}
          onDelete={handleDeleteAccount}
        />
      </Dialog>
    </Card>
  );
};
