import { ReactNode, createContext, useContext, useState } from "react";
import { PaymentMethod } from "../../types/types";

type DeliveryDetails = {
  businessId: string;
  value: string;
};

export type FormDataDeliveryContext = {
  deliveryDetails: DeliveryDetails[];
  paymentMethod: PaymentMethod;
};

export type DeliveryDetailsContextType = {
  formData: FormDataDeliveryContext | null;
  setFormValues: (data: FormDataDeliveryContext) => void;
};

const DeliveryDetailsContext = createContext<DeliveryDetailsContextType>(
  {} as DeliveryDetailsContextType
);

export const DeliveryDetailsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [formData, setFormData] = useState<FormDataDeliveryContext | null>(
    null
  );

  const setFormValues = (data: FormDataDeliveryContext) => {
    setFormData(data);
  };

  return (
    <DeliveryDetailsContext.Provider value={{ formData, setFormValues }}>
      {children}
    </DeliveryDetailsContext.Provider>
  );
};

export const useDeliveryDetailsContext = () =>
  useContext(DeliveryDetailsContext);
