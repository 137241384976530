import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetAdminBusiness,
  useUpdateAdminBusiness,
} from "../../../apiClient/apiClient";
import { UploadImage } from "./UploadImage";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowBack, Delete, InfoOutlined } from "@mui/icons-material";
import { StyledTextField } from "../../../shared/components/UI/StyledTextField";
import { Header } from "../../../customer/components/TopBar/Header";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { NARROW_SCREEN } from "../../../shared/helpers/values";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";

const UpdateBusinessSchema = () =>
  yup.object({
    name: yup.string().optional(),
    description: yup.string().optional(),
    terms: yup.string().optional(),
    gdpr: yup.string().optional(),
  });

export const EditBusiness = () => {
  const { t } = useTranslation("common");
  const { id } = useParams<{ id: string }>();

  const {
    data: getBusiness,
    refetch,
    isLoading: loadingBusiness,
  } = useGetAdminBusiness({
    businessId: String(id),
  });

  const business = getBusiness?.data;

  const { control, handleSubmit, watch } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(UpdateBusinessSchema()),
    defaultValues: {
      name: business?.name,
      description: business?.description,
      terms: business?.terms,
      gdpr: business?.gdpr,
    },
  });

  const watchValues = watch();

  const { mutate: updateBusiness, isLoading: isUpdating } =
    useUpdateAdminBusiness();

  const navigate = useNavigate();

  const onSubmit = () => {
    updateBusiness(
      {
        id: String(id),
        name: watchValues.name ?? business?.name,
        description: watchValues.description ?? business?.description,
        terms: watchValues.terms ?? business?.terms,
        gdpr: watchValues.gdpr ?? business?.gdpr,
      },
      {
        onSuccess: () => {
          navigate(`/admin/business/${id}`);
        },
        onError: () => console.log("error"),
      }
    );
  };

  const handleDelete = ({ img }: { img: string }) => {
    const filteredImages = business?.images.filter((image) => image !== img);
    updateBusiness(
      {
        id: String(id),
        images: filteredImages,
      },
      { onSuccess: () => refetch() }
    );
  };

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  return (
    <Stack>
      <Header />
      <Stack alignItems='center' justifyContent='center' sx={{ mt: 10, mb: 1 }}>
        <Stack
          spacing={3}
          alignItems='center'
          justifyContent='center'
          sx={{ width: isNarrow ? "95%" : "60%", textAlign: "center" }}
        >
          <Typography
            variant='h4'
            color='text.primary'
            sx={{ fontWeight: 500 }}
          >
            Upravit obchod
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction={isNarrow ? "column" : "row"}
        spacing={isNarrow ? 1 : 5}
        justifyContent='center'
        alignItems={isNarrow ? "center" : "start"}
        sx={{ my: 2, mr: isNarrow ? 0 : 12, mb: 5 }}
      >
        <StyledLink to={`/admin/business/${id}`}>
          <IconButton>
            <ArrowBack fontSize='large' color='primary' />
          </IconButton>
        </StyledLink>
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{ m: 3, mr: isNarrow ? 0 : 3, width: isNarrow ? "98%" : "80%" }}
        >
          <Card
            variant='outlined'
            sx={{
              backgroundColor: "transparent",
              borderRadius: "20px",
              borderWidth: "2px",
              borderColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.light,
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              p: isNarrow ? 0 : 5,
            }}
          >
            <Stack
              spacing={3}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4 }}>
                {/* todo - dnd images */}
                {loadingBusiness ? (
                  <>
                    <Grid item>
                      <Skeleton
                        variant='rounded'
                        width={300}
                        height={200}
                        sx={{ borderRadius: "50px" }}
                      />
                    </Grid>
                    <Grid item>
                      <Skeleton
                        variant='rounded'
                        width={300}
                        height={200}
                        sx={{ borderRadius: "50px" }}
                      />
                    </Grid>
                  </>
                ) : (
                  business?.images.map((img, index) => (
                    <Grid
                      item
                      key={`${img}${index}`}
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 1,
                        ml: 1,
                      }}
                    >
                      <Card
                        sx={{
                          width: 300,
                          height: 200,
                          borderRadius: "50px",
                          borderWidth: "2px",
                          borderColor: (theme) => theme.palette.primary.main,
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          alt='business-img'
                          src={img}
                          style={{ width: 300, height: 200 }}
                        />
                        <IconButton
                          size='medium'
                          sx={{
                            position: "absolute",
                            top: "6%",
                            right: "5%",
                            backgroundColor: (theme) =>
                              theme.palette.secondary.dark,
                            ":hover": {
                              backgroundColor: (theme) =>
                                theme.palette.secondary.light,
                            },
                          }}
                          onClick={() => handleDelete({ img })}
                        >
                          {isUpdating ? (
                            <CircularProgress size={20} />
                          ) : (
                            <Delete fontSize='medium' />
                          )}
                        </IconButton>
                      </Card>
                    </Grid>
                  ))
                )}
              </Grid>
              <UploadImage />
            </Stack>
            <Divider
              sx={{
                pt: 2,
                borderColor: (theme) => theme.palette.primary.main,
              }}
            />
            <CardContent
              sx={{
                pt: 6,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={5}>
                  <Stack
                    direction='row'
                    alignItems='center'
                    spacing={4}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      variant='h6'
                      color='primary.dark'
                      sx={{ flex: 1, textAlign: "end" }}
                    >
                      {t("shared.naming")}:
                    </Typography>
                    <Controller
                      control={control}
                      name='name'
                      render={({ field, fieldState, formState }) => (
                        <StyledTextField
                          {...field}
                          placeholder={
                            field.value === undefined
                              ? business?.name
                              : field.value
                          }
                          variant='outlined'
                          size='small'
                          error={!!fieldState.error}
                          helperText={
                            !!fieldState.error
                              ? fieldState.error.message
                              : undefined
                          }
                          inputProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.dark,
                              backgroundColor: "transparent !important",
                              width: isNarrow ? "180px" : "300px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack
                    direction='row'
                    alignItems='start'
                    spacing={4}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      variant='h6'
                      color='primary.dark'
                      sx={{ flex: 1, textAlign: "end" }}
                    >
                      {t("shared.description")}:
                    </Typography>
                    <Controller
                      control={control}
                      name='description'
                      render={({ field, fieldState, formState }) => (
                        <StyledTextField
                          {...field}
                          multiline
                          placeholder={
                            field.value === undefined
                              ? business?.description
                              : field.value
                          }
                          variant='outlined'
                          size='small'
                          error={!!fieldState.error}
                          helperText={
                            !!fieldState.error
                              ? fieldState.error.message
                              : undefined
                          }
                          inputProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.dark,
                              backgroundColor: "transparent !important",
                              width: isNarrow ? "180px" : "300px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>

                  <Stack
                    direction='row'
                    alignItems='center'
                    spacing={4}
                    sx={{ justifyContent: "end" }}
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Tooltip
                        title={t("admin.business.termsTooltip")}
                        placement='top'
                        arrow
                      >
                        <InfoOutlined color='primary' />
                      </Tooltip>
                      <Typography
                        variant='h6'
                        color='primary.dark'
                        sx={{ flex: 1, textAlign: "end" }}
                      >
                        {t("admin.business.businessTerms")}:
                      </Typography>
                    </Stack>
                    <Controller
                      control={control}
                      name='terms'
                      render={({ field, fieldState, formState }) => (
                        <StyledTextField
                          {...field}
                          variant='outlined'
                          size='small'
                          placeholder={
                            field.value === undefined
                              ? business?.terms
                              : field.value
                          }
                          error={!!fieldState.error}
                          helperText={
                            !!fieldState.error
                              ? fieldState.error.message
                              : undefined
                          }
                          inputProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.dark,
                              backgroundColor: "transparent !important",
                              width: isNarrow ? "180px" : "300px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>

                  <Stack
                    direction='row'
                    alignItems='center'
                    spacing={4}
                    sx={{ pb: 2, justifyContent: "end" }}
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Tooltip
                        title={t("admin.business.gdprTooltip")}
                        placement='top'
                        arrow
                      >
                        <InfoOutlined color='primary' />
                      </Tooltip>
                      <Typography
                        variant='h6'
                        color='primary.dark'
                        sx={{ flex: 1, textAlign: "end" }}
                      >
                        {t("admin.business.businessGDPR")}:
                      </Typography>
                    </Stack>
                    <Controller
                      control={control}
                      name='gdpr'
                      render={({ field, fieldState, formState }) => (
                        <StyledTextField
                          {...field}
                          variant='outlined'
                          size='small'
                          placeholder={
                            field.value === undefined
                              ? business?.gdpr
                              : field.value
                          }
                          error={!!fieldState.error}
                          helperText={
                            !!fieldState.error
                              ? fieldState.error.message
                              : undefined
                          }
                          inputProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.dark,
                              backgroundColor: "transparent !important",
                              width: isNarrow ? "180px" : "300px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Button
                    type='submit'
                    variant='contained'
                    sx={{ alignSelf: "center" }}
                  >
                    {t("shared.save")}
                  </Button>
                </Stack>
              </form>
            </CardContent>
          </Card>
        </Stack>
      </Stack>
    </Stack>
  );
};
