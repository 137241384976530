import { Button, CircularProgress, Input } from '@mui/material';
import { useTranslation } from 'react-i18next';
window.Buffer = window.Buffer || require('buffer').Buffer;

type UploadImageButtonProps = {
  handleUpload: (e) => void;
  isLoading: boolean;
};

export const UploadImageButton = ({
  handleUpload,
  isLoading,
}: UploadImageButtonProps) => {
  const { t } = useTranslation('common');
  return (
    <Button component='label' variant='contained' sx={{ width: 180 }}>
      {isLoading && (
        <CircularProgress
          size={20}
          sx={{ color: (theme) => theme.palette.secondary.light, mr: 1 }}
        />
      )}
      {t('admin.business.fileUpload')}
      <Input
        onChange={handleUpload}
        sx={{
          display: 'none',
        }}
        type='file'
      />
    </Button>
  );
}
