import locale from "../translations/cz/common.json";

export type LoginPayload = {
  email: string;
  password: string;
};

export type RegistrationPayload = {
  password: string;
  email: string;
};

export type Product = {
  id: string;
  name: string;
  description: string;
  images?: string[];
  price: number;
};

export type ApiProduct = {
  id: string;
  name: string;
  description: string;
  images: string[];
  businessId: string;
  price: number;
  inStock: number;
  categories: string[];
  createdAt: string;
  deletedAt: string | null;
  business: BusinessType;
};

export type BusinessType = {
  id: string;
  name?: string;
  description?: string;
  images?: string[];
  terms?: string;
  gdpr?: string;
};

export type CreateBusinessPayload = {
  name: string;
  description: string;
  images: string[];
  terms?: string;
  gdpr?: string;
};

export type UdateBusinessPayload = {
  name?: string;
  description?: string;
  images?: string[];
  terms?: string;
  gdpr?: string;
};

export type DeliveryDetails = {
  firstName: string;
  lastName: string;
  phone: string;
  streetAndStreetNumber: string;
  city: string;
  zipCode: string;
};

export type DecodeType = {
  iat: number;
  id: string;
  roles: Array<User>;
  email: string;
};

export type User = "ADMIN" | "CUSTOMER";

export type UpdateProductPayload = {
  businessId: string;
  productId: string;
  name?: string;
  description?: string;
  images?: string[];
  price?: number;
  inStock?: number;
  categories?: string[];
};

export type CreateProductPayload = {
  businessId: string;
  name: string;
  description: string;
  images: string[];
  price: number;
  inStock: number;
  categories: string[];
};

export type PasswordRecoveryPayload = { email: string };

export type ChangePasswordPayload = {
  password: string;
  token: string;
};

export type CartItemDetail = {
  id: string;
  quantity: number;
  cartId: string;
  productId: string;
  createdAt: string;
  product: ApiProduct;
};

type CustomerDeliveryMethod = {
  businessId: string;
  value: string;
};

export type CustomerOrder = {
  products: CartItemDetail[];
  price: number;
  deliveryMethods: CustomerDeliveryMethod[];
  paymentMethod: string;
};

export enum PaymentMethod {
  CARD = "card",
  APPLE_PAY = "apple_pay",
  GOOGLE_PAY = "google_pay",
}
type FlattenKeys<T, Prefix extends string = ""> = T extends object
  ? {
      [K in keyof T]:
        | `${Prefix & string}${string &
            (Prefix extends "" ? "" : ".")}${string & K}`
        | FlattenKeys<
            T[K],
            `${Prefix & string}${string &
              (Prefix extends "" ? "" : ".")}${string & K}`
          >;
    }[keyof T]
  : "";

export type TranslationsType = FlattenKeys<typeof locale>;
