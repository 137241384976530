import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import {
  useCreateBusinessOrder,
  useCreateCustomerOrder,
  useGetCart,
} from "../../../apiClient/apiClient";
import { CartItemDetail } from "../../../types/types";
import { useTranslation } from "react-i18next";
import { roundToTwoDecimals } from "../../../shared/helpers/helpers";
import { LocalShippingOutlined } from "@mui/icons-material";
import { useDeliveryDetailsContext } from "../../context/DeliveryDetailsContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

export const CartStepFinal = () => {
  const { t } = useTranslation("common");
  const { formData } = useDeliveryDetailsContext();
  const navigate = useNavigate();
  const auth = useAuth();

  const { data: cartData } = useGetCart({});
  const cart = cartData?.data?.items;

  const { mutate: createCustomerOrder, isLoading: isCreatingOrder } =
    useCreateCustomerOrder();

  const { mutate: createBusinessOrder, isLoading: isCreatingBusinessOrder } =
    useCreateBusinessOrder();

  const groupedCartObj = cart?.reduce((prev, current) => {
    return {
      ...prev,
      [current.product.businessId]: [
        ...(prev[current.product.businessId] || []),
        current,
      ],
    };
  }, {});

  const groupedCart: CartItemDetail[][] = Object.values(groupedCartObj ?? []);

  const pricesTotal = cart
    ?.map((item) => item.product.price * item.quantity)
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

  const handleSubmit = () => {
    if (
      pricesTotal &&
      pricesTotal > 0 &&
      cart &&
      formData?.deliveryDetails &&
      formData?.paymentMethod
    ) {
      createCustomerOrder(
        {
          products: cart,
          price: pricesTotal,
          deliveryMethods: formData.deliveryDetails,
          paymentMethod: formData.paymentMethod,
        },
        {
          onSuccess: (order) => {
            groupedCart?.map((businessItems, index) => {
              const orderDetails = order?.data?.deliveryDetails;
              const pricePerBusiness = businessItems
                .map((i) => i.product.price * i.quantity)
                .reduce((accumulator, currentValue) => {
                  return accumulator + currentValue;
                }, 0);

              const deliveryMethod =
                formData?.deliveryDetails?.find(
                  (m) => m.businessId === businessItems[0].product.business.id
                )?.value ?? "";
              return createBusinessOrder(
                {
                  products: businessItems,
                  price: pricePerBusiness,
                  businessId: businessItems[0].product.business.id,
                  deliveryFirstName: orderDetails.firstName,
                  deliveryLastName: orderDetails.lastName,
                  deliveryEmail: auth?.user?.email ?? "",
                  deliveryPhone: orderDetails.phone,
                  deliveryStreetAndStreetNumber:
                    orderDetails.streetAndStreetNumber,
                  deliveryZipCode: orderDetails.zipCode,
                  deliveryCity: orderDetails.city,
                  deliveryMethod,
                  paymentMethod: formData.paymentMethod ?? "",
                },
                {
                  onSuccess: () => {
                    navigate("/payment");
                  },
                  onError: (e) => console.log(e),
                }
              );
            });
          },
          onError: (e) => console.log(e),
        }
      );
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Stack
        spacing={2}
        justifyContent='center'
        alignItems='center'
        sx={{ pt: 5 }}
      >
        <Typography
          variant='h4'
          color='primary.dark'
          sx={{ textAlign: "center" }}
        >
          {t("customer.cart.cartContent")}
        </Typography>
        <Stack sx={{ width: "100%" }}>
          {groupedCart?.map((groupedItem, index) => (
            <Stack key={groupedItem[index]?.id} spacing={2} sx={{ my: 2 }}>
              <Stack direction='row' spacing={1}>
                <Typography variant='h5' color='text.secondary'>
                  {t("customer.cart.supplier")}:
                </Typography>
                <Typography
                  variant='h5'
                  color='text.secondary'
                  sx={{ fontWeight: 500 }}
                >
                  {groupedItem[0].product.business.name}
                </Typography>
              </Stack>
              <Stack>
                <Divider />
                {groupedItem?.map((item) => (
                  <Box key={item.id}>
                    <Stack
                      direction='row'
                      sx={{ py: 2, px: 2 }}
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Stack
                        direction='row'
                        spacing={1}
                        alignItems='center'
                        sx={{
                          overflow: "hidden",
                          mr: 3,
                        }}
                      >
                        <Avatar
                          src={item.product?.images?.[0]}
                          sx={{ width: 56, height: 56 }}
                        />
                        <Stack>
                          <Typography
                            variant='h6'
                            color='primary.dark'
                            sx={{
                              maxWidth: "186px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item?.product?.name}
                          </Typography>
                          <Typography
                            variant='subtitle2'
                            color='text.secondary'
                            sx={{
                              maxWidth: "186px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {roundToTwoDecimals(
                              item?.quantity * item?.product?.price
                            )}{" "}
                            {t("shared.currencyCZ")}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <Stack alignItems='center' spacing={1}>
                          {/* sem len cenu a pocut ks */}
                        </Stack>
                      </Stack>
                    </Stack>
                    <Divider />
                  </Box>
                ))}
              </Stack>
            </Stack>
          ))}
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{ pl: 2, pr: 3.5, mt: 2 }}
          >
            <Typography
              variant='h5'
              color='text.secondary'
              sx={{ fontWeight: 500 }}
            >
              {t("shared.inTotal")}:
            </Typography>
            <Typography variant='h5' color='text.secondary'>
              {roundToTwoDecimals(pricesTotal ?? 0)} Kč
            </Typography>
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='end'
            spacing={1}
            sx={{ pl: 2, pr: 3.5, my: 1, mb: 2 }}
          >
            <LocalShippingOutlined
              fontSize='small'
              sx={{ color: (theme) => theme.palette.primary.light }}
            />
            <Typography variant='body1' color='primary.light'>
              + {t("shared.delivery")} {t("shared.from")} 59{" "}
              {t("shared.currencyCZ")} * {groupedCart?.length} ={" "}
              {groupedCart?.length * 59} {t("shared.currencyCZ")}
            </Typography>
          </Stack>
          <Divider />
        </Stack>
      </Stack>
      <div style={{ position: "absolute", bottom: -60, right: 0 }}>
        <Button
          variant='contained'
          onClick={handleSubmit}
          disabled={
            isCreatingOrder ||
            isCreatingBusinessOrder ||
            (!!pricesTotal && pricesTotal < 1)
          }
          sx={{ borderRadius: "50px", color: "secondary.light" }}
        >
          {(isCreatingOrder || isCreatingBusinessOrder) && (
            <CircularProgress
              size={20}
              sx={{
                color: (theme) => theme.palette.secondary.light,
                mr: 1,
              }}
            />
          )}
          {t("shared.buy")}
        </Button>
      </div>
    </div>
  );
};
