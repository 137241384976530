import { Stack } from '@mui/system';
import { Header } from '../TopBar/Header';
import { DrawerListCategories } from '../Drawer/DrawerHome/DrawerListCategories';
import { Typography } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { DrawerListBusinessAdmin } from '../../../admin/components/DrawerBusinessAdmin/DrawerListBusinessAdmin';
import { useTranslation } from 'react-i18next';
import { DoNotDisturb } from '@mui/icons-material';

export const NotFound = () => {
  const { t } = useTranslation('common');

  const auth = useAuth();
  const isAdmin = auth?.user?.roles?.includes('ADMIN');

  return (
    <Stack>
      <Header>
        {isAdmin ? <DrawerListBusinessAdmin /> : <DrawerListCategories />}
      </Header>
      <Stack spacing={2} alignItems='center' sx={{ pt: 15 }}>
        <DoNotDisturb fontSize='large' sx={{ color: 'text.secondary' }} />
        <Typography variant='h3' color='text.secondary'>
          {t('shared.pageNotFound')}
        </Typography>
      </Stack>
    </Stack>
  );
};
