import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Cancel } from "@mui/icons-material";
import {
  useAddToCart,
  useDeleteCartItem,
  useGetCart,
  useGetProduct,
  useUpdateCartItem,
} from "../../../apiClient/apiClient";
import { handleInStockText } from "../../../shared/helpers/helpers";
import { QuantityInput } from "../UI/QuantityInput";
import { CarouselSlider } from "../../../shared/components/UI/CarouselSlider";
import { useAuth } from "../../context/AuthContext";
import { SnackbarMsg } from "../../../shared/components/UI/SnackbarMsg";
import {
  addedToCart,
  removedFromCart,
  somethingWentWrong,
} from "../../../shared/helpers/values";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../shared/components/UI/Spinner";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";

export const ProductDetail = ({
  isFromBusiness,
}: {
  isFromBusiness?: boolean;
}) => {
  const { t } = useTranslation("common");
  const [opened, setOpened] = useState(false);
  const [showMsg, setShowMsg] = useState<{
    successMsg: boolean;
    errorMsg: boolean;
    removeFromCartMsg: boolean;
  }>({ successMsg: false, errorMsg: false, removeFromCartMsg: false });

  const navigate = useNavigate();
  const { id, productId } = useParams<{ id: string; productId: string }>();

  const { data: customerProductData, isLoading: isLoadingProduct } =
    useGetProduct({ productId: String(productId) });

  const auth = useAuth();
  const { data: cartData } = useGetCart({
    enabled: !!auth?.user,
  });

  const { mutate: updateCartItem, isLoading: isLoadingCartItem } =
    useUpdateCartItem();

  const { mutate: deleteCartItem, isLoading: isDeleting } = useDeleteCartItem();
  const cart = cartData?.data?.items;

  const { mutate: addToCart, isLoading: isAddingToCart } = useAddToCart();

  const onAddToCart = ({ productId }) => {
    addToCart(
      { productId },
      {
        onSuccess: () =>
          setShowMsg({
            successMsg: true,
            errorMsg: false,
            removeFromCartMsg: false,
          }),
        onError: () =>
          setShowMsg({
            successMsg: false,
            errorMsg: true,
            removeFromCartMsg: false,
          }),
      }
    );
  };

  const isInCart =
    cart && cart?.filter((item) => item.productId === productId).length > 0;

  useEffect(() => {
    if (productId) {
      setOpened(true);
    }
  }, [productId]);

  const handleClose = () => {
    if (!isFromBusiness) {
      navigate(-1);
    } else {
      navigate(`/business/${id}`);
    }
    setOpened(false);
  };

  const isLoading =
    isLoadingProduct || isDeleting || isAddingToCart || isLoadingCartItem;

  const onLessClick = ({ cartItemId, quantity }) => {
    if (quantity - 1 === 0) {
      deleteCartItem(
        { cartItemId },
        {
          onSuccess: () =>
            setShowMsg({
              successMsg: false,
              errorMsg: false,
              removeFromCartMsg: true,
            }),
          onError: () =>
            setShowMsg({
              successMsg: false,
              errorMsg: true,
              removeFromCartMsg: false,
            }),
        }
      );
      return;
    }
    updateCartItem({
      cartItemId,
      quantity: quantity - 1,
    });
  };

  const onMoreClick = ({ cartItemId, quantity }) => {
    updateCartItem({
      cartItemId,
      quantity: quantity + 1,
    });
  };

  return (
    <>
      <Dialog
        open={opened}
        onClose={handleClose}
        PaperProps={{
          sx: {
            height: "90%",
            borderRadius: "20px",
            position: "fixed",
            backgroundColor: (theme) => theme.palette.secondary.light,
          },
          elevation: 2,
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <DialogContent
            sx={{
              mb: 3,
              p: 0,
              overflowY: "visible",
              overflowX: "hidden",
              backgroundColor: (theme) => theme.palette.secondary.light,
            }}
          >
            <Stack sx={{ height: "100%", justifyContent: "space-between" }}>
              <Stack>
                <IconButton
                  onClick={handleClose}
                  sx={{ position: "absolute", right: 0, zIndex: 2 }}
                  size='large'
                >
                  <Cancel
                    sx={{ color: (theme) => theme.palette.text.secondary }}
                    fontSize='large'
                  />
                </IconButton>
                <Stack sx={{ height: 280, mt: 0 }}>
                  <CarouselSlider slides={customerProductData?.data?.images} />
                </Stack>
                <Stack spacing={3} sx={{ m: 4 }}>
                  {customerProductData?.data?.business.id && (
                    <Stack sx={{ alignSelf: "start" }}>
                      <StyledLink
                        to={`/business/${customerProductData?.data?.business.id}`}
                      >
                        <Chip
                          label={customerProductData?.data?.business.name}
                          variant='outlined'
                          color='primary'
                          sx={{ cursor: "pointer" }}
                        />
                      </StyledLink>
                    </Stack>
                  )}
                  <Typography
                    color='text.primary'
                    variant='h4'
                    sx={{ fontWeight: 500 }}
                  >
                    {customerProductData?.data?.name}
                  </Typography>
                  <Typography color='text.secondary' variant='subtitle1'>
                    {customerProductData?.data?.description}
                  </Typography>
                  <Typography
                    variant='h4'
                    color='text.primary'
                    sx={{ fontWeight: 500 }}
                  >{`${customerProductData?.data?.price} Kč`}</Typography>
                  <Typography
                    variant='body2'
                    sx={{ fontWeight: 500 }}
                    color={
                      customerProductData?.data?.inStock === 0
                        ? "error"
                        : "primary.dark"
                    }
                  >
                    {t(handleInStockText(customerProductData?.data?.inStock), {
                      inStock: customerProductData?.data?.inStock,
                    })}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  alignItems: "center",
                  justifySelf: "center",
                  mb: 2,
                  pb: 1,
                }}
              >
                {isInCart && cart ? (
                  <QuantityInput
                    cartItemQuantity={
                      cart.find((item) => item.productId === productId)!
                        .quantity
                    }
                    isLoading={isLoading}
                    onLessClick={() =>
                      onLessClick({
                        cartItemId: cart?.find(
                          (item) => item.productId === productId
                        )!.id,
                        quantity: cart?.find(
                          (item) => item.productId === productId
                        )!.quantity,
                      })
                    }
                    onMoreClick={() =>
                      onMoreClick({
                        cartItemId: cart?.find(
                          (item) => item.productId === productId
                        )!.id,
                        quantity: cart?.find(
                          (item) => item.productId === productId
                        )!.quantity,
                      })
                    }
                    size='40px'
                  />
                ) : (
                  <Tooltip
                    title={!auth?.user ? t("shared.login.loginWarning") : ""}
                    arrow
                  >
                    <span>
                      <Button
                        variant='contained'
                        onClick={(e) => {
                          e.preventDefault();
                          onAddToCart({ productId });
                        }}
                        disabled={
                          isLoading ||
                          customerProductData?.data?.inStock === 0 ||
                          !auth?.user
                        }
                        size='large'
                        sx={{ borderRadius: "50px", color: "secondary.light" }}
                      >
                        {t("shared.addToCart")}
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
          </DialogContent>
        )}
      </Dialog>
      <SnackbarMsg
        open={showMsg.successMsg}
        handleClose={() =>
          setShowMsg({
            successMsg: false,
            errorMsg: false,
            removeFromCartMsg: false,
          })
        }
        message={t(addedToCart)}
        severity='success'
      />
      <SnackbarMsg
        open={showMsg.removeFromCartMsg}
        handleClose={() =>
          setShowMsg({
            successMsg: false,
            errorMsg: false,
            removeFromCartMsg: false,
          })
        }
        message={t(removedFromCart)}
        severity='success'
      />
      <SnackbarMsg
        open={showMsg.errorMsg}
        handleClose={() =>
          setShowMsg({
            successMsg: false,
            errorMsg: false,
            removeFromCartMsg: false,
          })
        }
        message={t(somethingWentWrong)}
        severity='error'
      />
    </>
  );
};
