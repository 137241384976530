export const roundToTwoDecimals = (num: number) => Math.round(num * 100) / 100;

export const handleInStockText = (inStock: number) => {
  if (inStock === 0) {
    return 'customer.products.notInStock';
  }
  if (inStock > 0 && inStock < 6) {
    return `customer.products.inStock`;
  } else return `customer.products.inStockMore`;
};
