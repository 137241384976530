import React from "react";
import { Header } from "../../../customer/components/TopBar/Header";
import { useWindowSize } from "../../hooks/useWindowSize";
import { NARROW_SCREEN } from "../../helpers/values";
import { LandingPageMobile } from "./LandingPageMobile";
import { LandingPageDesktop } from "./LandingPageDesktop";

export const LandingPage = () => {
  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  return (
    <>
      {/* todo - add app reviews from heureka etc */}
      <Header />
      {isNarrow ? <LandingPageMobile /> : <LandingPageDesktop />}
    </>
  );
};
