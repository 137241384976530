import {
  Button,
  CardContent,
  CardMedia,
  CircularProgress,
  // todo - add rating to product
  Rating,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  useAddToCart,
  useDeleteCartItem,
  useGetCart,
  useUpdateCartItem,
} from "../../../apiClient/apiClient";
import { useAuth } from "../../context/AuthContext";
import { StyledCardActionArea } from "../../../shared/components/UI/StyledCardActionArea";
import { StyledCard } from "../../../shared/components/UI/StyledCard";
import { handleInStockText } from "../../../shared/helpers/helpers";
import { SnackbarMsg } from "../../../shared/components/UI/SnackbarMsg";
import {
  addedToCart,
  removedFromCart,
  somethingWentWrong,
} from "../../../shared/helpers/values";
import { useTranslation } from "react-i18next";

export type ProductCardProps = {
  id: string;
  name: string;
  description: string;
  images?: string[];
  price: number;
  inStock: number;
};

export const ProductCard = ({
  id,
  name,
  description,
  images,
  price,
  inStock,
}: ProductCardProps) => {
  const { t } = useTranslation("common");
  const [showMsg, setShowMsg] = useState<{
    successMsg: boolean;
    errorMsg: boolean;
    removeFromCartMsg: boolean;
  }>({ successMsg: false, errorMsg: false, removeFromCartMsg: false });
  const { mutate: addToCart, isLoading: isLoadingCart } = useAddToCart();
  const auth = useAuth();

  const { data: cartData, refetch } = useGetCart({ enabled: !!auth?.user });
  const { mutate: updateCartItem, isLoading: isLoadingCartItem } =
    useUpdateCartItem();
  const { mutate: deleteCartItem, isLoading: isLoadingDeleteCartItem } =
    useDeleteCartItem();

  useEffect(() => {
    if (!isLoadingCart || !isLoadingCartItem) {
      refetch();
    }
  }, [isLoadingCart, isLoadingCartItem, refetch]);

  const isInCart = cartData
    ? cartData?.data?.items?.filter((item) => item.productId === id).length > 0
    : false;

  const cartItem = cartData?.data?.items?.find((item) => item.productId === id);

  useEffect(() => {
    if (cartItem?.quantity === 0) {
      deleteCartItem(
        { cartItemId: cartItem.id },
        {
          onSuccess: () =>
            setShowMsg({
              successMsg: false,
              errorMsg: false,
              removeFromCartMsg: true,
            }),
        }
      );
    }
  }, [cartItem, deleteCartItem]);

  const onAddToCart = ({ productId }) => {
    addToCart(
      { productId },
      {
        onSuccess: () =>
          setShowMsg({
            successMsg: true,
            errorMsg: false,
            removeFromCartMsg: false,
          }),
        onError: () =>
          setShowMsg({
            successMsg: false,
            errorMsg: true,
            removeFromCartMsg: false,
          }),
      }
    );
  };

  const onLessClick = ({ cartItemId }) => {
    updateCartItem({
      cartItemId,
      quantity: cartItem ? cartItem?.quantity - 1 : 0,
    });
  };

  const onMoreClick = ({ cartItemId }) => {
    updateCartItem({
      cartItemId,
      quantity: cartItem ? cartItem?.quantity + 1 : 0,
    });
  };

  return (
    <>
      <StyledCard>
        <StyledCardActionArea disableRipple>
          <CardMedia
            // todo - map images
            image={images?.[0]}
            component='img'
            alt='alt'
            height={180}
            sx={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
          />
          <CardContent sx={{ px: 3, height: "228px" }}>
            <Stack spacing={0} display='grid' sx={{ pb: 1 }}>
              <Typography
                variant='h5'
                color='text.primary'
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "normal",
                  display: "-webkit-box !important",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {name}
              </Typography>
              <Typography
                variant='body2'
                color='text.secondary'
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "normal",
                  display: "-webkit-box !important",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  pt: 1.5,
                }}
              >
                {description}
              </Typography>
              <Typography
                variant='h5'
                color='text.primary'
                sx={{ fontWeight: 500, pt: 1.5 }}
              >{`${price} Kč`}</Typography>
              <Typography
                variant='body2'
                sx={{ fontWeight: 500 }}
                color={inStock === 0 ? "error" : "primary.dark"}
              >
                {t(handleInStockText(inStock), { inStock })}
              </Typography>
              {isInCart && cartItem && cartItem?.quantity >= 1 ? (
                <Stack
                  direction='row'
                  spacing={2}
                  sx={{ alignItems: "center", justifySelf: "center", mt: 2.5 }}
                  onClick={(e) => e.preventDefault()}
                >
                  <Button
                    variant='contained'
                    size='small'
                    sx={{
                      maxWidth: "34px",
                      maxHeight: "34px",
                      minWidth: "34px",
                      minHeight: "34px",
                    }}
                    disabled={isLoadingCart || inStock === 0 || !auth?.user}
                    onClick={() => onLessClick({ cartItemId: cartItem?.id })}
                  >
                    <Typography variant='h5' sx={{ color: "secondary.light" }}>
                      -
                    </Typography>
                  </Button>
                  {isLoadingCartItem || isLoadingDeleteCartItem ? (
                    <CircularProgress size={15} />
                  ) : (
                    <Typography variant='subtitle1'>
                      {cartItem?.quantity}
                    </Typography>
                  )}
                  <Button
                    variant='contained'
                    size='small'
                    sx={{
                      maxWidth: "34px",
                      maxHeight: "34px",
                      minWidth: "34px",
                      minHeight: "34px",
                    }}
                    onClick={() => onMoreClick({ cartItemId: cartItem?.id })}
                    disabled={cartItem?.product?.inStock <= cartItem?.quantity}
                  >
                    <Typography variant='h5' sx={{ color: "secondary.light" }}>
                      +
                    </Typography>
                  </Button>
                </Stack>
              ) : (
                <Tooltip
                  title={!auth?.user ? t("shared.login.loginWarning") : ""}
                  arrow
                >
                  <span>
                    <Button
                      variant='contained'
                      onClick={(e) => {
                        e.preventDefault();
                        onAddToCart({ productId: id });
                      }}
                      sx={{
                        mt: 2.5,
                        borderRadius: "50px",
                        color: "secondary.light",
                        width: "100%",
                      }}
                      disabled={isLoadingCart || inStock === 0 || !auth?.user}
                    >
                      {isLoadingCart ? (
                        <CircularProgress size={24} />
                      ) : (
                        t("shared.addToCart")
                      )}
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Stack>
          </CardContent>
        </StyledCardActionArea>
      </StyledCard>
      <SnackbarMsg
        open={showMsg.successMsg}
        handleClose={() =>
          setShowMsg({
            successMsg: false,
            errorMsg: false,
            removeFromCartMsg: false,
          })
        }
        message={t(addedToCart)}
        severity='success'
      />
      <SnackbarMsg
        open={showMsg.removeFromCartMsg}
        handleClose={() =>
          setShowMsg({
            successMsg: false,
            errorMsg: false,
            removeFromCartMsg: false,
          })
        }
        message={t(removedFromCart)}
        severity='success'
      />
      <SnackbarMsg
        open={showMsg.errorMsg}
        handleClose={() =>
          setShowMsg({
            successMsg: false,
            errorMsg: false,
            removeFromCartMsg: false,
          })
        }
        message={t(somethingWentWrong)}
        severity='error'
      />
    </>
  );
};
