import { Box, CardContent, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { StyledCard } from "../../../shared/components/UI/StyledCard";
import { useTranslation } from "react-i18next";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";
import addLogo from "../UI/add.png";

export const AddProductCard = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();

  return (
    <StyledLink to={`/admin/business/${id}/products/add`}>
      <StyledCard
        sx={{
          minHeight: 468,
        }}
      >
        <CardContent>
          <Stack alignItems='center' justifyContent='center' spacing={6}>
            <Box
              sx={{
                mt: 3,
                p: 3,
                borderStyle: "dashed",
                borderColor: (theme) => theme.palette.primary.light,
                borderWidth: "2px",
                borderRadius: 4,
              }}
            >
              <img src={addLogo} alt='add-product' style={{ width: 180 }} />
            </Box>
            <Typography variant='h4' color='primary.dark'>
              {t("admin.product.addProduct")}
            </Typography>
          </Stack>
        </CardContent>
      </StyledCard>
    </StyledLink>
  );
};
