import { Button, Stack } from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const CheckoutForm = ({ price }: { price: number }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment/success`,
      },
    });

    if (error) {
      navigate("/payment/fail");
    }

    setIsProcessing(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={3} alignItems='center'>
        <PaymentElement />
        <Button
          type='submit'
          variant='outlined'
          size='medium'
          disabled={isProcessing}
          sx={{ borderRadius: "10px", width: "100%" }}
        >
          {`${t("shared.pay")} ${price}${t("shared.currencyCZ")}`}
        </Button>
      </Stack>
    </form>
  );
};
