import { Paper, Stack, Typography } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { StyledTextField } from "../../../shared/components/UI/StyledTextField";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { NARROW_SCREEN } from "../../../shared/helpers/values";
import { useTranslation } from "react-i18next";

type CartStepDeliveryDetailsProps = {
  control: Control<{
    firstName: string;
    lastName: string;
    phone: string;
    streetAndStreetNumber: string;
    city: string;
    zipCode: string;
  }>;
  deliveryDetails?: {
    firstName: string;
    lastName: string;
    phone: string;
    streetAndStreetNumber: string;
    city: string;
    zipCode: string;
  };
};

export const CartStepDeliveryDetails = ({
  control,
}: CartStepDeliveryDetailsProps) => {
  const { t } = useTranslation("common");
  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  return (
    <Stack
      spacing={2}
      justifyContent='center'
      alignItems='center'
      sx={{ pt: 5 }}
    >
      <Typography
        variant='h4'
        color='primary.dark'
        sx={{ textAlign: "center" }}
      >
        {t("customer.cart.deliveryDetails")}
      </Typography>
      <Paper
        variant='outlined'
        sx={{
          backgroundColor: "transparent",
          borderRadius: "50px",
          borderWidth: "2px",
          borderColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.primary.light,
          width: "90%",
        }}
      >
        <Stack
          alignItems='center'
          justifyContent='center'
          spacing={5}
          sx={{ m: 5 }}
        >
          <Typography
            variant='h4'
            sx={{
              color: (theme) => theme.palette.text.secondary,
              textAlign: "center",
            }}
          >
            {t("customer.cart.deliveryEnter")}
          </Typography>
          <Stack spacing={3}>
            <Stack
              direction='row'
              spacing={3}
              alignItems='center'
              sx={{ justifyContent: "space-between" }}
            >
              <Typography
                variant='h6'
                color='text.secondary'
                sx={{ flex: 1, textAlign: "end" }}
              >
                {t("shared.name")}:
              </Typography>
              <Controller
                control={control}
                name='firstName'
                render={({ field, fieldState, formState }) => (
                  <StyledTextField
                    {...field}
                    variant='outlined'
                    size='small'
                    error={!!fieldState.error}
                    helperText={
                      !!fieldState.error ? fieldState.error.message : undefined
                    }
                    inputProps={{
                      sx: {
                        color: (theme) => theme.palette.text.primary,
                        width: isNarrow ? "200px" : "300px",
                      },
                    }}
                    InputLabelProps={{
                      sx: { color: (theme) => theme.palette.primary.main },
                    }}
                  />
                )}
              />
            </Stack>
            <Stack
              direction='row'
              spacing={3}
              alignItems='center'
              sx={{ justifyContent: "space-between" }}
            >
              <Typography
                variant='h6'
                color='text.secondary'
                sx={{ flex: 1, textAlign: "end" }}
              >
                {t("shared.surname")}:
              </Typography>
              <Controller
                control={control}
                name='lastName'
                render={({ field, fieldState, formState }) => (
                  <StyledTextField
                    {...field}
                    variant='outlined'
                    size='small'
                    error={!!fieldState.error}
                    helperText={
                      !!fieldState.error ? fieldState.error.message : undefined
                    }
                    inputProps={{
                      sx: {
                        color: (theme) => theme.palette.text.primary,
                        width: isNarrow ? "200px" : "300px",
                      },
                    }}
                    InputLabelProps={{
                      sx: { color: (theme) => theme.palette.primary.main },
                    }}
                  />
                )}
              />
            </Stack>
            <Stack
              direction='row'
              spacing={3}
              alignItems='center'
              sx={{ justifyContent: "space-between" }}
            >
              <Typography
                variant='h6'
                color='text.secondary'
                sx={{ flex: 1, textAlign: "end" }}
              >
                {t("customer.cart.phoneNumber")}:
              </Typography>
              {/*  TODO - treba validaciu ci uz cislo neexistuje */}
              <Controller
                control={control}
                name='phone'
                render={({ field, fieldState, formState }) => (
                  <StyledTextField
                    {...field}
                    variant='outlined'
                    size='small'
                    error={!!fieldState.error}
                    helperText={
                      !!fieldState.error ? fieldState.error.message : undefined
                    }
                    inputProps={{
                      sx: {
                        color: (theme) => theme.palette.text.primary,
                        width: isNarrow ? "200px" : "300px",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: (theme) => theme.palette.primary.main,
                      },
                    }}
                  />
                )}
              />
            </Stack>
            {/* todo - implement autocomplete
            https://mui.com/material-ui/react-autocomplete/#google-maps-place
            https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service */}
            <Stack
              direction='row'
              spacing={3}
              alignItems='center'
              sx={{ justifyContent: "space-between" }}
            >
              <Typography
                variant='h6'
                color='text.secondary'
                sx={{ flex: 1, textAlign: "end" }}
              >
                {t("shared.street")}:
              </Typography>
              <Controller
                control={control}
                name='streetAndStreetNumber'
                render={({ field, fieldState, formState }) => (
                  <StyledTextField
                    {...field}
                    variant='outlined'
                    size='small'
                    error={!!fieldState.error}
                    helperText={
                      !!fieldState.error ? fieldState.error.message : undefined
                    }
                    inputProps={{
                      sx: {
                        color: (theme) => theme.palette.text.primary,
                        width: isNarrow ? "200px" : "300px",
                      },
                    }}
                    InputLabelProps={{
                      sx: { color: (theme) => theme.palette.primary.main },
                    }}
                  />
                )}
              />
            </Stack>
            <Stack
              direction='row'
              spacing={3}
              alignItems='center'
              sx={{ justifyContent: "space-between" }}
            >
              <Typography
                variant='h6'
                color='text.secondary'
                sx={{ flex: 1, textAlign: "end" }}
              >
                {t("shared.city")}:
              </Typography>
              <Controller
                control={control}
                name='city'
                render={({ field, fieldState, formState }) => (
                  <StyledTextField
                    {...field}
                    variant='outlined'
                    size='small'
                    error={!!fieldState.error}
                    helperText={
                      !!fieldState.error ? fieldState.error.message : undefined
                    }
                    inputProps={{
                      sx: {
                        color: (theme) => theme.palette.text.primary,
                        width: isNarrow ? "200px" : "300px",
                      },
                    }}
                    InputLabelProps={{
                      sx: { color: (theme) => theme.palette.primary.main },
                    }}
                  />
                )}
              />
            </Stack>
            <Stack
              direction='row'
              spacing={3}
              alignItems='center'
              sx={{ justifyContent: "space-between" }}
            >
              <Typography
                variant='h6'
                color='text.secondary'
                sx={{ flex: 1, textAlign: "end" }}
              >
                {t("shared.postalCode")}:
              </Typography>
              <Controller
                control={control}
                name='zipCode'
                render={({ field, fieldState, formState }) => (
                  <StyledTextField
                    {...field}
                    variant='outlined'
                    size='small'
                    error={!!fieldState.error}
                    helperText={
                      !!fieldState.error ? fieldState.error.message : undefined
                    }
                    inputProps={{
                      sx: {
                        color: (theme) => theme.palette.text.primary,
                        width: isNarrow ? "200px" : "300px",
                      },
                    }}
                    InputLabelProps={{
                      sx: { color: (theme) => theme.palette.primary.main },
                    }}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};
