import CookieConsent from "react-cookie-consent";
import React from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import { Cookie, InfoOutlined } from "@mui/icons-material";

export const CookieConsentProvider = () => {
  const { t } = useTranslation("common");

  return (
    <CookieConsent
      location='none'
      buttonText={t("shared.confirm")}
      acceptOnScroll={false}
      acceptOnOverlayClick={false}
      style={{
        zIndex: 1200,
        bottom: 0,
        left: "unset",
        backgroundColor: "#513313",
        color: "#fff",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        fontSize: "18px",
        alignItems: "center",
        opacity: 0.7,
      }}
      buttonStyle={{
        backgroundColor: "#fff",
        color: "#d67a19",
        fontWeight: "bold",
        borderRadius: "50px",
        fontSize: "14px",
        width: "100px",
      }}
      debug={true}
    >
      <Stack direction='row' spacing={2}>
        <Cookie />
        <Typography>{t("shared.cookiesMsg")}</Typography>
      </Stack>
    </CookieConsent>
  );
};
