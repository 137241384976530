import { Button, IconButton, Paper, Stack, Typography } from '@mui/material';
import { Header } from '../../../customer/components/TopBar/Header';
import { Controller, useForm } from 'react-hook-form';
import { StyledTextField } from '../UI/StyledTextField';
import { SnackbarMsg } from '../UI/SnackbarMsg';
import { useWindowSize } from '../../hooks/useWindowSize';
import {
  NARROW_SCREEN,
  requiredField,
  somethingWentWrong,
} from '../../helpers/values';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useChangePassword } from '../../../apiClient/apiClient';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';

export const ChangePassword = () => {
  const { t } = useTranslation('common');
  const [showMsg, setShowMsg] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  const ChangePasswordSchema = () =>
    yup.object({
      password: yup.string().required(t(requiredField).toString()),
    });

  const { token } = useParams<{ token: string }>();
  const decode: { email: string; iat: number; exp: number } = jwt_decode(
    token ?? ''
  );

  const email = decode.email;

  const navigate = useNavigate();

  const { mutate: changePassword } = useChangePassword();
  const { control, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(ChangePasswordSchema()),
    defaultValues: {
      password: '',
    },
  });

  const onSubmit = ({ password }) => {
    if (token) {
      changePassword(
        { password, token },
        {
          onSuccess: () => {
            navigate('/login');
          },
          onError: () => setShowMsg(true),
        }
      );
    }
  };

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;
  return email || decode ? (
    <>
      <Header />
      <Stack
        alignItems='center'
        justifyContent='center'
        spacing={5}
        sx={{ m: isNarrow ? 0 : 5, mt: 12, mb: 5, overflowX: 'hidden' }}
      >
        <Typography variant='h6' color='text.primary'>
          {t('shared.recovery.recoveryTitle')} {email}
        </Typography>
        <Paper
          variant='outlined'
          sx={{
            backgroundColor: 'secondary.light',
            borderRadius: '50px',
            borderWidth: '2px',
            borderColor: (theme) => theme.palette.primary.main,
            width: isNarrow ? '95%' : '60%',
            color: (theme) => theme.palette.primary.light,
          }}
        >
          <Stack
            alignItems='center'
            justifyContent='center'
            spacing={4}
            sx={{ m: 5 }}
          >
            <Typography
              variant='h4'
              sx={{
                color: (theme) => theme.palette.primary.dark,
                textAlign: 'center',
              }}
            >
              {t('shared.recovery.newPasswordPrompt')}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <Stack
                  direction='row'
                  spacing={3}
                  alignItems='center'
                  sx={{ justifyContent: 'space-between' }}
                >
                  <Typography
                    variant='h6'
                    color='text.secondary'
                    sx={{ flex: 1, textAlign: 'end' }}
                  >
                    {t('shared.recovery.newPassword')}:
                  </Typography>
                  <Controller
                    control={control}
                    name='password'
                    render={({ field, fieldState, formState }) => (
                      <StyledTextField
                        {...field}
                        type={showPassword ? 'text' : 'password'}
                        id='newPassword'
                        variant='outlined'
                        autoComplete='new-password'
                        size='small'
                        error={!!fieldState.error}
                        sx={{ width: isNarrow ? '228px' : '328px' }}
                        helperText={
                          !!fieldState.error
                            ? fieldState.error.message
                            : undefined
                        }
                        inputProps={{
                          sx: {
                            color: (theme) => theme.palette.text.primary,
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            color: (theme) => theme.palette.primary.main,
                          },
                        }}
                        InputProps={{
                          sx: {
                            color: (theme) => theme.palette.secondary.main,
                          },
                          endAdornment: (
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={() => setShowPassword(!showPassword)}
                              edge='end'
                              data-cy='show-password-toggler'
                              sx={{
                                '&:hover': {
                                  backgroundColor: 'secondary.dark',
                                },
                              }}
                            >
                              {showPassword ? (
                                <VisibilityOff
                                  fontSize='small'
                                  sx={{
                                    color: 'primary.dark',
                                    '&:hover': {
                                      color: 'primary.light',
                                      transition: 'smooth',
                                    },
                                  }}
                                  data-cy='eye-off'
                                />
                              ) : (
                                <Visibility
                                  fontSize='small'
                                  sx={{
                                    color: 'primary.dark',
                                    '&:hover': {
                                      color: 'primary.light',
                                      transition: 'smooth',
                                    },
                                  }}
                                  data-testid='eye-on'
                                />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                    )}
                  />
                </Stack>
                <Button
                  variant='contained'
                  type='submit'
                  sx={{ borderRadius: '50px', color: 'secondary.light' }}
                >
                  {t('shared.send')}
                </Button>
              </Stack>
            </form>
          </Stack>
        </Paper>
      </Stack>
      <SnackbarMsg
        open={showMsg}
        handleClose={() => setShowMsg(false)}
        severity='error'
        message={t(somethingWentWrong)}
      />
    </>
  ) : (
    <>
      <Header />
      <Stack sx={{ pt: 10 }}>
        <Typography>{t('shared.recovery.invalidLink')}</Typography>
      </Stack>
    </>
  );
};
