import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  BusinessType,
  CartItemDetail,
  ChangePasswordPayload,
  CreateBusinessPayload,
  CreateProductPayload,
  CustomerOrder,
  DeliveryDetails,
  LoginPayload,
  PasswordRecoveryPayload,
  PaymentMethod,
  RegistrationPayload,
  UpdateProductPayload,
} from "../types/types";
import { backendUrl } from "./config";

const GET_CART = "getCart";

const useAxiosInstance = (token: string) => {
  const apiClient = axios.create({
    baseURL: backendUrl,
    headers: { Authorization: `token ${token}` },
  });
  return apiClient;
};

// Auth

export const useLogin = () => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);
  return useMutation("login", ({ email, password }: LoginPayload) =>
    apiClient.post("/auth/sign-in", { email, password })
  );
};

export const useRegistrateCustomer = () => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useMutation(
    "registrateCustomer",
    ({ password, email }: RegistrationPayload) =>
      apiClient.post("/auth/sign-up/customer", {
        password,
        email,
      })
  );
};

export const useRegistrateAdmin = () => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useMutation(
    "registrateAdmin",
    ({ password, email }: RegistrationPayload) =>
      apiClient.post("/auth/sign-up/admin", {
        password,
        email,
      })
  );
};

export const useDeleteUser = () => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useMutation("deleteUser", ({ userId }: { userId: string }) =>
    apiClient.delete(`/auth/${userId}`)
  );
};

// User

export const useGetDeliveryDetails = () => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useQuery("getDeliveryDetails" as const, () =>
    apiClient.get(`/user/detail`)
  );
};

export const useCreateDeliveryDetails = () => {
  const queryClient = useQueryClient();
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useMutation(
    "createDeliveryDetails" as const,
    ({
      firstName,
      lastName,
      phone,
      streetAndStreetNumber,
      city,
      zipCode,
    }: DeliveryDetails) =>
      apiClient.post(`/user/detail`, {
        firstName,
        lastName,
        phone,
        streetAndStreetNumber,
        city,
        zipCode,
      }),
    { onSuccess: () => queryClient.invalidateQueries("getDeliveryDetails") }
  );
};

export const useUpdateDeliveryDetails = () => {
  const queryClient = useQueryClient();
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useMutation(
    "updateDeliveryDetails" as const,
    ({
      firstName,
      lastName,
      phone,
      streetAndStreetNumber,
      city,
      zipCode,
    }: DeliveryDetails) =>
      apiClient.put(`/user/detail`, {
        firstName,
        lastName,
        phone,
        streetAndStreetNumber,
        city,
        zipCode,
      }),
    { onSuccess: () => queryClient.invalidateQueries("getDeliveryDetails") }
  );
};

export const useVerifyUser = ({ email }: { email: string }) => {
  const apiClient = useAxiosInstance("");

  return useQuery("verifyUser" as const, () =>
    apiClient.post(`/auth/verify-user`, {
      email,
    })
  );
};

// Admin business

export const useGetAdminBusinesses = () => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useQuery("getBusinesess" as const, () =>
    apiClient.get(`/admin/business`)
  );
};

export const useGetAdminBusiness = ({ businessId }: { businessId: string }) => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  // todo types
  return useQuery("getAdminBusiness" as const, () =>
    apiClient.get(`/admin/business/${businessId}`)
  );
};

export const useUpdateAdminBusiness = () => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);
  const queryClient = useQueryClient();

  return useMutation(
    "updateAdminBusiness",
    ({ id, name, description, images, terms, gdpr }: BusinessType) =>
      apiClient.put(`/admin/business/${id}`, {
        name,
        description,
        images,
        terms,
        gdpr,
      }),
    { onSuccess: () => queryClient.invalidateQueries("getAdminBusiness") }
  );
};

export const useCreateAdminBusiness = () => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);
  const queryClient = useQueryClient();

  return useMutation(
    "createAdminBusiness",
    ({ name, description, images, terms, gdpr }: CreateBusinessPayload) =>
      apiClient.post(`/admin/business`, {
        name,
        description,
        images,
        terms,
        gdpr,
      }),
    { onSuccess: () => queryClient.invalidateQueries("getAdminBusiness") }
  );
};

// Admin products

export const useGetAdminProducts = ({
  businessId,
  page = 0,
  pageSize = 200,
}: {
  businessId: string;
  page?: number;
  pageSize?: number;
}) => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);
  // todo response type Product[]
  return useQuery("getProducts", () =>
    apiClient.get(`/admin/business/${businessId}/products`, {
      params: { page, pageSize },
    })
  );
};

export const useGetAdminProduct = ({
  businessId,
  productId,
}: {
  businessId: string;
  productId: string;
}) => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useQuery("getProduct", () =>
    apiClient.get(`/admin/business/${businessId}/products/${productId}`)
  );
};

export const useUpdateAdminProduct = () => {
  const queryClient = useQueryClient();
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useMutation(
    "updateAdminProduct",
    ({
      businessId,
      productId,
      name,
      description,
      images,
      price,
      inStock,
      categories,
    }: UpdateProductPayload) =>
      apiClient.put(`/admin/business/${businessId}/products/${productId}`, {
        name,
        description,
        images,
        price,
        inStock,
        categories,
      }),
    { onSuccess: () => queryClient.invalidateQueries("getProduct") }
  );
};

export const useCreateAdminProduct = () => {
  const queryClient = useQueryClient();
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useMutation(
    "createAdminProduct",
    ({
      businessId,
      name,
      description,
      images,
      price,
      inStock,
      categories,
    }: CreateProductPayload) =>
      apiClient.post(`/admin/business/${businessId}/products`, {
        name,
        description,
        images,
        price,
        inStock,
        categories,
      }),
    { onSuccess: () => queryClient.invalidateQueries("getProduct") }
  );
};

export const useDeleteAdminProduct = () => {
  const queryClient = useQueryClient();
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useMutation(
    "deleteProduct",
    ({ businessId, productId }: { businessId: string; productId: string }) =>
      apiClient.delete(`/admin/business/${businessId}/products/${productId}`),
    { onSuccess: () => queryClient.invalidateQueries("getProduct") }
  );
};

// Customer Business

export const useGetCustomerBusinesses = () => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  // todo types
  return useQuery(
    "getCustomerBusinesses" as const,
    async () => await apiClient.get(`/business`)
  );
};

export const useGetCustomerBusiness = ({
  businessId,
  enabled,
}: {
  businessId: string;
  enabled?: boolean;
}) => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  // todo types
  return useQuery(
    "getCustomerBusiness" as const,
    async () => await apiClient.get(`/business/${businessId}`),
    { enabled }
  );
};

// Customer product

export const useGetAllProducts = ({
  categories,
  page = 0,
  pageSize = 500,
  search,
}: {
  categories?: string;
  page?: number;
  pageSize?: number;
  search?: string;
}) => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useQuery(
    `getAllProducts-${categories}${search}`,
    async () =>
      await apiClient.get(`/products`, {
        params: { categories, page, pageSize, search },
      })
  );
};

export const useGetCustomerProducts = ({
  businessId,
  page = 0,
  pageSize = 500,
  search,
}: {
  businessId: string;
  page?: number;
  pageSize?: number;
  search?: string;
}) => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  // todo response type Product[]
  return useQuery(
    "getCustomerProducts",
    async () =>
      await apiClient.get(`/business/${businessId}/products`, {
        params: { page, pageSize, search },
      })
  );
};

export const useGetCustomerProduct = ({
  businessId,
  productId,
}: {
  businessId: string;
  productId: string;
}) => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useQuery(
    "getCustomerProduct",
    async () =>
      await apiClient.get(`/business/${businessId}/products/${productId}`)
  );
};

export const useGetProduct = ({ productId }: { productId: string }) => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useQuery(
    "getProduct",
    async () => await apiClient.get(`/products/${productId}`)
  );
};

// Cart

export const useGetCart = (options) => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useQuery<{ data: { items: CartItemDetail[] } }, any>(
    GET_CART,
    () => apiClient.get("/cart"),
    options
  );
};

export const useAddToCart = () => {
  const queryClient = useQueryClient();
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useMutation(
    "addToCart",
    ({ productId }: { productId: string }) =>
      apiClient.post("/cart", { productId }),
    { onSuccess: () => queryClient.invalidateQueries(GET_CART) }
  );
};

export const useUpdateCartItem = () => {
  const queryClient = useQueryClient();
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useMutation(
    "updateCartItem",
    ({ cartItemId, quantity }: { cartItemId: string; quantity: number }) =>
      apiClient.put(`cart/${cartItemId}`, { quantity }),
    { onSuccess: () => queryClient.invalidateQueries(GET_CART) }
  );
};

export const useDeleteCartItem = () => {
  const queryClient = useQueryClient();
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useMutation(
    "deleteCartItem",
    ({ cartItemId }: { cartItemId: string }) =>
      apiClient.delete(`cart/${cartItemId}`),
    { onSuccess: () => queryClient.invalidateQueries(GET_CART) }
  );
};

// Forgot Password

export const usePasswordRecovery = () => {
  const apiClient = useAxiosInstance("");

  return useMutation("passwordRecovery", ({ email }: PasswordRecoveryPayload) =>
    apiClient.post(`/password-recovery`, {
      email,
    })
  );
};

export const useChangePassword = () => {
  const apiClient = useAxiosInstance("");

  return useMutation(
    "changePassword",
    ({ password, token }: ChangePasswordPayload) =>
      apiClient.post(`/auth/change-password`, {
        password,
        token,
      })
  );
};

// Payments

export const useCreatePaymentIntent = (
  {
    price,
    paymentMethod,
  }: {
    price: number;
    paymentMethod: PaymentMethod;
  },
  options
) => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useQuery(
    "createPaymentIntent",
    () =>
      apiClient.get(`/payments/createPaymentIntent`, {
        params: { price, paymentMethod },
      }),
    options
  );
};

export const useGetStripePublicKey = () => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useQuery("createPaymentIntent", () =>
    apiClient.get(`/payments/publicKey`, {})
  );
};

// Customer Order

export const useGetCustomerOrders = (options) => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useQuery<any, any>(
    "getCustomerOrders",
    () => apiClient.get("/customerOrder"),
    options
  );
};

export const useCreateCustomerOrder = () => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useMutation(
    "createCustomerOrder",
    ({ products, price, deliveryMethods, paymentMethod }: CustomerOrder) =>
      apiClient.post("/customerOrder", {
        products,
        price,
        deliveryMethods,
        paymentMethod,
      })
  );
};

// Business Order

export const useGetBusinessOrders = (options) => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useQuery<any, any>(
    "getBusinessOrders",
    () => apiClient.get("/businessOrder"),
    options
  );
};

export const useCreateBusinessOrder = () => {
  const token = localStorage.getItem("token") || "";
  const apiClient = useAxiosInstance(token);

  return useMutation(
    "createBusinessOrder",
    ({
      products,
      businessId,
      price,
      deliveryMethod,
      paymentMethod,
      deliveryFirstName,
      deliveryLastName,
      deliveryEmail,
      deliveryPhone,
      deliveryStreetAndStreetNumber,
      deliveryCity,
      deliveryZipCode,
    }: {
      products: any[];
      businessId: string;
      price: number;
      deliveryMethod: string;
      paymentMethod: string;
      deliveryFirstName: string;
      deliveryLastName: string;
      deliveryEmail: string;
      deliveryPhone: string;
      deliveryStreetAndStreetNumber: string;
      deliveryCity: string;
      deliveryZipCode: string;
    }) =>
      apiClient.post("/businessOrder", {
        products,
        businessId,
        price,
        deliveryMethod,
        paymentMethod,
        deliveryFirstName,
        deliveryLastName,
        deliveryEmail,
        deliveryPhone,
        deliveryStreetAndStreetNumber,
        deliveryCity,
        deliveryZipCode,
      })
  );
};
