
import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { StyledSelect } from './StyledSelect';

type LanguageChooserProps = {
    onChange: (language) => void;
    lng: string;
};

export const LanguageChooser = ({ onChange, lng }: LanguageChooserProps) => {

    return (
        <Select
            labelId="lng-select-label"
            id="lng-select"
            value={lng}
            input={<StyledSelect />}
            onChange={($event) => onChange($event.target.value)}
            inputProps={{ sx: { width: 28 } }}
        >
            <MenuItem value={'cz'}>CZ</MenuItem>
            <MenuItem value={'en'}>EN</MenuItem>
        </Select>)
}