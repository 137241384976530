import { Stack } from '@mui/material';
import { Businesses } from './Business/Businesses';
import { DrawerListCategories } from './Drawer/DrawerHome/DrawerListCategories';
import { Header } from './TopBar/Header';

export const HomeBusinesses = () => {
  return (
    <Stack>
      <Header children={<DrawerListCategories />} />
      <Businesses />
    </Stack>
  );
};
