import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { useGetCustomerBusinesses } from '../../../apiClient/apiClient';
import { Business } from './Business';
import './Businesses.css';
import { ProductsSkeleton } from '../../../shared/components/UI/ProductsSkeleton';
import { useTranslation } from 'react-i18next';

export const Businesses = () => {
  const { t } = useTranslation('common');
  const { data, isSuccess, isLoading } = useGetCustomerBusinesses();

  const filteredBusinesses = data?.data;

  if (isLoading) {
    return (
      <Stack
        sx={{ alignItems: 'center', justifyContent: 'center', pt: 12, pb: 5 }}
      >
        <div className='masonry'>
          <ProductsSkeleton n={8} isMasonry />
        </div>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{ alignItems: 'center', justifyContent: 'center', pt: 10, pb: 5 }}
    >
      {isSuccess && filteredBusinesses?.length > 0 ? (
        <div className='masonry'>
          {filteredBusinesses?.map(({ id, name, description, images }) => (
            <Business
              key={id}
              id={id}
              name={name}
              description={description}
              images={images}
            />
          ))}
        </div>
      ) : (
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{ height: '100%', width: '100%' }}
        >
          <Typography variant='h4' color='primary.dark'>
            {t('customer.businesses.notFound')}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
