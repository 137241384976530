import { useRef } from "react";
import { BusinessDetailCustomer } from "../Business/BusinessDetailCustomer";
import { Header } from "../TopBar/Header";
import { Products } from "./Products";
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { InfoOutlined, ShoppingBagOutlined } from "@mui/icons-material";

export const ProductsPageCustomer = () => {
  const productsRef = useRef<HTMLElement>(null);
  const aboutUsRef = useRef<HTMLElement>(null);

  const handleAboutUsClick = () => {
    aboutUsRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleProductsClick = () => {
    productsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Header
        children={
          <List
            sx={{ width: "100%", maxWidth: 360, overflow: "hidden" }}
            component='nav'
          >
            <Stack spacing={1}>
              <ListItemButton
                onClick={handleAboutUsClick}
                sx={{
                  height: 25,
                  borderRadius: 2,
                  ":hover": {
                    backgroundColor: "#d4d5f235",
                  },
                  display: "flex",
                  pl: 2,
                }}
              >
                <Stack direction='row' spacing={1} alignItems='center'>
                  <ListItemIcon>
                    <InfoOutlined color='secondary' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText
                    primary='O nás'
                    sx={{ color: (theme) => theme.palette.secondary.main }}
                  />
                </Stack>
              </ListItemButton>
              <Divider
                sx={{
                  backgroundColor: (theme) => theme.palette.secondary.dark,
                }}
              />
              <ListItemButton
                onClick={handleProductsClick}
                sx={{
                  height: 25,
                  borderRadius: 2,
                  ":hover": {
                    backgroundColor: "#d4d5f235",
                  },
                  display: "flex",
                  pl: 2,
                }}
              >
                <Stack direction='row' spacing={1} alignItems='center'>
                  <ListItemIcon>
                    <ShoppingBagOutlined color='secondary' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText
                    primary='Produkty'
                    sx={{ color: (theme) => theme.palette.secondary.main }}
                  />
                </Stack>
              </ListItemButton>
            </Stack>
          </List>
        }
      />
      <span ref={aboutUsRef} style={{ scrollMarginTop: "80px" }}>
        <BusinessDetailCustomer />
      </span>
      <span ref={productsRef}>
        <Products />
      </span>
    </>
  );
};
