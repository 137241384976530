import { Grid, Skeleton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Outlet, useParams } from "react-router-dom";
import { useGetCustomerProducts } from "../../../apiClient/apiClient";
import { ProductCard, ProductCardProps } from "./ProductCard";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { NARROW_SCREEN } from "../../../shared/helpers/values";
import { useTranslation } from "react-i18next";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";

export const Products = () => {
  const { t } = useTranslation("common");
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetCustomerProducts({
    businessId: String(id),
  });

  const products: ProductCardProps[] = data?.data;

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  if (isLoading) {
    return (
      <Grid
        container
        sx={{ mt: 10, mb: 5, pl: isNarrow ? 5 : 30 }}
        spacing={2}
        justifyContent='center'
      >
        {[...Array(10)].map((e, i) => (
          <Grid item key={i}>
            <Skeleton
              variant='rounded'
              width={300}
              height={400}
              sx={{ mb: 2, borderRadius: "10px" }}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <>
      <div
        style={{
          overflow: "hidden",
        }}
      >
        <Stack>
          <Grid
            container
            sx={{ mt: 10, mb: 5, pl: isNarrow ? 2 : 32 }}
            spacing={2}
            justifyContent='center'
          >
            {products?.length > 0 ? (
              products.map((product) => (
                <StyledLink to={`product/${product.id}`} key={product.id}>
                  <Grid item>
                    <ProductCard {...product} />
                  </Grid>
                </StyledLink>
              ))
            ) : (
              <Stack
                alignItems='center'
                justifyContent='center'
                sx={{ height: "100%", width: "100%" }}
              >
                <Typography variant='h4' color='primary.dark'>
                  {t("customer.products.notFound")}
                </Typography>
              </Stack>
            )}
          </Grid>
        </Stack>
      </div>
      <Outlet />
    </>
  );
};
