import { Button, Paper, Stack, Typography } from '@mui/material';
import { Header } from '../../../customer/components/TopBar/Header';
import { Controller, useForm } from 'react-hook-form';
import { StyledTextField } from '../UI/StyledTextField';
import { useWindowSize } from '../../hooks/useWindowSize';
import {
  NARROW_SCREEN,
  requiredField,
  somethingWentWrong,
} from '../../helpers/values';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePasswordRecovery } from '../../../apiClient/apiClient';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { SnackbarMsg } from '../UI/SnackbarMsg';
import { useTranslation } from 'react-i18next';

export const PasswordRecovery = () => {
  const { t } = useTranslation('common');
  const [showMsg, setShowMsg] = useState<boolean>(false);
  const navigate = useNavigate();

  const RecoverPassSchema = () =>
    yup.object({
      email: yup.string().required(t(requiredField).toString()),
    });


  const { mutate: recoverPassword } = usePasswordRecovery();
  const { control, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(RecoverPassSchema()),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = ({ email }) => {
    recoverPassword(
      { email },
      {
        onSuccess: () => {
          navigate('/email-sent');
        },
        onError: () => setShowMsg(true),
      }
    );
  };

  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;
  return (
    <>
      <Header />
      <Stack
        alignItems='center'
        justifyContent='center'
        spacing={5}
        sx={{ m: isNarrow ? 0 : 5, mt: 12, mb: 5, overflowX: 'hidden' }}
      >
        <Paper
          variant='outlined'
          sx={{
            backgroundColor: 'secondary.light',
            borderRadius: '50px',
            borderWidth: '2px',
            borderColor: (theme) => theme.palette.primary.main,
            width: isNarrow ? '95%' : '60%',
            color: (theme) => theme.palette.primary.light,
          }}
        >
          <Stack
            alignItems='center'
            justifyContent='center'
            spacing={4}
            sx={{ m: 5 }}
          >
            <Typography
              variant='h4'
              sx={{
                color: (theme) => theme.palette.primary.dark,
                textAlign: 'center',
              }}
            >
              {t('shared.recovery.enterEmail')}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <Stack
                  direction='row'
                  spacing={3}
                  alignItems='center'
                  sx={{ justifyContent: 'space-between' }}
                >
                  <Typography
                    variant='h6'
                    color='text.secondary'
                    sx={{ flex: 1, textAlign: 'end' }}
                  >
                    {t('shared.email')}:
                  </Typography>
                  <Controller
                    control={control}
                    name='email'
                    render={({ field, fieldState, formState }) => (
                      <StyledTextField
                        {...field}
                        variant='outlined'
                        size='small'
                        error={!!fieldState.error}
                        helperText={
                          !!fieldState.error
                            ? fieldState.error.message
                            : undefined
                        }
                        inputProps={{
                          sx: {
                            color: (theme) => theme.palette.text.primary,
                            backgroundColor: 'transparent !important',
                            width: isNarrow ? '200px' : '300px',
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            color: (theme) => theme.palette.primary.main,
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
                <Button
                  variant='contained'
                  type='submit'
                  sx={{ borderRadius: '50px', color: 'secondary.light' }}
                >
                  {t('shared.send')}
                </Button>
              </Stack>
            </form>
          </Stack>
        </Paper>
      </Stack>
      <SnackbarMsg
        open={showMsg}
        handleClose={() => setShowMsg(false)}
        severity='error'
        message={t(somethingWentWrong)}
      />
    </>
  );
};
