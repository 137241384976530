import { styled, InputBase } from '@mui/material';

export const StyledSelect = styled(InputBase)({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #e1994b',
    fontSize: 16,
    padding: '6px',
    '&:hover': {
      borderColor: '#e9b782',
    },
  },
});
