import { Card, CardContent, Skeleton, Stack, Typography } from "@mui/material";
import { Header } from "../TopBar/Header";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { NARROW_SCREEN } from "../../../shared/helpers/values";

import {
  useCreatePaymentIntent,
  useGetCustomerOrders,
} from "../../../apiClient/apiClient";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CheckoutForm } from "./CheckoutForm";
import { DrawerListCategories } from "../Drawer/DrawerHome/DrawerListCategories";
import { useTranslation } from "react-i18next";

const stripePromise = loadStripe(
  String(process.env.REACT_APP_STRIPE_PUBLIC_TEST)
);

export const Payment = () => {
  const windowSize = useWindowSize();
  const { t } = useTranslation("common");
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;
  const { data: customerOrders, isLoading } = useGetCustomerOrders({});
  const price = customerOrders?.data?.[0]?.price;
  const paymentMethod = customerOrders?.data?.[0]?.paymentMethod;
  const { data: paymentData } = useCreatePaymentIntent(
    { price, paymentMethod },
    { enabled: customerOrders?.data?.[0]?.status === "STARTED" }
  );
  const clientSecret = paymentData?.data.clientSecret;
  // todo - getcustomerorder a porovnaj cenu ci sedi - potom elements zobrazis pod podmienkou

  return (
    <>
      <Header children={<DrawerListCategories />} />
      <Stack
        alignItems={isNarrow ? "center" : "start"}
        justifyContent='center'
        sx={{ m: isNarrow ? 1 : 5, ml: isNarrow ? 1 : 35, mt: 10 }}
        direction='row'
      >
        <Card
          variant='outlined'
          sx={{
            backgroundColor: (theme) => theme.palette.secondary.light,
            borderRadius: "50px",
            borderWidth: "2px",
            borderColor: (theme) => theme.palette.primary.main,
            width: "100%",
            color: (theme) => theme.palette.primary.light,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack alignItems='center' justifyContent='center'>
            {isLoading ? (
              <Skeleton
                variant='rounded'
                width={300}
                height={300}
                sx={{ my: 2, borderRadius: "10px" }}
              />
            ) : (
              <CardContent sx={{ pt: 6, mx: isNarrow ? 0 : 5, mb: 2 }}>
                <Stack spacing={5} justifyContent='center' alignItems='center'>
                  <Typography
                    variant='h4'
                    color='text.secondary'
                    fontWeight={500}
                  >
                    {t("customer.cart.fillInfo")}
                  </Typography>
                  {stripePromise && clientSecret && (
                    <Elements
                      stripe={stripePromise}
                      options={{
                        clientSecret,
                      }}
                    >
                      <CheckoutForm price={price} />
                    </Elements>
                  )}
                </Stack>
              </CardContent>
            )}
          </Stack>
        </Card>
      </Stack>
    </>
  );
};
