export const requiredField = "shared.settings.requiredField";

export const requiredImage = "shared.settings.requiredImage";

export const invalidPhone = "shared.settings.invalidPhone";

export const invalidEmail = "shared.settings.invalidEmail";

export const NARROW_SCREEN = 1000;

export const WIDE_SCREEN = 999;

export const dataSaved = "shared.dataSaved";

export const somethingWentWrong = "shared.errorMessage";

export const addedToCart = "shared.addedToCart";

export const removedFromCart = "shared.removedToCart";

export const welcomeMsg = "shared.registration.successfulRegistration";

export const userExists = "shared.registration.userExistMessage";

export const invalidCredentials = "shared.login.invalidCredentials";

export const pleaseConfirm = "shared.pleaseConfirm";
