import { Stack } from '@mui/material';
import { DrawerListCategories } from './Drawer/DrawerHome/DrawerListCategories';
import { Header } from './TopBar/Header';
import { AllProducts } from './Product/AllProducts';

export const HomeProducts = ({ categories }: { categories?: string }) => {
  return (
    <Stack>
      <Header children={<DrawerListCategories />} />
      <AllProducts categories={categories} />
    </Stack>
  );
};
