import { Button, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { dataSaved, somethingWentWrong } from "../../../shared/helpers/values";
import { useState } from "react";
import { SnackbarMsg } from "../../../shared/components/UI/SnackbarMsg";
import { useTranslation } from "react-i18next";
import { LanguageChooser } from "../../../shared/components/UI/LanguageChooser";

export const SettingsCustomerLanguageForm = () => {
  const { t, i18n } = useTranslation("common");
  const [showMsg, setShowMsg] = useState<{
    successMsg: boolean;
    errorMsg: boolean;
  }>({ successMsg: false, errorMsg: false });

  const [lng, setLng] = useState(localStorage.getItem("lng") || "cz");

  const { handleSubmit } = useForm({
    mode: "onBlur",
  });

  const onSubmit = () => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
    setShowMsg({ successMsg: true, errorMsg: false });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Stack direction='row' spacing={3} alignItems='center'>
            <Typography
              variant='h6'
              color='text.secondary'
              sx={{ flex: 1, textAlign: "end" }}
            >
              {t("shared.language")}:
            </Typography>
            <LanguageChooser onChange={(value) => setLng(value)} lng={lng} />
          </Stack>
          <Button
            type='submit'
            variant='contained'
            sx={{ alignSelf: "center" }}
          >
            {t("shared.save")}
          </Button>
        </Stack>
      </form>
      <SnackbarMsg
        open={showMsg.successMsg}
        handleClose={() => setShowMsg({ successMsg: false, errorMsg: false })}
        severity='success'
        message={t(dataSaved)}
      />
      <SnackbarMsg
        open={showMsg.errorMsg}
        handleClose={() => setShowMsg({ successMsg: false, errorMsg: false })}
        severity='error'
        message={t(somethingWentWrong)}
      />
    </>
  );
};
