import { Button, Card, CardContent, Stack } from "@mui/material";
import { Header } from "../../../customer/components/TopBar/Header";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { NARROW_SCREEN } from "../../../shared/helpers/values";
import { useTranslation } from "react-i18next";
import { SettingsAdminDrawer } from "./SettingsAdminDrawer";
import { useRef } from "react";
import { SettingsTermsAndConditions } from "../../../shared/components/Settings/SettingsTermsAndConditions";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";
import { SettingsDeleteAccount } from "../../../shared/components/Settings/SettingsDeleteAccount";

export const SettingsAdmin = () => {
  const { t } = useTranslation("common");
  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  const infoRef = useRef<HTMLDivElement>(null);
  const conditionsRef = useRef<HTMLDivElement>(null);
  const deleteAccount = useRef<HTMLDivElement>(null);

  const handleRefClick = (name: string) => {
    if (name === "info") {
      infoRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (name === "conditions") {
      conditionsRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (name === "deleteAccount") {
      deleteAccount.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Header
        children={
          <SettingsAdminDrawer onClick={(name) => handleRefClick(name)} />
        }
      />
      <Stack
        alignItems='start'
        sx={{ m: isNarrow ? 0 : 5, ml: isNarrow ? 0 : 35, mt: 10 }}
        direction='column'
        spacing={4}
      >
        <div ref={infoRef}>
          <Card
            variant='outlined'
            sx={{
              backgroundColor: (theme) => theme.palette.secondary.light,
              borderRadius: "50px",
              borderWidth: "2px",
              borderColor: (theme) => theme.palette.primary.main,
              width: "100%",
              color: (theme) => theme.palette.primary.light,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack alignItems='center' justifyContent='center'>
              <CardContent sx={{ pt: 6, mx: isNarrow ? 0 : 5, mb: 2 }}>
                <StyledLink to='/app'>
                  <Button
                    variant='outlined'
                    size='large'
                    sx={{ borderRadius: "10px" }}
                  >
                    {t("shared.registration.buy")}
                  </Button>
                </StyledLink>
              </CardContent>
            </Stack>
          </Card>
        </div>
        <div style={{ width: "100%" }} ref={conditionsRef}>
          <SettingsTermsAndConditions />
        </div>
        <div style={{ width: "100%" }} ref={deleteAccount}>
          <SettingsDeleteAccount />
        </div>
      </Stack>
    </>
  );
};
