import React from "react";
import { CardContent, CardMedia, Stack, Typography } from "@mui/material";
import { StyledCard } from "../../../shared/components/UI/StyledCard";
import { StyledCardActionArea } from "../../../shared/components/UI/StyledCardActionArea";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";

type Props = {
  id: string;
  name: string;
  description: string;
  images?: string[];
};

export const BusinessAdmin = ({ id, name, description, images }: Props) => {
  return (
    <StyledCard>
      <StyledCardActionArea disableRipple sx={{ p: 2, height: "100%" }}>
        <StyledLink to={`/admin/business/${id}`}>
          <CardMedia
            component='img'
            image={images?.[0]}
            alt='alt'
            sx={{ borderRadius: 2 }}
          />
          <CardContent sx={{ height: "100%" }}>
            <Stack spacing={1}>
              <Typography variant='h4' color='primary.dark'>
                {name}
              </Typography>
              <Typography variant='body2' color='primary'>
                {description}
              </Typography>
            </Stack>
          </CardContent>
        </StyledLink>
      </StyledCardActionArea>
    </StyledCard>
  );
};
