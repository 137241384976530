import { Card, CardContent, Stack } from "@mui/material";
import { Header } from "../TopBar/Header";
import { SettingsCustomerDrawer } from "./SettingsCustomerDrawer";
import { SettingsDeliveryDetailsForm } from "./SettingsDeliveryDetailsForm";
import { useWindowSize } from "../../../shared/hooks/useWindowSize";
import { NARROW_SCREEN } from "../../../shared/helpers/values";
import { SettingsCustomerLanguageForm } from "./SettingsCustomerLanguageForm";
import { useRef } from "react";
import { SettingsTermsAndConditions } from "../../../shared/components/Settings/SettingsTermsAndConditions";
import { SettingsDeleteAccount } from "../../../shared/components/Settings/SettingsDeleteAccount";

export const SettingsCustomer = () => {
  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < NARROW_SCREEN;

  const deliveryRef = useRef<HTMLDivElement>(null);
  const languageRef = useRef<HTMLDivElement>(null);
  const conditionsRef = useRef<HTMLDivElement>(null);
  const deleteAccount = useRef<HTMLDivElement>(null);

  const handleRefClick = (name: string) => {
    if (name === "delivery") {
      deliveryRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (name === "language") {
      languageRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (name === "conditions") {
      conditionsRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (name === "deleteAccount") {
      deleteAccount.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Header
        children={
          <SettingsCustomerDrawer onClick={(name) => handleRefClick(name)} />
        }
      />
      <Stack
        alignItems='start'
        sx={{ m: isNarrow ? 0 : 5, ml: isNarrow ? 0 : 35, mt: 10 }}
        direction='column'
        spacing={4}
      >
        <div
          style={{ scrollMarginTop: "80px", width: "100%" }}
          ref={deliveryRef}
        >
          <Card
            variant='outlined'
            sx={{
              backgroundColor: (theme) => theme.palette.secondary.light,
              borderRadius: "50px",
              borderWidth: "2px",
              borderColor: (theme) => theme.palette.primary.main,
              width: "100%",
              color: (theme) => theme.palette.primary.light,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack alignItems='center' justifyContent='center' sx={{ mb: 5 }}>
              <CardContent
                sx={{
                  pt: 6,
                  mx: 5,
                }}
              >
                <Stack spacing={3}>
                  <SettingsDeliveryDetailsForm />
                </Stack>
              </CardContent>
            </Stack>
          </Card>
        </div>
        <div style={{ width: "100%" }} ref={languageRef}>
          <Card
            variant='outlined'
            sx={{
              backgroundColor: (theme) => theme.palette.secondary.light,
              borderRadius: "50px",
              borderWidth: "2px",
              borderColor: (theme) => theme.palette.primary.main,
              width: "100%",
              color: (theme) => theme.palette.primary.light,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack alignItems='center' justifyContent='center' sx={{ mb: 5 }}>
              <CardContent
                sx={{
                  pt: 6,
                  mx: 5,
                }}
              >
                <Stack spacing={3}>
                  <SettingsCustomerLanguageForm />
                </Stack>
              </CardContent>
            </Stack>
          </Card>
        </div>
        <div style={{ width: "100%" }} ref={conditionsRef}>
          <SettingsTermsAndConditions isBuyer />
        </div>
        <div style={{ width: "100%" }} ref={deleteAccount}>
          <SettingsDeleteAccount isBuyer />
        </div>
      </Stack>
    </>
  );
};
