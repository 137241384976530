import {
  Button,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { StyledCard } from "../../../shared/components/UI/StyledCard";
import { StyledCardActionArea } from "../../../shared/components/UI/StyledCardActionArea";
import { useTranslation } from "react-i18next";
import { StyledLink } from "../../../shared/components/StyledLink/StyledLink";

export type ProductCardProps = {
  id: string;
  name: string;
  description: string;
  images?: string[];
  price: number;
  inStock: number;
};

export const ProductCardAdmin = ({
  id: productId,
  name,
  description,
  images,
  price,
  inStock,
}: ProductCardProps) => {
  const { t } = useTranslation("common");
  const { id } = useParams();

  return (
    <StyledCard>
      <StyledCardActionArea disableRipple sx={{ p: 2 }}>
        <StyledLink to={`/admin/business/${id}/products/${productId}/edit`}>
          <CardMedia
            // todo - map images
            image={images?.[0]}
            component='img'
            alt='alt'
            height={180}
            sx={{ borderRadius: 2 }}
          />
          <CardContent>
            <Stack spacing={1} alignItems='center'>
              <Stack spacing={0.5} display='grid'>
                <Typography variant='h6' color='text.primary'>
                  {name}
                </Typography>
                <Typography
                  variant='body1'
                  color='text.secondary'
                  sx={{ fontWeight: 500 }}
                >
                  {price} {t("shared.currencyCZ")}
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 500 }}
                  color='text.secondary'
                >
                  {t("shared.inStock")}: {inStock} {t("shared.numberOfPieces")}
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{
                    height: 80,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "normal",
                    display: "-webkit-box !important",
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {description}
                </Typography>
              </Stack>
              <Button variant='contained' sx={{ mt: 1 }}>
                {t("shared.edit")}
              </Button>
            </Stack>
          </CardContent>
        </StyledLink>
      </StyledCardActionArea>
    </StyledCard>
  );
};
