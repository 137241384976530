import {
  Avatar,
  Box,
  Dialog,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { QuantityInput } from "../UI/QuantityInput";
import { DeleteForever, LocalShippingOutlined } from "@mui/icons-material";
import { useAuth } from "../../context/AuthContext";
import {
  useDeleteCartItem,
  useGetCart,
  useUpdateCartItem,
} from "../../../apiClient/apiClient";
import { useState } from "react";
import { roundToTwoDecimals } from "../../../shared/helpers/helpers";
import { DeleteDialog } from "../../../shared/components/UI/DeleteDialog";
import { SnackbarMsg } from "../../../shared/components/UI/SnackbarMsg";
import {
  removedFromCart,
  somethingWentWrong,
} from "../../../shared/helpers/values";
import { useTranslation } from "react-i18next";
import { CartItemDetail } from "../../../types/types";

export const CartStepOverview = () => {
  const { t } = useTranslation("common");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [cartItemId, setCartItemId] = useState<string | null>(null);

  const [showMsg, setShowMsg] = useState<{
    errorMsg: boolean;
    removeFromCartMsg: boolean;
  }>({ errorMsg: false, removeFromCartMsg: false });

  const auth = useAuth();

  const { data: cartData, isLoading: isLoadingCart } = useGetCart({
    enabled: !!auth?.user,
  });

  const { mutate: deleteCartItem, isLoading: isDeleting } = useDeleteCartItem();
  const cart = cartData?.data?.items;

  const handleDelete = ({ cartItemId }) => {
    deleteCartItem(
      { cartItemId: cartItemId },
      {
        onSuccess: () => {
          setOpenDialog(false);
          setShowMsg({
            errorMsg: false,
            removeFromCartMsg: true,
          });
        },
        onError: () =>
          setShowMsg({
            errorMsg: true,
            removeFromCartMsg: false,
          }),
      }
    );
  };

  const pricesWithQuantities = cart?.map(
    (item) => item.product.price * item.quantity
  );

  const pricesTotal = pricesWithQuantities?.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue;
    },
    0
  );

  const { mutate: updateCartItem, isLoading: isLoadingCartItem } =
    useUpdateCartItem();

  const onLessClick = ({ cartItemId, quantity }) => {
    if (quantity - 1 === 0) {
      setCartItemId(cartItemId);
      setOpenDialog(true);
      return;
    }
    updateCartItem({
      cartItemId,
      quantity: quantity - 1,
    });
  };

  const onMoreClick = ({ cartItemId, quantity }) => {
    updateCartItem({
      cartItemId,
      quantity: quantity + 1,
    });
  };

  const groupedCartObj = cart?.reduce((prev, current) => {
    return {
      ...prev,
      [current.product.businessId]: [
        ...(prev[current.product.businessId] || []),
        current,
      ],
    };
  }, {});

  const groupedCart: CartItemDetail[][] = Object.values(groupedCartObj ?? []);

  if (isLoadingCart) {
    // todo - style skeleton
    return <Skeleton />;
  }

  return (
    <Stack
      spacing={2}
      justifyContent='center'
      alignItems='center'
      sx={{ pt: 5 }}
    >
      <Typography
        variant='h4'
        color='primary.dark'
        sx={{ textAlign: "center" }}
      >
        {t("customer.cart.cartContent")}
      </Typography>
      <Stack sx={{ width: "100%" }}>
        {groupedCart?.map((groupedItem, index) => (
          <Stack key={groupedItem[index]?.id} spacing={2} sx={{ my: 2 }}>
            <Stack direction='row' spacing={1}>
              <Typography variant='h5' color='text.secondary'>
                {t("customer.cart.supplier")}:
              </Typography>
              <Typography
                variant='h5'
                color='text.secondary'
                sx={{ fontWeight: 500 }}
              >
                {groupedItem[0].product.business.name}
              </Typography>
            </Stack>
            <Stack>
              <Divider />
              {groupedItem?.map((item) => (
                <Box key={item.id}>
                  <Stack
                    direction='row'
                    sx={{ py: 2, px: 2 }}
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Stack
                      direction='row'
                      spacing={1}
                      alignItems='center'
                      sx={{
                        overflow: "hidden",
                        mr: 3,
                      }}
                    >
                      <Avatar
                        src={item.product?.images?.[0]}
                        sx={{ width: 56, height: 56 }}
                      />
                      <Stack>
                        <Typography
                          variant='h6'
                          color='primary.dark'
                          sx={{
                            maxWidth: "186px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item?.product?.name}
                        </Typography>
                        <Typography
                          variant='subtitle2'
                          color='text.secondary'
                          sx={{
                            maxWidth: "186px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {roundToTwoDecimals(
                            item?.quantity * item?.product?.price
                          )}{" "}
                          {t("shared.currencyCZ")}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Stack alignItems='center' spacing={1}>
                        <QuantityInput
                          cartItemQuantity={item?.quantity}
                          isLoading={isLoadingCart || isLoadingCartItem}
                          onLessClick={() =>
                            onLessClick({
                              cartItemId: item?.id,
                              quantity: item?.quantity,
                            })
                          }
                          onMoreClick={() =>
                            onMoreClick({
                              cartItemId: item?.id,
                              quantity: item?.quantity,
                            })
                          }
                        />
                      </Stack>
                      <IconButton
                        onClick={() => {
                          setCartItemId(item?.id);
                          setOpenDialog(true);
                        }}
                      >
                        <DeleteForever />
                      </IconButton>
                    </Stack>
                  </Stack>
                  <Divider />
                </Box>
              ))}
            </Stack>
          </Stack>
        ))}
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ pl: 2, pr: 3.5, mt: 2 }}
        >
          <Typography
            variant='h5'
            color='text.secondary'
            sx={{ fontWeight: 500 }}
          >
            {t("shared.inTotal")}:
          </Typography>
          <Typography variant='h5' color='text.secondary'>
            {roundToTwoDecimals(pricesTotal ?? 0)} Kč
          </Typography>
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='end'
          spacing={1}
          sx={{ pl: 2, pr: 3.5, my: 1, mb: 2 }}
        >
          <LocalShippingOutlined
            fontSize='small'
            sx={{ color: (theme) => theme.palette.primary.light }}
          />
          <Typography variant='body1' color='primary.light'>
            + {t("shared.delivery")} {t("shared.from")} 59{" "}
            {t("shared.currencyCZ")} * {groupedCart?.length} ={" "}
            {groupedCart?.length * 59} {t("shared.currencyCZ")}
          </Typography>
        </Stack>
        <Divider />
      </Stack>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: {
            width: "430px",
            position: "fixed",
            backgroundColor: (theme) => theme.palette.secondary.light,
          },
          elevation: 2,
        }}
      >
        <DeleteDialog
          onClose={() => setOpenDialog(false)}
          onDelete={() => {
            handleDelete({ cartItemId: cartItemId });
          }}
          isLoading={isDeleting}
          textTitle={t("customer.cart.deleteFromCart")}
          textBody={t("customer.cart.deleteFromCartConfirmation")}
        />
      </Dialog>
      <SnackbarMsg
        open={showMsg.removeFromCartMsg}
        handleClose={() =>
          setShowMsg({
            errorMsg: false,
            removeFromCartMsg: false,
          })
        }
        message={t(removedFromCart)}
        severity='success'
      />
      <SnackbarMsg
        open={showMsg.errorMsg}
        handleClose={() =>
          setShowMsg({
            errorMsg: false,
            removeFromCartMsg: false,
          })
        }
        message={t(somethingWentWrong)}
        severity='error'
      />
    </Stack>
  );
};
