import React, { useState } from "react";
import {
  AccountCircle,
  HowToReg,
  Login,
  Logout,
  Settings,
} from "@mui/icons-material";
import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

export const AvatarMenu = () => {
  const { t } = useTranslation("common");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const user = useAuth();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLoginClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate("/login");
    setAnchorEl(null);
  };

  const handleRegistrationClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate("/registration");
    setAnchorEl(null);
  };

  const handleLogoutClick = (e: React.MouseEvent) => {
    localStorage.removeItem("token");
    user?.setUser(null);
    navigate("/login");
    setAnchorEl(null);
  };

  const handleSettingsClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate("/settings");
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size='large'
        aria-label='account of current user'
        aria-controls='avatar-menu'
        aria-haspopup='true'
        onClick={handleMenu}
        color='inherit'
      >
        <AccountCircle fontSize='large' color='secondary' />
      </IconButton>
      <Menu
        id='avatar-menu'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          mt: 6,
          ml: -2,
        }}
        PaperProps={{
          sx: {
            backgroundColor: (theme) => theme.palette.secondary.light,
          },
        }}
      >
        {user?.user ? (
          <span>
            <Stack spacing={1}>
              {user?.user?.email && (
                <>
                  <MenuItem onClick={handleSettingsClick}>
                    <Stack direction='row' spacing={1}>
                      <AccountCircle
                        sx={{
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      />
                      <Typography color='text.secondary'>
                        {user?.user?.email}
                      </Typography>
                    </Stack>
                  </MenuItem>
                </>
              )}
              <MenuItem onClick={handleSettingsClick}>
                <Stack direction='row' spacing={1} justifyContent='start'>
                  <Settings
                    sx={{ color: (theme) => theme.palette.text.secondary }}
                  />
                  <Typography color='text.secondary' variant='body1'>
                    {t("shared.settings.settings")}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem onClick={handleLogoutClick}>
                <Stack direction='row' spacing={1} justifyContent='start'>
                  <Logout
                    sx={{ color: (theme) => theme.palette.text.secondary }}
                  />
                  <Typography color='text.secondary' variant='body1'>
                    {t("shared.settings.logout")}
                  </Typography>
                </Stack>
              </MenuItem>
            </Stack>
          </span>
        ) : (
          <span>
            <MenuItem onClick={handleLoginClick}>
              <Stack direction='row' spacing={1} justifyContent='start'>
                <Login
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                />
                <Typography color='text.secondary' variant='body1'>
                  {t("shared.settings.login")}
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem onClick={handleRegistrationClick}>
              <Stack direction='row' spacing={1} justifyContent='start'>
                <HowToReg
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                />
                <Typography color='text.secondary' variant='body1'>
                  {t("shared.settings.registration")}
                </Typography>
              </Stack>
            </MenuItem>
          </span>
        )}
      </Menu>
    </>
  );
};
