import {
  Assignment,
  InfoOutlined,
  PersonOffOutlined,
} from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type SettingsAdminDrawerProps = {
  onClick: (name: string) => void;
};

export const SettingsAdminDrawer = ({ onClick }: SettingsAdminDrawerProps) => {
  const { t } = useTranslation("common");

  return (
    <>
      <List sx={{ width: "100%", maxWidth: 360, pt: 0 }} component='nav'>
        <Stack spacing={1}>
          <Link
            to={`/settings`}
            onClick={() => onClick("info")}
            style={{ textDecoration: "none" }}
          >
            <ListItemButton
              sx={{
                height: 25,
                borderRadius: 2,
                ":hover": {
                  backgroundColor: "#d4d5f235",
                },
                display: "flex",
                pl: 2,
              }}
            >
              <Stack direction='row' alignItems='center'>
                <ListItemIcon style={{ minWidth: "40px" }}>
                  <InfoOutlined color='secondary' fontSize='small' />
                </ListItemIcon>
                <ListItemText
                  primary={t("shared.settings.personalData")}
                  primaryTypographyProps={{ variant: "body2" }}
                  sx={{ color: (theme) => theme.palette.secondary.main }}
                />
              </Stack>
            </ListItemButton>
          </Link>
          <Link
            to={`/settings`}
            onClick={() => onClick("conditions")}
            style={{ textDecoration: "none" }}
          >
            <ListItemButton
              sx={{
                height: 25,
                borderRadius: 2,
                ":hover": {
                  backgroundColor: "#d4d5f235",
                },
                display: "flex",
                pl: 2,
              }}
            >
              <Stack
                direction='row'
                alignItems='center'
                sx={{ whiteSpace: "nowrap" }}
              >
                <ListItemIcon style={{ minWidth: "40px" }}>
                  <Assignment color='secondary' fontSize='small' />
                </ListItemIcon>
                <ListItemText
                  primary={t("shared.conditions")}
                  primaryTypographyProps={{ variant: "body2" }}
                  sx={{ color: (theme) => theme.palette.secondary.main }}
                />
              </Stack>
            </ListItemButton>
          </Link>
          <Link
            to={`/settings`}
            onClick={() => onClick("deleteAccount")}
            style={{ textDecoration: "none" }}
          >
            <ListItemButton
              sx={{
                height: 25,
                borderRadius: 2,
                ":hover": {
                  backgroundColor: "#d4d5f235",
                },
                display: "flex",
                pl: 2,
              }}
            >
              <Stack direction='row' alignItems='center'>
                <ListItemIcon style={{ minWidth: "40px" }}>
                  <PersonOffOutlined color='secondary' fontSize='small' />
                </ListItemIcon>
                <ListItemText
                  primary={t("shared.deleteAccount")}
                  primaryTypographyProps={{ variant: "body2" }}
                  sx={{ color: (theme) => theme.palette.secondary.main }}
                />
              </Stack>
            </ListItemButton>
          </Link>
        </Stack>
      </List>
    </>
  );
};
