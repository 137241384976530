import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDeliveryDetailsContext } from "../../context/DeliveryDetailsContext";
import { useGetCart } from "../../../apiClient/apiClient";
import { CartItemDetail, PaymentMethod } from "../../../types/types";

type CartStepDeliveryAndPaymentProps = {
  handleNext: () => void;
};

// TODO - will be available on Business api
const deliveryOptions = ["PPL", "DPD", "Zásilkovna"];

export const CartStepDeliveryAndPayment = ({
  handleNext,
}: CartStepDeliveryAndPaymentProps) => {
  const { t } = useTranslation("common");
  const { setFormValues } = useDeliveryDetailsContext();
  const { data: cartData } = useGetCart({});
  const cart = cartData?.data?.items;

  const paymentOptions = [
    PaymentMethod.CARD,
    PaymentMethod.APPLE_PAY,
    PaymentMethod.GOOGLE_PAY,
  ];

  const DeliveryAndPaymentSchema = (arr: CartItemDetail[][]) => {
    const dynamicFields = arr.reduce((fields, value, index) => {
      return {
        ...fields,
        [`${value[0].product.business.id}`]: yup
          .string()
          .required(`${t("customer.cart.chooseDelivery")}`),
      };
    }, {});

    return yup.object({
      ...dynamicFields,
      paymentMethod: yup
        .string()
        .required(`${t("customer.cart.choosePayment")}`),
    });
  };

  const groupedCartObj = cart?.reduce((prev, current) => {
    return {
      ...prev,
      [current.product.businessId]: [
        ...(prev[current.product.businessId] || []),
        current,
      ],
    };
  }, {});

  const groupedCart: CartItemDetail[][] = Object.values(groupedCartObj ?? []);

  const { control, handleSubmit } = useForm({
    mode: "onBlur",
    resolver: yupResolver(DeliveryAndPaymentSchema(groupedCart)),
  });

  const onSubmit = (data: Record<string, string>) => {
    const arr = Object.keys(data)
      .filter((key) => key !== "paymentMethod")
      .map((key) => ({ businessId: key, value: data[key] }));
    setFormValues({
      deliveryDetails: arr,
      paymentMethod: data.paymentMethod as PaymentMethod,
    });
    handleNext();
  };

  return (
    <div style={{ position: "relative" }}>
      <form id='delivery-and-payment-form' onSubmit={handleSubmit(onSubmit)}>
        <Stack
          spacing={2}
          justifyContent='center'
          alignItems='center'
          sx={{ pt: 5 }}
        >
          <Typography
            variant='h4'
            color='primary.dark'
            sx={{ textAlign: "center" }}
          >
            {t("customer.cart.deliveryAndPayment")}
          </Typography>
          <Paper
            variant='outlined'
            sx={{
              backgroundColor: "transparent",
              borderRadius: "50px",
              borderWidth: "2px",
              borderColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.light,
              width: "90%",
            }}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              spacing={5}
              sx={{ m: 5 }}
            >
              {groupedCart?.map((d, i) => (
                <>
                  <Stack spacing={3} alignItems='center'>
                    <Typography
                      variant='h6'
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                        textAlign: "center",
                      }}
                    >
                      {t("customer.cart.chooseDelivery")}:{" "}
                      {d[0].product.business.name}
                    </Typography>
                    <FormGroup>
                      <Stack
                        direction='row'
                        spacing={3}
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Controller
                          control={control}
                          name={`${d[0].product.business.id}`}
                          render={({ field, fieldState, formState }) => (
                            <FormControl component='fieldset' hiddenLabel>
                              <RadioGroup
                                name={`${d[0].product.business.id}`}
                                value={field.value}
                                onChange={(_, val) => field.onChange(val)}
                              >
                                {deliveryOptions.map((option) => (
                                  <FormControlLabel
                                    disabled={formState.isSubmitting}
                                    key={option}
                                    value={option}
                                    control={<Radio size='small' />}
                                    componentsProps={{
                                      typography: {
                                        variant: "body2",
                                      },
                                    }}
                                    label={option}
                                    labelPlacement='end'
                                  />
                                ))}
                              </RadioGroup>
                              {fieldState.error && (
                                <FormHelperText error sx={{ mt: 0 }}>
                                  {fieldState.error?.message}
                                </FormHelperText>
                              )}
                            </FormControl>
                          )}
                        />
                      </Stack>
                    </FormGroup>
                  </Stack>
                  <Divider
                    sx={{
                      pt: 2,
                      borderColor: (theme) => theme.palette.primary.main,
                      width: "100%",
                    }}
                  />
                </>
              ))}
              <Stack spacing={3} alignItems='center'>
                <Typography
                  variant='h6'
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    textAlign: "center",
                  }}
                >
                  {t("customer.cart.choosePayment")}
                </Typography>
                <FormGroup>
                  <Stack
                    direction='row'
                    spacing={3}
                    // alignItems='center'
                    // justifyContent='center'
                  >
                    <Controller
                      control={control}
                      name='paymentMethod'
                      render={({ field, fieldState, formState }) => {
                        return (
                          <FormControl component='fieldset' hiddenLabel>
                            <RadioGroup
                              name='paymentMethod'
                              value={field.value}
                              onChange={(_, val) => field.onChange(val)}
                            >
                              {paymentOptions.map((option) => (
                                <FormControlLabel
                                  disabled={formState.isSubmitting}
                                  key={option}
                                  value={option}
                                  control={<Radio size='small' />}
                                  componentsProps={{
                                    typography: {
                                      variant: "body2",
                                    },
                                  }}
                                  label={t(`customer.cart.payment_${option}`)}
                                  labelPlacement='end'
                                />
                              ))}
                            </RadioGroup>
                            {fieldState.error && (
                              <FormHelperText error sx={{ mt: 0 }}>
                                {fieldState.error?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    />
                  </Stack>
                </FormGroup>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </form>
      <div style={{ position: "absolute", bottom: -60, right: 0 }}>
        <Button
          type='submit'
          form='delivery-and-payment-form'
          variant='contained'
          sx={{ borderRadius: "50px", color: "secondary.light" }}
        >
          {t("shared.proceed")}
        </Button>
      </div>
    </div>
  );
};
