import { Header } from '../TopBar/Header';
import { DrawerListCategories } from '../Drawer/DrawerHome/DrawerListCategories';
import { CartSteps } from './CartSteps';
import { Stack } from '@mui/material';

export const CartCheckout = () => {
  return (
    <Stack>
      <Header children={<DrawerListCategories />} />
      <CartSteps />
    </Stack>
  );
};
