import { ShoppingCart } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import { useGetCart } from '../../../apiClient/apiClient';
import { useCartContext } from '../../context/CartContext';
import { useAuth } from '../../context/AuthContext';

export const ShoppingCartMenu = () => {
  const { setShowCart, showCart } = useCartContext();
  const auth = useAuth();
  const { data } = useGetCart({ enabled: !!auth?.user });

  const cart = data?.data?.items;

  return (
    <IconButton
      size='large'
      aria-label='account of current user'
      aria-controls='shopping-cart'
      aria-haspopup='true'
      color='inherit'
      onClick={() => setShowCart(!showCart)}
    >
      <Badge badgeContent={cart && cart.length} color='secondary'>
        <ShoppingCart color='secondary' fontSize='medium' />
      </Badge>
    </IconButton>
  );
};
