import { ReactNode } from "react";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_cz from "../../translations/cz/common.json";
import common_en from "../../translations/en/common.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("lng") || "cz",
  resources: {
    en: {
      common: common_en,
    },
    cz: {
      common: common_cz,
    },
  },
});

export const TranslationProvider = ({ children }: { children: ReactNode }) => {
  return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>;
};
