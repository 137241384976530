import { Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import landingImg from "./LandingPageImages/landingPage.svg";
import landingTopImg from "./LandingPageImages/landingPageTop.svg";
import lpRocksDesktop from "./LandingPageImages/lpRocksDesktop.svg";
import lpMountains from "./LandingPageImages/lpMountains.svg";
import { buySections, sellSections } from "./LandingPageMobile";
import { LandingPageSection } from "./LandingPageSection";
import { StyledLink } from "../StyledLink/StyledLink";

export const LandingPageDesktop = () => {
  const { t } = useTranslation("common");

  return (
    <Stack sx={{ mb: 15 }}>
      <Stack direction='row' sx={{ pt: 8, overflow: "hidden" }}>
        <Stack alignItems='start' sx={{ width: "50%" }}>
          <Stack spacing={4} sx={{ pl: 15, pt: 12, width: "70%", mb: 1 }}>
            <Typography
              variant='h3'
              color='primary.dark'
              sx={{ fontWeight: 600, zIndex: 1 }}
            >
              {t("shared.landing.title")}
            </Typography>
            <Typography
              variant='h3'
              color='text.primary'
              sx={{ fontWeight: 550 }}
            >
              {t("shared.landing.subtitle")}
            </Typography>
            <Typography variant='subtitle1' color='text.primary'>
              {t("shared.landing.description")}
            </Typography>
          </Stack>
        </Stack>
        <img
          src={landingImg}
          alt='landing'
          style={{ zIndex: 1, paddingTop: 30, width: "50%", right: 0 }}
        />
        <img
          src={landingTopImg}
          alt='landingTop'
          style={{ position: "absolute", right: 0, top: 0, width: "60%" }}
        />
      </Stack>
      <Stack alignItems='center' sx={{ pb: 5 }}>
        <img
          src={lpRocksDesktop}
          alt='lpRocs'
          style={{ width: "100%", zIndex: 1 }}
        />
      </Stack>
      <Stack spacing={3} sx={{ pb: 5 }}>
        <Typography
          variant='h4'
          color='primary.dark'
          sx={{ fontWeight: 550, textAlign: "center" }}
        >
          {t("shared.landing.welcome")}
        </Typography>
        <Typography
          variant='h4'
          color='text.primary'
          sx={{ fontWeight: 550, textAlign: "center" }}
        >
          {t("shared.landing.explore")}
        </Typography>
        <Typography
          variant='subtitle1'
          color='primary.dark'
          sx={{ textAlign: "center" }}
        >
          {t("shared.landing.description2")}
        </Typography>
        <Typography
          variant='subtitle1'
          color='text.primary'
          sx={{ textAlign: "center" }}
        >
          {t("shared.landing.description3")}
        </Typography>
        <Typography
          variant='h4'
          color='primary.dark'
          sx={{ fontWeight: 550, textAlign: "center", pt: 8 }}
        >
          {t("shared.landing.titleBuy")}
        </Typography>
        <Typography
          variant='h4'
          color='text.primary'
          sx={{ fontWeight: 550, textAlign: "center" }}
        >
          {t("shared.landing.titleInfo")}
        </Typography>
      </Stack>
      <Grid container sx={{ px: 5 }}>
        {buySections.map(({ src, title, desc }) => (
          <Grid item xs={6}>
            <LandingPageSection
              key={title}
              src={src}
              title={title}
              desc={desc}
            />
          </Grid>
        ))}
      </Grid>
      <Stack spacing={4}>
        <Typography
          variant='h4'
          color='primary.dark'
          sx={{ fontWeight: 550, textAlign: "center" }}
        >
          {t("shared.landing.wantSellTitle")}
        </Typography>
        <Typography
          variant='h4'
          color='text.primary'
          sx={{ fontWeight: 550, textAlign: "center" }}
        >
          {t("shared.landing.wantSellTitle2")}
        </Typography>
      </Stack>
      <Grid container sx={{ px: 5, pt: 5 }}>
        {sellSections.map(({ src, title, desc }) => (
          <Grid item xs={6}>
            <LandingPageSection src={src} title={title} desc={desc} />
          </Grid>
        ))}
      </Grid>
      <Typography
        variant='h4'
        color='primary.dark'
        sx={{ fontWeight: 550, textAlign: "center", zIndex: 1, pt: 15 }}
      >
        {t("shared.landing.registrateNow")}
      </Typography>
      <img
        src={lpMountains}
        alt={lpMountains}
        style={{ width: "100%", marginBottom: "-10rem", marginTop: "-197px" }}
      />
      <Stack
        position='fixed'
        spacing={1}
        sx={{
          bottom: 0,
          left: 0,
          width: "100%",
          pt: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#faf8f5e4",
          zIndex: 2,
        }}
      >
        <Stack direction='row' spacing={3} sx={{ my: 1 }}>
          <StyledLink to='/app'>
            <Button
              variant='contained'
              size='medium'
              sx={{
                borderRadius: "50px",
                color: "secondary.light",
                mb: 2,
                width: "15rem",
              }}
            >
              {t("shared.landing.buy")}
            </Button>
          </StyledLink>
          <StyledLink to='/registration/admin'>
            <Button
              variant='outlined'
              size='medium'
              sx={{
                borderRadius: "50px",
                mb: 2,
                width: "15rem",
                backgroundColor: "secondary.dark",
              }}
            >
              {t("shared.landing.sell")}
            </Button>
          </StyledLink>
        </Stack>
      </Stack>
    </Stack>
  );
};
