import { AttachMoney, ShoppingCartOutlined } from "@mui/icons-material";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { Header } from "../../../customer/components/TopBar/Header";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useTranslation } from "react-i18next";
import { StyledLink } from "../StyledLink/StyledLink";

export const Registration = () => {
  const { t } = useTranslation("common");
  const windowSize = useWindowSize();
  const isNarrow = windowSize.width && windowSize.width < 700;

  return (
    <>
      <Header />
      <Stack
        alignItems='center'
        justifyContent='center'
        sx={{ m: 5, mt: 12 }}
        spacing={3}
      >
        <Typography variant='h3' color='text.secondary'>
          {t("shared.registration.chooseOption")}
        </Typography>
        <Card
          elevation={3}
          sx={{
            backgroundColor: "transparent",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: (theme) => theme.palette.primary.main,
            borderRadius: 4,
            width: isNarrow ? "95%" : "80%",
            height: 500,
            p: 5,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack
            direction={isNarrow ? "column" : "row"}
            spacing={10}
            alignItems='center'
            justifyContent='center'
          >
            <Card
              elevation={3}
              sx={{
                width: 300,
                backgroundColor: "transparent",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: (theme) => theme.palette.primary.main,
                borderRadius: 4,
                cursor: "pointer",
                ":hover": {
                  boxShadow: 9,
                  backgroundColor: "#ffffff",
                  transition: "250ms",
                  transitionDelay: "inherit",
                },
              }}
            >
              <StyledLink to='/registration/customer'>
                <CardContent>
                  <Stack
                    alignItems='center'
                    justifyContent='center'
                    spacing={isNarrow ? 1 : 6}
                  >
                    <Box
                      sx={{
                        mt: 3,
                        p: 5,
                        borderStyle: "dashed",
                        borderColor: (theme) => theme.palette.primary.light,
                        borderWidth: "2px",
                        borderRadius: 4,
                      }}
                    >
                      <ShoppingCartOutlined fontSize='large' color='primary' />
                    </Box>
                    <Typography variant='h5' color='primary.dark'>
                      {t("shared.registration.buy")}
                    </Typography>
                  </Stack>
                </CardContent>
              </StyledLink>
            </Card>

            <Card
              elevation={3}
              sx={{
                width: 300,
                backgroundColor: "transparent",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: (theme) => theme.palette.primary.main,
                borderRadius: 4,
                cursor: "pointer",
                ":hover": {
                  boxShadow: 9,
                  backgroundColor: "#ffffff",
                  transition: "250ms",
                  transitionDelay: "inherit",
                },
              }}
            >
              <StyledLink to='/registration/admin'>
                <CardContent>
                  <Stack
                    alignItems='center'
                    justifyContent='center'
                    spacing={isNarrow ? 1 : 6}
                  >
                    <Box
                      sx={{
                        mt: 3,
                        p: 5,
                        borderStyle: "dashed",
                        borderColor: (theme) => theme.palette.primary.light,
                        borderWidth: "2px",
                        borderRadius: 4,
                      }}
                    >
                      <AttachMoney fontSize='large' color='primary' />
                    </Box>
                    <Typography variant='h5' color='primary.dark'>
                      {t("shared.registration.sell")}
                    </Typography>
                  </Stack>
                </CardContent>
              </StyledLink>
            </Card>
          </Stack>
        </Card>
      </Stack>
    </>
  );
};
