import { Grid, Skeleton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useGetAdminBusinesses } from "../../../apiClient/apiClient";
import { BusinessAdmin } from "./BusinessAdmin";
import { AddBusinessCard } from "./AddBusinessCard";
import { useTranslation } from "react-i18next";

export const BusinessesAdmin = () => {
  const { data: businesses, isSuccess, isLoading } = useGetAdminBusinesses();
  const { t } = useTranslation("common");

  if (isLoading) {
    return (
      <Stack direction='row' spacing={2} sx={{ mt: 12, ml: 5 }}>
        <Skeleton variant='rounded' width={300} height={438} />
        <Skeleton variant='rounded' width={300} height={438} />
      </Stack>
    );
  }

  return (
    <Stack
      spacing={4}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        pt: 12,
        pb: 5,
        px: 5,
      }}
    >
      {isSuccess && businesses.data.length < 1 && (
        <Typography variant='h3' fontWeight={500} color='text.secondary'>
          {t("admin.business.addFirstBusiness")}
        </Typography>
      )}
      {isSuccess && businesses.data.length === 1 && (
        <Typography variant='h3' fontWeight={500} color='text.secondary'>
          {t("admin.business.yourBusiness")}
        </Typography>
      )}
      {isSuccess && businesses.data.length > 1 && (
        <Typography variant='h3' fontWeight={500} color='text.secondary'>
          {t("admin.business.yourBusinesses")}
        </Typography>
      )}
      {isSuccess && (
        <Grid container>
          {businesses.data.map(({ id, name, description, images }) => (
            <BusinessAdmin
              key={id}
              id={id}
              name={name}
              description={description}
              images={images}
            />
          ))}
          <AddBusinessCard />
        </Grid>
      )}
    </Stack>
  );
};
